import { Model } from '@vuex-orm/core';

export class PromoterScoreQuestionJoin extends Model {
  static get entity() {
    return 'PromoterScoreQuestionJoin';
  }

  static primaryKey = ['promoter_score_id', 'promoter_score_question_id'];

  static fields() {
    return {
      id: this.attr(null),
      promoter_score_id: this.attr(null),
      promoter_score_question_id: this.attr(null),
    };
  }
}

export default PromoterScoreQuestionJoin;
