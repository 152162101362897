/*
 * The following is an explanation to get pagination working
 * for any data table within the app:
 *
 * 1. Add a name to the component that holds the v-data-table
 *    IE - export default { name: 'PostIndex' }
 *
 * 2. Add a named object to this file (pagination.module) with
 *    the component's name under state.pages with initial default
 *    key => value pairs of { currentPage: 1, perPage: 30 }
 *
 * 3. Set up the controller method to use the will_paginate gem
 *    Ex: @posts = params[:limit] && params[:limit].to_i === -1
 *      ? @posts.order(order)
 *      : _paginate_collection(@posts.order(order),
 *        per_page: limit)
 *
 * 4. In the respective service pack, add a new method to get
 *    items by page (IE - postService.getPostsByPage())
 *
 *    Be sure to use async await, pass in a limit, and store
 *    the result to return later. In the .then(), create an
 *    object to store the necessary values (shown below), and
 *    run the 'pagination/setHeaders' vuex action
 *
 *    baseUrl: the base endpoint being called for this data
 *      (to be used in the pagination.js mixin)
 *    context: the vuex-orm model
 *    page: used to determine which object will hold the data
 *    perPage: received via headers (+headers['x-per-page'])
 *    total: received via headers (+headers['x-total'])
 *
 * 5. Within the component that uses the v-data-table, add the
 *    following to the script tag for pagination to be included:
 *
 *    import pagination from '~/mixins/pagination';
 *    export default { mixins: [ pagination ] }
 *
 * 6. On the v-data-table, add the following props:
 *
 *    :items-per-page.sync="pagination.pages[$options.name].perPage"
 *    :options.sync="options"
 *    :page.sync="currentPage"
 *    :server-items-length="pagination.pages[$options.name].total"
 *
 *    items-per-page: controls the "Rows per page:" dropdown value
 *    options: an object controlled by vuetify
 *    page: controls the page that gets loaded in the footer
 *    server-items-length: total amount of items available from the
 *      server for pagination to work correctly
 */

const storeDefaults = {
  baseUrl: '',
  context: null,
  currentPage: 1,
  hasLoaded: false,
  perPage: 30,
  search: null,
  sortBy: 'created_at',
  sortDirection: 'desc',
  total: null,
};

const pagination = {
  actions: {
    resetPagination({ commit }, payload) {
      commit('resetPagination', payload);
    },
    setCurrentPage({ commit }, payload) {
      commit('setCurrentPage', payload);
    },
    setHasLoaded({ commit }, payload) {
      commit('setHasLoaded', payload);
    },
    setHeaders({ commit }, payload) {
      commit('setHeaders', payload);
    },
    setPerPage({ commit }, payload) {
      commit('setPerPage', payload);
    },
    setSortBy({ commit }, payload) {
      commit('setSortBy', payload);
    },
    setSortDirection({ commit }, payload) {
      commit('setSortDirection', payload);
    },
  },

  mutations: {
    resetPagination(state, payload) {
      const isProfileComments = payload.page === 'profile-comments';

      state.pages[payload.page] = {
        ...storeDefaults,
        ...(isProfileComments && { source: null }),
      };
    },
    setCurrentPage(state, payload) {
      state.pages[payload.page].currentPage = payload.currentPage;
    },
    setHasLoaded(state, payload) {
      state.pages[payload.page].hasLoaded = true;
    },
    setHeaders(state, payload) {
      state.pages[payload.page].baseUrl = payload.baseUrl;
      state.pages[payload.page].context = payload.context;
      state.pages[payload.page].perPage = payload.perPage;
      state.pages[payload.page].total = payload.total;

      if (payload.search) {
        state.pages[payload.page].search = payload.search;
      }

      if (
        state.pages[payload.page].hasOwnProperty('source') &&
        payload.source
      ) {
        state.pages[payload.page].source = payload.source;
      }
    },
    setPerPage(state, payload) {
      state.pages[payload.page].perPage = payload.perPage;
    },
    setSortBy(state, payload) {
      state.pages[payload.page].sortBy = payload.sortBy;
    },
    setSortDirection(state, payload) {
      state.pages[payload.page].sortDirection = payload.sortDirection;
    },
  },

  namespaced: true,

  state: {
    pages: {
      'insight-checkin-group-show': {
        ...storeDefaults,
        sortBy: 'first_name',
      },
      'insight-checkin-index': {
        ...storeDefaults,
        sortBy: 'name',
        sortDirection: 'asc',
      },
      'insight-comments': {
        ...storeDefaults,
      },
      'insight-promoter-score-group-show': {
        ...storeDefaults,
        sortBy: 'first_name',
      },
      'insight-promoter-score-index': {
        ...storeDefaults,
        sortBy: 'name',
        sortDirection: 'asc',
      },
      'post-index': {
        ...storeDefaults,
      },
      'profile-comments': {
        ...storeDefaults,
        source: null,
      },
    },
  },
};

export default pagination;
