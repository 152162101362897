import axios from 'axios';
import PayPlan from '~/models/payPlan';
import Subscription from '~/models/subscription';
import { stripeService } from './stripe.service';

function getIndexData() {
  return axios.all([
    this.getSubscription(),
    this.getPayPlans(),
    stripeService.getUpcomingInvoice(),
    stripeService.getCustomerInfo(),
  ]);
}

function getPayPlans() {
  return PayPlan.api().get('/api/pay_plans');
}

function getSubscription() {
  return Subscription.api().get('/api/subscription');
}

function updateFBNotifiedStatus(payload) {
  const { id, fb_notified } = payload;

  return Subscription.api().put(`/api/subscription/${id}/update_fb_status`, {
    fb_notified,
  });
}

export const subscriptionService = {
  getIndexData,
  getPayPlans,
  getSubscription,
  updateFBNotifiedStatus,
};
