import axios from 'axios';
import Role from '~/models/role';

function deleteRoles(payload, authenticityToken = null) {
  const { roleIds } = payload;
  return axios({
    data: {
      role_ids: roleIds,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'POST',
    url: '/api/custom_roles/bulk_delete_roles',
  })
    .then(response => response)
    .catch(() => {
      throw new Error('There was an error deleting role.');
    });
  // return User.api().post('/api/custom_roles/bulk_delete_roles', {
  //   role_ids: roleIds,
  // });
}

function fetchAllUserRoles() {
  return Role.api().get('/api/user_managements/all_user_defined_roles');
}

function fetchEditRolePermissions(id) {
  return axios({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    url: `/api/custom_roles/${id}/edit`,
  })
    .then(response => response)
}

function fetchNewRolePermissions() {
  return axios({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    url: '/api/custom_roles/new',
  })
    .then(response => response)
  // return User.api().get('/api/custom_roles/new');
}

function fetchRoles() {
  return axios({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    url: '/api/custom_roles',
  })
    .then(response => response)
  // return User.api().get('/api/custom_roles');
}

function saveRole(payload, authenticityToken = null) {
  const { role } = payload;
  return axios({
    data: {
      role,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'POST',
    url: '/api/custom_roles',
  })
    .then(response => response)
    .catch(() => {
      throw new Error('There was an error saving role.');
    });
}

function updateRole(payload, id, authenticityToken = null) {
  const { role } = payload;
  return axios({
    data: {
      role,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'PATCH',
    url: `/api/custom_roles/${id}`,
  })
    .then(response => response)
    .catch(() => {
      throw new Error('There was an error saving role.');
    });
}

export const roleService = {
  deleteRoles,
  fetchAllUserRoles,
  fetchEditRolePermissions,
  fetchNewRolePermissions,
  fetchRoles,
	saveRole,
  updateRole,
};
