import CheckinStat from '~/models/checkinStat';
import PromoterScoreStat from '~/models/promoterScoreStat';
import Stat from '~/models/stat';

function getCheckinStatsByContact(payload) {
  const { contactId, range } = payload;
  return CheckinStat.api().get(`/api/contacts/${contactId}/over_time?range=${range}`);
}

function getCheckinStatsByGroup(payload) {
  const { groupId, range } = payload;
  return CheckinStat.api().get(`/api/groups/${groupId}/over_time?range=${range}`);
}

function getCheckinStatsByRange(range = 30) {
  return CheckinStat.api().get(`/api/checkins/over_time?range=${range}`);
}

function getPromoterScoreStatsByContact(payload) {
  const { contactId, range } = payload;
  return PromoterScoreStat.api().get(`/api/contacts/${contactId}/over_time?range=${range}`);
}

function getPromoterScoreStatsByGroup(payload) {
  const { groupId, range } = payload;
  return PromoterScoreStat.api().get(`/api/groups/${groupId}/promoter_score_over_time?range=${range}`);
}

function getPromoterScoreStatsByRange(range = 30) {
  return PromoterScoreStat.api().get(`/api/enps/over_time?range=${range}`);
}

function getStatsByRange(range = 30) {
  return Stat.api().get(`/api/stats?range=${range}`);
}

export const statsService = {
  getCheckinStatsByContact,
  getCheckinStatsByGroup,
  getCheckinStatsByRange,
  getPromoterScoreStatsByContact,
  getPromoterScoreStatsByGroup,
  getPromoterScoreStatsByRange,
  getStatsByRange,
};
