const UserCheckin = () => import('~/views/users/checkins/Show');
const UserEdit = () => import('~/views/users/edit/Edit');
const UserEngagement = () => import('~/views/users/engagement/Show');
const UserFeedback = () => import('~/views/users/feedback/Show');
const UserHealthScore = () => import('~/views/users/healthScore/Show');
const UserMailableShow = () => import('~/views/users/mailable/Show');
const UserMessageRemindShow = () => import('~/views/users/messageRemind/Show');
const UserPromoterScore = () => import('~/views/users/enps/Show');

export const userManagement = [
  {
    component: UserCheckin,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'UserCheckin',
    path: '/users/:id/checkins',
  },
  {
    component: UserEdit,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'UserEdit',
    path: '/users/:id/edit',
  },
  {
    component: UserEngagement,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'UserEngagement',
    path: '/users/:id/engagement',
  },
  {
    component: UserFeedback,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'UserFeedback',
    path: '/users/:id/feedback',
  },
  {
    component: UserHealthScore,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'UserHealthScore',
    path: '/users/:id/health_score',
  },
  {
    component: UserMailableShow,
    meta: {
      adminRestricted: true,
      isNavigationDrawerVisible: false,
      requiresAuth: false,
    },
    name: 'UserMailableShow',
    path: '/users/:id/mailable',
  },
  {
    component: UserMessageRemindShow,
    meta: {
      adminRestricted: true,
      isNavigationDrawerVisible: false,
      requiresAuth: false,
    },
    name: 'UserMessageRemindShow',
    path: '/users/:id/message_with_reminder',
  },
  {
    component: UserPromoterScore,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'UserPromoterScore',
    path: '/users/:id/enps',
  },
];
