import Reply from '~/models/reply';

function createReply(payload) {
  const { comment_id, reply } = payload;

  return Reply.api().post(`/api/comments/${comment_id}/submit_reply`, {
    reply,
  });
}

export const replyService = {
  createReply,
};
