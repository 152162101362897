import { Model } from '@vuex-orm/core';

export class ViewableUser extends Model {
  static get entity() {
    return 'ViewableUser';
  }

  static primaryKey = ['group_id', 'user_id'];

  static fields() {
    return {
      group_id: this.attr(null),
      id: this.attr(null),
      user_id: this.attr(null),
    };
  }
}

export default ViewableUser;
