<template>
  <!-- If logged in -->
  <v-navigation-drawer
    v-if="account && currentUser && isLoggedIn"
    v-model="drawer"
    app
    right
    temporary>
    <v-list>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>{{ currentUser.username() }}</v-list-item-title>
          <v-list-item-subtitle>{{ currentUser.role.name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="divider-spacer" />

      <v-list-item>
        <v-btn
          exact
          :ripple="false"
          text
          :to="{ name: 'Dashboard' }">
          Dashboard
        </v-btn>
      </v-list-item>

      <v-list-item>
        <v-btn
          :ripple="false"
          text
          :to="{ name: 'PostIndex' }">
          Broadcasts
        </v-btn>
      </v-list-item>

      <v-list-item v-if="hasAccess && showTemplatesItem">
        <v-btn
          :ripple="false"
          text
          :to="{ name: 'TemplateIndex' }">
          Templates
        </v-btn>
      </v-list-item>

      <v-list-item v-if="hasAccess">
        <v-btn
          :ripple="false"
          text
          :to="{ name: 'InsightCheckinIndex' }">
          Check-ins
        </v-btn>
      </v-list-item>

      <v-list-item v-if="hasAccess">
        <v-btn
          :class="{
            'is-locked': !checkFeatureAccess.enps,
            'v-btn--active': checkFeatureAccess.enps &&
              isMenuItemActive('InsightPromoterScoreIndex'),
          }"
          :ripple="false"
          text
          @click="checkLockedState('InsightPromoterScoreIndex', features.ENPS)">
          eNPS<sup class="trademark">&reg;</sup>
          <v-icon
            v-if="!checkFeatureAccess.enps"
            class="icon-lock"
            size="12">
            mdi-lock
          </v-icon>
        </v-btn>
      </v-list-item>

      <v-list-item v-if="hasAdminAccess">
        <v-btn
          :class="{
            'is-locked': !checkFeatureAccess.surveys,
            'v-btn--active': checkFeatureAccess.surveys &&
              isMenuItemActive('SurveyIndex'),
          }"
          :ripple="false"
          text
          @click="checkLockedState('SurveyIndex', features.SURVEYS)">
          Surveys
          <v-icon
            v-if="!checkFeatureAccess.surveys"
            class="icon-lock"
            size="12">
            mdi-lock
          </v-icon>
        </v-btn>
      </v-list-item>

      <v-list-item v-if="hasAccess">
        <v-btn
          :ripple="false"
          text
          :to="{ name: 'InsightHealthScoreShow' }">
          Insights
        </v-btn>
      </v-list-item>
    </v-list>

    <v-divider class="divider-spacer" />

    <v-list>
      <v-list-item>
        <v-btn
          :ripple="false"
          text
          :to="{ name: 'UserEdit', params: { id: currentUser.id } }">
          Profile &amp; Preferences
        </v-btn>
      </v-list-item>

      <v-list-item v-if="hasAccess && hasAdminAccess">
        <v-btn
          :ripple="false"
          text
          :to="{ name: accountRoute }">
          Account &amp; Billing
        </v-btn>
      </v-list-item>

      <v-list-item v-if="hasAccess">
        <v-btn
          :ripple="false"
          text
          :to="{ name: 'TeamManagementIndividuals' }">
          Team Management
        </v-btn>
      </v-list-item>

      <v-list-item v-if="hasAccess">
        <v-btn
          :ripple="false"
          text
          :to="{ name: 'IntegrationIndex' }">
          Integrations
        </v-btn>
      </v-list-item>

      <v-list-item>
        <v-btn
          color="primary"
          :ripple="false"
          text
          @click="logout">
          Logout
        </v-btn>
      </v-list-item>
    </v-list>

    <v-divider class="divider-spacer" />

    <v-list>
      <v-list-item>
        <v-btn
          class="btn-install"
          href="https://help.recess.io/en/?ref=app"
          :ripple="false"
          target="_blank"
          text>
          Help Center
          <v-icon
            class="icon-external"
            size="13">
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </v-list-item>

      <v-list-item>
        <v-btn
          class="btn-install"
          href="https://chrome.google.com/webstore/detail/recess/jcmafppmgmdgjflfjgpkbcmigkcchpeo"
          :ripple="false"
          target="_blank"
          text>
          Install Chrome Extension
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <!-- If not logged in -->
  <v-navigation-drawer
    v-else
    v-model="drawer"
    app
    right
    temporary>
    <v-list>
      <v-list-item>Don't have an account?</v-list-item>
      <v-list-item>
        <v-btn
          color="primary"
          href="https://www.recess.io/pricing"
          large
          outlined
          :ripple="false"
          rounded
          text>
          Register Now
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations } from 'vuex';
import currentUser from '~/mixins/currentUser';
import { EventBus } from '~/helpers/eventBus';
import { FEATURES } from '~/helpers/constants';
import store from '~/store';
import { userService } from '~/services';

export default {
  mixins: [currentUser],
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    features: FEATURES,
  }),
  computed: {
    accountRoute() {
      const [firstTab] = this.currentUser.accountTabs();

      if (firstTab) {
        return firstTab.url;
      }

      return 'TeamManagementIndividuals';
    },
    checkFeatureAccess() {
      return {
        enps: this.isFeatureUnlocked(this.features.ENPS),
        surveys: this.isFeatureUnlocked(this.features.SURVEYS),
      };
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    hasAccess() {
      return this.account &&
        this.isLoggedIn &&
        this.$route.path !== '/onboard' &&
        this.currentUser.hasAccess();
    },
    showTemplatesItem() {
      return this.account.crud_templates;
    },
  },
  methods: {
    ...mapMutations([
      'setDrawer',
    ]),
    checkLockedState(route, featureName) {
      if (!this.isFeatureUnlocked(featureName)) {
        EventBus.$emit('account-upgrade-required', featureName);

        return;
      }

      if (route === this.$route.name) {
        return;
      }

      this.$router.push({ name: route });
    },
    isMenuItemActive(item) {
      return item === this.$route.name;
    },
    async logout() {
      await userService.logout()
        .then(() => {
          document.querySelector('body').setAttribute('data-user', '');
          document.querySelector('body').setAttribute('data-account', '');
          store.dispatch('entities/deleteAll');
          this.$router.push({ name: 'Login' });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .divider-spacer {
    margin: 8px 0;
  }

  .icon-lock {
    margin-left: 4px;
  }

  .is-locked {
    opacity: 0.3;
  }

  .trademark {
    font-size: 60%;
    top: -0.3em;
  }

  .v-btn.btn-install {
    font-size: 0.7rem;
  }

  .icon-external {
    margin-left: 6px;
  }
</style>

<style lang="scss">
  .v-list-item {
    justify-content: start;
  }
</style>
