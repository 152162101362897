import { Model } from '@vuex-orm/core';
import { Survey } from '~/models/surveyHierarchy';
import SurveyQuestion from '~/models/surveyQuestion';
import SurveyQuestionOption from '~/models/surveyQuestionOption';
import { User } from '~/models/userHierarchy';

export class SurveyUserAnswer extends Model {
  static get entity() {
    return 'SurveyUserAnswer';
  }

  static fields() {
    return {
      id: this.attr(null),
      comment: this.attr(''),
      created_at: this.attr(null),
      option_id: this.attr(null),
      survey_id: this.attr(null),
      survey_question_id: this.attr(null),
      updated_at: this.attr(null),
      user_id: this.attr(null),

      survey: this.belongsTo(Survey, 'survey_id'),
      survey_question: this.belongsTo(SurveyQuestion, 'survey_question_id'),
      survey_question_option: this.belongsTo(SurveyQuestionOption, 'option_id'),
      user: this.belongsTo(User, 'user_id'),
    };
  }
}

export default SurveyUserAnswer;
