import { Model } from '@vuex-orm/core';

export default class Invoice extends Model {
  static get entity() {
    return 'Invoice';
  }

  static fields() {
    return {
      amount_due: this.number(0),
      amount_paid: this.number(0),
      amount_remaining: this.number(0),
      period_end: this.attr(null),
      period_start: this.attr(null),
    };
  }
}
