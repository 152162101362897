import { FEATURES } from '~/helpers/constants';

const SurveyEdit = () => import('~/views/surveys/edit/Edit');
const SurveyIndex = () => import('~/views/surveys/index/Index');
const SurveyShow = () => import('~/views/surveys/Show');
const SurveyTemplatesIndex = () => import('~/views/surveys/templates/Index');

export const survey = [
  {
    component: SurveyIndex,
    meta: {
      adminRestricted: true,
      featureName: FEATURES.SURVEYS,
      requiresAuth: true,
    },
    name: 'SurveyIndex',
    path: '/surveys',
  },
  {
    component: SurveyTemplatesIndex,
    meta: {
      adminRestricted: true,
      featureName: FEATURES.SURVEY_TEMPLATES,
      permissionName: 'create_edit_surveys',
      requiresAuth: true,
    },
    name: 'SurveyTemplatesIndex',
    path: '/surveys/templates',
  },
  {
    component: SurveyShow,
    meta: {
      adminRestricted: false,
      featureName: FEATURES.SURVEYS,
      isNavigationDrawerVisible: false,
      requiresAuth: true,
    },
    name: 'SurveyShow',
    path: '/surveys/:id',
  },
  {
    component: SurveyEdit,
    meta: {
      adminRestricted: true,
      featureName: FEATURES.SURVEYS,
      permissionName: 'create_edit_surveys',
      requiresAuth: true,
    },
    name: 'SurveyEdit',
    path: '/surveys/:id/edit',
  },
];
