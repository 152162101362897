import { Event } from '~/models/eventHierarchy';

export class Clicked extends Event {
  static get entity() {
    return 'clicked';
  }

  static baseEntity = 'event';

  static fields() {
    return {
      ...super.fields(),
    };
  }

  description = (user = null) => {
    const { raw_rcpt_to, subject, target_link_url } = this.event_data;

    const from = user ? user : raw_rcpt_to;
    const link = target_link_url ? target_link_url : 'a link';
    const broadcast = subject ? subject : 'a broadcast';

    return `${from} has clicked ${link} inside ${broadcast}.`;
  }

  icon = () => {
    return 'mdi-cursor-default-click';
  }

  title = () => {
    return 'Clicked';
  }
};

export default Clicked;
