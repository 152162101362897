import axios from 'axios';
import { contactService, postService } from '~/services';

function getIndexData() {
  return axios.all([
    postService.getAllPosts(),
    contactService.getAllContacts(),
  ]);
}

export const broadcastIndexService = {
  getIndexData,
};
