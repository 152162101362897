import { Event } from '~/models/eventHierarchy';

export class InitialOpened extends Event {
  static get entity() {
    return 'initial_opened';
  }

  static baseEntity = 'event';

  static fields() {
    return {
      ...super.fields(),
    };
  }

  description = (user = null) => {
    const { raw_rcpt_to, subject } = this.event_data;

    const from = user ? user : raw_rcpt_to;
    const broadcast = subject ? subject : 'a broadcast';

    return `${from} has opened ${broadcast}.`;
  }

  icon = () => {
    return 'mdi-email-open';
  }

  title = () => {
    return 'Opened';
  }
};

export default InitialOpened;
