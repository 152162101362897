import axios from 'axios';
import store from '~/store';
import { User } from '~/models/userHierarchy';
import { Post } from '~/models/postHierarchy';
import Template from '~/models/template';

function createPost(payload, authenticityToken) {
  return axios({
    data: { ...payload },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'POST',
    url: '/api/posts',
  }).then(response => {
    User.update({
      data: {
        posts_count: response.data.posts_count,
      },
      where: response.data.creator_id,
    });

    return response;
  });
}

function deletePost(payload) {
  const { id } = payload;

  return Post.api().delete(`/api/posts/${id}`);
}

function deleteTemplates(payload) {
  const { templateIds } = payload;

  return Template.api().post('/api/templates/bulk_delete_templates', {
    template_ids: templateIds,
  });
}

function duplicatePost(payload) {
  const { id } = payload;

  return Post.api().get(`/api/posts/${id}/duplicate`);
}

function duplicateTemplate(payload) {
  const { id } = payload;

  return Post.api().get(`/api/posts/${id}/duplicate_template`);
}

function getAllPosts() {
  return Post.api().get('/api/posts');
}

function getAllTemplates() {
  return Template.api().get('/api/templates');
}

function getMessage(payload) {
  const { id } = payload;

  return axios({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    url: `/api/posts/${id}/message`,
  }).then(response => response.data);
}

function getPost(payload) {
  const { id } = payload;

  return axios({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    url: `/api/posts/${id}`,
  })
    .then(response => response.data)
    .catch(() => {
      throw new Error('There was an error loading your post.');
    });
}

async function getPostsByPage(payload) {
  const { limit, search } = payload;
  let params = {
    limit: limit || 30,
    order: 'created_at,desc',
  };

  if (search) {
    params = {
      ...params,
      search,
    };
  }
  const result = await Post.api()
    .get('/api/posts', {
      params,
    })
    .then(response => {
      const {
        response: { headers },
      } = response;
      const data = {
        baseUrl: '/api/posts',
        context: Post,
        page: 'post-index',
        perPage: +headers['x-per-page'],
        search,
        total: +headers['x-total'],
      };

      store.dispatch('pagination/setHeaders', data);
    });

  return result;
}

function getMostRecentPost() {
  return Post.api().get(`/api/posts/recent`);
}

function getPostById(payload) {
  const { id } = payload;

  return Post.api().get(`/api/posts/${id}`);
}

function getTemplateById(payload) {
  const { id } = payload;

  return Template.api().get(`/api/templates/${id}`);
}

function markAsRead(payload) {
  const { authenticityToken, id } = payload;

  return axios({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'POST',
    url: `/api/posts/${id}/mark_read`,
  }).catch(error => error);
}

function submitRating(payload) {
  const { authenticityToken, data, post_id } = payload;

  return axios({
    data: {
      ...data,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'POST',
    url: `/api/posts/${post_id}/feedback`,
  }).catch(() => {
    throw new Error(
      'There was an error sending your reaction to this broadcast.',
    );
  });
}

function updateOrder(payload) {
  const { authenticityToken, id, items } = payload;

  return axios({
    data: {
      items,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'PUT',
    url: `/api/posts/${id}/update_order`,
  }).catch(() => {
    throw new Error('There was an error updating the order.');
  });
}

function updatePost(payload) {
  const { authenticityToken, post } = payload;

  return axios({
    data: {
      post,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'PUT',
    url: `/api/posts/${post.id}`,
  })
    .then(response => response.data)
    .catch(() => {
      throw new Error('There was an error updating your post.');
    });
}

function updateTemplateThumbnail(payload) {
  const { data, id } = payload;

  return Template.api().put(`/api/templates/${id}/update_thumbnail`, {
    data,
  });
}

export const postService = {
  createPost,
  deletePost,
  deleteTemplates,
  duplicatePost,
  duplicateTemplate,
  getAllPosts,
  getAllTemplates,
  getMessage,
  getMostRecentPost,
  getPost,
  getPostById,
  getPostsByPage,
  getTemplateById,
  markAsRead,
  submitRating,
  updateOrder,
  updatePost,
  updateTemplateThumbnail,
};
