import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Model } from '@vuex-orm/core';
import Comment from '~/models/comment';
import PromoterScoreQuestion from '~/models/promoterScoreQuestion';
import PromoterScoreQuestionJoin from '~/models/promoterScoreQuestionJoin';
import PromoterScoreTarget from '~/models/promoterScoreTarget';
import PromoterScoreUserAnswer from '~/models/promoterScoreUserAnswer';

dayjs.extend(utc);
export class PromoterScore extends Model {
  static get entity() {
    return 'PromoterScore';
  }

  static fields() {
    return {
      account_id: this.attr(null),
      comments: this.morphMany(Comment, 'commentable_id', 'commentable_type'),
      created_at: this.attr(''),
      draft: this.boolean(true),
      id: this.attr(null),
      promoter_score_questions: this.belongsToMany(
        PromoterScoreQuestion,
        PromoterScoreQuestionJoin,
        'promoter_score_id',
        'promoter_score_question_id',
      ),
      promoter_score_targets: this.hasMany(
        PromoterScoreTarget,
        'promoter_score_id',
      ),
      promoter_score_user_answers: this.hasMany(
        PromoterScoreUserAnswer,
        'promoter_score_id',
      ),
      sent_at: this.attr(null).nullable(),
      updated_at: this.attr(''),
    };
  }

  static state() {
    return {
      currentDate: '',
    };
  }

  getUserAnswer = type => {
    const question = this.promoter_score_questions.find(
      promoterQuestion => promoterQuestion.category === type,
    );
    const answer = this.promoter_score_user_answers.find(
      promoterAnswer =>
        promoterAnswer.promoter_score_question_id === question.id,
    );
    return answer.value;
  };
}

export default PromoterScore;
