import { Model } from '@vuex-orm/core';
import Account from '~/models/account';
import { DEFAULT_EMAIL } from '~/helpers/constants';

export default class SendingDomain extends Model {
  static get entity() {
    return 'SendingDomain';
  }

  static fields() {
    return {
      account: this.hasOne(Account, 'account_id'),
      account_id: this.attr(),
      cached_dkims: this.string('').nullable(),
      cached_spf: this.string('').nullable(),
      cname_verified: this.boolean(false),
      dkim_verified: this.boolean(false),
      dkims: this.string(''),
      domain: this.string(''),
      domain_verified: this.boolean(false),
      from: this.string('').nullable(),
      id: this.attr(null),
      is_default: this.boolean(false),
      selector: this.string(''),
      spf_verified: this.boolean(false),
    };
  }

  static defaultSendingDomain() {
    const domain = new SendingDomain();
    domain.id = -1;
    domain.domain = 'recess.io';
    domain.from = DEFAULT_EMAIL;

    return domain;
  }

  fromEmailDomain = () => {
    if (this.from == null || !this.from.includes('@')) {
      return null;
    }

    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.from)) {
      return this.from.split('@').pop();
    }

    return null;
  };

  hostname = () => {
    return {
      cname: `recess.${this.domain}`,
      dkims: this.selector,
    };
  };

  isDomainStatusValid = () => {
    return this.domain_verified;
  };

  isEmailValid = () => {
    return (
      this.fromEmailDomain() !== null && this.domain === this.fromEmailDomain()
    );
  };

  isTxtVerified = () => {
    return this.dkim_verified && this.spf_verified;
  };
}
