<template>
  <v-dialog
    v-model="show"
    max-width="1000"
    @click:outside="closeModal">
    <v-card>
      <div class="upgrade">
        <v-icon
          class="modal-close"
          color="secondary-grey"
          @click="closeModal">
          mdi-close
        </v-icon>
        <div class="upgrade-content upgrade-panel">
          <v-card-title class="card-headline">
            Your Account Doesn't Have Access to this Feature 😞
          </v-card-title>

          <div class="card-body">
            <p>Upgrade today to get:</p>
            <ul class="features">
              <li
                v-for="(feature, index) in features"
                :key="`feature-item-${index}`"
                class="feature-item">
                <v-icon
                  color="primary"
                  size="18">
                  mdi-check
                </v-icon>
                <div v-html="feature" />
              </li>
            </ul>

            <div class="upgrade-now">
              <v-btn
                color="primary"
                depressed
                :disabled="canUpgradeAccount"
                :ripple="false"
                rounded
                @click="upgrade">
                View Plans &amp; Upgrade
              </v-btn>
            </div>
          </div>
        </div>
        <div class="upgrade-image upgrade-panel">
          <div class="image-wrapper">
            <manager-assessment
              height="256"
              width="256" />
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '~/helpers/eventBus';
import currentUser from '~/mixins/currentUser';
import ManagerAssessment from '~/components/icons/ManagerAssessment';

export default {
  components: {
    ManagerAssessment,
  },
  mixins: [currentUser],
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    upgradeDetails: {
      default: () => ({}),
      type: Object,
    },
    value: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    features: [
      'More Seats',
      'More Automation Options',
      'Access to Surveys &amp; Polls (PLUS the Template Library)',
      'eNPS<sup class="trademark">®</sup> Score (Professional Plan ONLY)',
      'More of Your Favorite 3rd Party Integrations (Google Workspace, Slack, &amp; More to Come)',
      'Custom Sending Domains',
      '...And More...',
    ],
  }),
  computed: {
    canUpgradeAccount() {
      return this.currentUser && !this.currentUser.hasPermission('manage_billing_subscription');
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    closeModal() {
      EventBus.$emit('reset-account-upgrade-modal');
      this.show = false;
    },
    upgrade() {
      // prevent NavigationDuplicated error
      if (this.$route.name === 'SubscriptionShow') {
        this.closeModal();

        return;
      }

      this.$router.push({ name: 'SubscriptionShow' });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
  .upgrade {
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .upgrade-content {
    display: flex;
    flex-direction: column;
  }

  .upgrade-panel {
    flex: 1;

    &.upgrade-content {
      @media screen and (min-width: 768px) {
        max-width: 600px;
      }
    }

    &.upgrade-image {
      @media screen and (min-width: 768px) {
        max-width: 400px;
      }
    }
  }

  .upgrade-image {
    background-color: #f8f8ff;
    padding: 32px 16px;
    text-align: center;

    @media screen and (min-width: 768px) {
      padding: 64px 32px;
    }
  }

  .image-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .v-card__title.card-headline {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    padding: 12px 16px;

    @media screen and (min-width: 768px) {
      font-size: 21px;
      padding: 24px 32px;
    }
  }

  .card-body {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    flex: 1;
    font-size: 16px;
    padding: 24px 16px;

    @media screen and (min-width: 768px) {
      padding: 24px;
    }
  }

  .features {
    list-style: none;
    margin-bottom: 64px;
    padding-left: 0;
  }

  .feature-item {
    align-items: flex-start;
    display: flex;

    & + .feature-item {
      margin-top: 8px;
    }

    .v-icon {
      margin-right: 8px;
      margin-top: 2px;
    }
  }

  .upgrade-now {
    text-align: center;
  }

  .modal-close {
    position: absolute;
    right: 12px;
    top: 9px;

    @media screen and (min-width: 768px) {
      right: 16px;
      top: 16px;
    }
  }
</style>

<style lang="scss">
  .feature-item .trademark {
    font-size: 60%;
    top: -0.75em;
  }
</style>
