import { Model } from '@vuex-orm/core';

export class HealthScore extends Model {
  static get entity() {
    return 'HealthScore';
  }

  static fields() {
    return {
      created_at: this.attr(''),
      engagement_pct: this.number(0),
      entity_id: this.attr(null),
      entity_type: this.attr(null),
      health_score_pct: this.number(0),
      id: this.attr(null),
      satisfaction_pct: this.number(0),
      trust_pct: this.number(0),
      updated_at: this.attr(''),
    };
  }
}

export default HealthScore;
