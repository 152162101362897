import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Survey } from '~/models/surveyHierarchy';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export class Sent extends Survey {
  static get entity() {
    return 'Sent';
  }

  static baseEntity() {
    return 'Survey';
  }

  static fields() {
    return {
      ...super.fields(),
    };
  }

  actions = () => {
    return {
      button: {
        actionType: '',
        hasRoute: true,
        isOutlined: true,
        params: {
          id: this.id,
        },
        route: 'InsightSurveyShow',
        text: 'Statistics',
      },
    };
  };

  details = () => {
    return {
      hasIcon: false,
      html: `
      <span>
        <p class="mb-0">${this.total_targets}</p>
        <p class="caption"># SENT</p>
      </span>
      <span>
        <p class="mb-0">${this.averageParticipationPercent()}</p>
        <p class="caption">COMPLETED</p>
      </span>
      `,
      icon: '',
    };
  };

  subhead = () => {
    return {
      action: {
        hasLink: true,
        link: {
          params: {
            id: this.id,
          },
          route: 'InsightSurveyShow',
          text: `Sent ${
            this.sent_at ? dayjs(this.sent_at).utc().fromNow() : ''
          }`,
        },
        text: `to ${this.total_targets} contact${
          this.total_targets !== 1 ? 's' : ''
        }`,
      },
      details: {
        hasLink: true,
        link: {
          params: {
            id: this.id,
          },
          route: 'SurveyShow',
          text: 'View Online',
        },
        text: '',
      },
    };
  };
}

export default Sent;
