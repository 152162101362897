import { Event } from '~/models/eventHierarchy';

export class Delivered extends Event {
  static get entity() {
    return 'delivered';
  }

  static baseEntity = 'event';

  static fields() {
    return {
      ...super.fields(),
    };
  }

  description = (user = null) => {
    const { raw_rcpt_to, subject } = this.event_data;

    const from = user ? user : raw_rcpt_to;
    const broadcast = subject ? subject : 'a broadcast';

    return `${from} has received ${broadcast}.`;
  }

  icon = () => {
    return 'mdi-email-receive';
  }

  title = () => {
    return 'Delivered';
  }
};

export default Delivered;
