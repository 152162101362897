const FeedbackShow = () => import('~/views/posts/feedback/Show');
const GlobalStats = () => import('~/views/posts/stats/Index');
const MarkReadShow = () => import('~/views/posts/markRead/MarkRead');
const PostEdit = () => import('~/views/posts/edit/Edit');
const PostIndex = () => import('~/views/posts/index/Index');
const PostShow = () => import('~/views/posts/show/Show');
const PostStats = () => import('~/views/posts/stats/Stats');
const PostTemplatesIndex = () => import('~/views/posts/templates/Index');

export const post = [
  {
    component: GlobalStats,
    meta: {
      adminRestricted: true,
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'GlobalStats',
    path: '/posts/stats',
  },
  {
    component: PostTemplatesIndex,
    meta: {
      adminRestricted: true,
      permissionName: 'create_send_broadcast',
      requiresAuth: true,
    },
    name: 'PostTemplatesIndex',
    path: '/posts/templates',
  },
  {
    component: PostEdit,
    meta: {
      adminRestricted: true,
      isNavigationDrawerVisible: false,
      permissionName: 'create_send_broadcast',
      requiresAuth: true,
    },
    name: 'PostEdit',
    path: '/posts/:id/edit',
  },
  {
    component: PostIndex,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'PostIndex',
    path: '/posts',
  },
  {
    component: PostShow,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      isTopNavigationVisible: false,
      requiresAuth: true,
    },
    name: 'PostPreview',
    path: '/posts/:id/preview',
  },
  {
    component: PostShow,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      isTopNavigationVisible: false,
      requiresAuth: true,
    },
    name: 'PostShow',
    path: '/posts/:id',
  },
  {
    component: MarkReadShow,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      requiresAuth: true,
    },
    name: 'MarkRead',
    path: '/posts/:id/mark_read',
  },
  {
    component: FeedbackShow,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      requiresAuth: true,
    },
    name: 'Feedback',
    path: '/posts/:id/feedback',
  },
  {
    component: PostStats,
    meta: {
      adminRestricted: true,
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'PostStats',
    path: '/posts/:id/stats',
  },
];
