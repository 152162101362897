const alert = {
  actions: {
    clear({ commit }) {
      commit('clear');
    },
    error({ commit }, message) {
      commit('error', message);
    },
    info({ commit }, message) {
      commit('info', message);
    },
    success({ commit }, message) {
      commit('success', message);
    },
  },

  mutations: {
    clear(state) {
      state.icon = null;
      state.message = null;
      state.type = null;
      state.snackbar = false;
    },
    error(state, message) {
      state.icon = 'mdi-alert-circle';
      state.message = message;
      state.snackbar = true;
      state.type = 'error';
    },
    info(state, message) {
      state.icon = 'mdi-information';
      state.message = message;
      state.snackbar = true;
      state.type = 'info';
    },
    success(state, message) {
      state.icon = 'mdi-check-circle';
      state.message = message;
      state.snackbar = true;
      state.type = 'success';
    },
  },

  namespaced: true,

  state: {
    icon: null,
    message: null,
    snackbar: false,
    type: null,
  },
};

export default alert;
