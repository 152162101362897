import { Model } from '@vuex-orm/core';
import Reply from '~/models/reply';
import { User } from '~/models/userHierarchy';

export class Comment extends Model {
  static get entity() {
    return 'Comment';
  }

  static fields() {
    return {
      commentable_id: this.attr(null),
      commentable_type: this.string(''),
      created_at: this.string(''),
      following: this.boolean(false),
      id: this.attr(null),
      pretty_name: this.string('').nullable(),
      read: this.boolean(false),
      replies: this.hasMany(Reply, 'comment_id'),
      replies_count: this.number(0),
      source: this.string(''),
      user: this.belongsTo(User, 'user_id'),
      user_id: this.attr(null),
      value: this.string('').nullable(),
    };
  }

  followButton = () => {
    if (this.following) {
      return {
        icon: 'mdi-check',
        text: 'Following',
      };
    }

    return {
      icon: 'mdi-plus',
      text: 'Follow',
    };
  };

  getSourceCategory = () => {
    switch (this.source) {
      case 'Help Request':
        return 'Request for Help';
      default:
        return 'Comment';
    }
  };

  getSourceQuestion = () => {
    switch (this.source) {
      case 'Help Request':
        return 'Do you need help or more resources?';
      default:
        return 'Do you have any additional comments?';
    }
  };

  humanReadableFollowing = () => {
    return this.following ? 'Yes' : 'No';
  };
}

export default Comment;
