import { Model } from '@vuex-orm/core';
import User from '~/models/user';

class Integration extends Model {
  static get entity() {
    return 'Integration';
  }

  static state() {
    return {
      component: '',
      current: '',
      importCount: 0,
      source: '',
    };
  }

  static fields() {
    return {
      account_id: this.attr(null),
      contacts: this.hasMany(User, 'integration_id'),
      created_at: this.string(''),
      duplicate_contacts: this.hasMany(User, 'integration_id'),
      id: this.attr(null),
      meta: this.attr(null),
      name: this.string(''),
    };
  }
}

export default Integration;
