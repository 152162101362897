import { Model } from '@vuex-orm/core';
import PayPlan from './payPlan';
import Subscription from './subscription';

export default class SubscriptionItem extends Model {
  static get entity() {
    return 'SubscriptionItem';
  }

  static fields() {
    return {
      id: this.attr(null),
      pay_plan_id: this.attr(null),
      quantity: this.number(0),
      stripe_id: this.attr(null),
      subscription_id: this.attr(null),
    };
  }
};
