import { Model } from '@vuex-orm/core';
import Account from '~/models/account';
import PayPlan from '~/models/payPlan';
import SubscriptionItem from '~/models/subscriptionItem';

export class Subscription extends Model {
  static get entity() {
    return 'Subscription';
  }

  static fields() {
    return {
      account: this.belongsTo(Account, 'account_id'),
      account_id: this.attr(null),
      amount: this.number(0.0),
      currency: this.string('').nullable(),
      current_status: this.string('').nullable(),
      fb_notified: this.boolean(false),
      fb_notified_at: this.attr(''),
      id: this.attr(null),
      pay_plans: this.hasMany(PayPlan, 'subscription_id'),
      previous_status: this.string('').nullable(),

      raaft_app_id: this.string('').nullable(),
      raaft_auth_key: this.string('').nullable(),
      raaft_flow_id: this.string('').nullable(),
      stripe_subscription_id: this.string('').nullable(),

      subscription_items: this.hasMany(SubscriptionItem, 'subscription_id'),
    };
  }
}

export default Subscription;
