import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';

import authLink from './_authLink';
import cache from './_cache';
import errorLink from './_errorLink';
import httpLink from './_httpLink';

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const apolloClient = new ApolloClient({
  cache,
  link,
});

export default apolloClient;
