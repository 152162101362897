import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Model } from '@vuex-orm/core';
import { uniqBy, groupBy } from 'lodash';
import { Event } from '~/models/eventHierarchy';
import Schedule from './schedule';
import Target from './target';
import {
  Draft,
  PostError,
  Scheduled,
  Sending,
  Sent,
} from '~/models/postHierarchy';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export class Post extends Model {
  static get entity() {
    return 'Post';
  }

  static typeKey = 'status';

  static types() {
    return {
      draft: Draft,
      error: PostError,
      scheduled: Scheduled,
      sending: Sending,
      sent: Sent,
    };
  }

  static fields() {
    return {
      account_id: this.attr(null),
      campaign_id: this.attr(null).nullable(),
      click_percent: this.attr(0),
      created_at: this.string(''),
      creator_id: this.attr(null),
      description: this.string(''),
      error_message: this.string('').nullable(),
      events: this.morphMany(Event, 'event_target_id', 'event_target_type'),
      has_checkin: this.boolean(null).nullable(),
      has_enps: this.boolean(null).nullable(),
      has_feedback: this.boolean(null).nullable(),
      has_survey: this.boolean(null).nullable(),
      id: this.attr(null),
      marked_as_read_percent: this.attr(0),
      number_sent: this.attr(0),
      open_percent: this.attr(0),
      rated_percent: this.attr(0),
      required: this.boolean(null).nullable(),
      schedule: this.hasOne(Schedule, 'post_id'),
      sent_at: this.string('').nullable(),
      status: this.string(''),
      subject: this.string(''),
      suggestion: this.string(''),
      targets: this.hasManyThrough(Target, Schedule, 'post_id', 'schedule_id'),
      title: this.string(''),
      total_targets: this.attr(0),
      type: this.string('Post'),
      updated_at: this.string(''),
    };
  }

  actions = () => {
    return {
      button: {
        clickHandler: () => null,
        hasRoute: false,
        isOutlined: true,
        params: {},
        route: '',
        text: '',
      },
    };
  };

  clickEvents = () => {
    return uniqBy(
      this.events.filter(event => event.event_type === 'click'),
      'user_id',
    );
  };

  allClickEvents = () => {
    return this.events.filter(event => event.event_type === 'click');
  };

  deliveryEvents = () => {
    return uniqBy(
      this.events.filter(event => event.event_type === 'delivery'),
      'user_id',
    );
  };

  groupClicks = () => {
    const group = groupBy(this.allClickEvents(), 'event_data.target_link_url');

    const clicks = Object.keys(group).map(data => {
      const events = groupBy(group[data], 'event_data.rcpt_to');

      return {
        events: Object.keys(events).map(email => ({
          count: events[email].length,
          email,
          id: email,
        })),
        favicon: group[data][0].event_data.favicon,
        id: data,
        total_count: Object.keys(events).reduce(
          (sum, key) => sum + parseFloat(events[key].length || 0),
          0,
        ),
        url: data,
      };
    });

    return clicks;
  };

  openPercentage = () => {
    return Math.ceil(this.open_percent);
  };

  clickPercentage = () => {
    return Math.ceil(this.click_percent);
  };

  ratedPercentage = () => {
    return Math.ceil(this.rated_percent);
  };

  readPercentage = () => {
    return Math.ceil(this.marked_as_read_percent);
  };

  details = () => {
    return {
      hasIcon: false,
      html: '',
      icon: '',
    };
  };

  firstClicked = () => {
    return uniqBy(
      this.events.filter(event => event.event_type === 'click'),
      'user_id',
    );
  };

  markReadEvents = () => {
    return uniqBy(
      this.events.filter(event => event.event_type === 'mark_read'),
      'user_id',
    );
  };

  openEvents = () => {
    return uniqBy(
      this.events.filter(event => event.event_type === 'open', 'user_id'),
    );
  };

  initialOpenEvents = () => {
    return uniqBy(
      this.events.filter(event => event.event_type === 'initial_open'),
      'user_id',
    );
  };

  ratedEvents = () => {
    return uniqBy(
      this.events.filter(event => event.event_type === 'rating'),
      'user_id',
    );
  };

  recipients = () => {
    return this.targets.filter(target => target.target_type === 'User');
  };

  reminder = () => {
    return this.events.filter(event => event.event_type === 'reminder_sent');
  };

  reminderDate = () => {
    return dayjs(this.schedule.scheduled_at)
      .utc()
      .add(2, 'day')
      .format('MMM DD, YYYY');
  };

  subhead = () => {
    return {
      action: {
        hasLink: false,
        link: {},
        text: `Edited ${
          this.updated_at ? dayjs(this.updated_at).utc().fromNow() : ''
        }`,
      },
      details: {
        hasLink: true,
        link: {
          params: {
            id: this.id,
          },
          route: 'PostShow',
          text: 'View Online',
        },
        text: '',
      },
    };
  };
}

export default Post;
