import { CurrentUser, User } from '~/models/userHierarchy';

function forgotPassword(payload) {
  const { email } = payload;
  return User.api().post('/users/password', {
    user: {
      email,
    },
  });
}

function login(payload) {
  const { email, password } = payload;
  return CurrentUser.api().post('/users/sign_in', {
    user: {
      email,
      password,
    },
  });
}

function logout() {
  return CurrentUser.api().delete('/users/sign_out');
}

function resendConfirmation(payload) {
  const { email } = payload;
  return User.api().post('/users/confirmation', {
    user: {
      email,
    },
  });
}

function resetPassword(payload) {
  const {
    authenticity_token,
    reset_password_token,
    password,
    password_confirmation,
  } = payload;
  return User.api().put('/users/password', {
    authenticity_token,
    user: {
      password,
      password_confirmation,
      reset_password_token,
    },
  });
}

function saveAccount(payload) {
  const { account, id } = payload;
  return User.api().put(`/api/accounts/${id}`, {
    account,
  });
}

function updateConfirmation(payload) {
  const { confirmation_token, password, password_confirmation } = payload;

  return User.api().patch('/devise/confirmation', {
    confirmation_token,
    user: {
      password,
      password_confirmation,
    },
  });
}

export const userService = {
  forgotPassword,
  login,
  logout,
  resendConfirmation,
  resetPassword,
  saveAccount,
  updateConfirmation,
};
