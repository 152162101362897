import Comment from '~/models/comment';
import store from '~/store';

function bulkFollowComments(payload) {
  const { commentIds } = payload;

  return Comment.api().put('/api/comments/bulk_follow', {
    comment_ids: commentIds,
  });
}

function bulkUnfollowComments(payload) {
  const { commentIds } = payload;

  return Comment.api().put('/api/comments/bulk_unfollow', {
    comment_ids: commentIds,
  });
}

function followComment(payload) {
  const { id } = payload;

  return Comment.api().put(`/api/comments/${id}/follow`);
}

function getCommentById(payload) {
  const { id } = payload;

  return Comment.api().get(`/api/comments/${id}`);
}

function getComments() {
  return Comment.api().get('/api/comments');
}

async function getCommentsByPage(payload) {
  const { isPaginated, limit, page } = payload;

  const result = await Comment.api()
    .get('/api/comments', {
      params: {
        limit: limit || 30,
        order: 'created_at,desc',
      },
    })
    .then(response => {
      if (isPaginated) {
        const {
          response: { headers },
        } = response;

        const data = {
          baseUrl: '/api/comments',
          context: Comment,
          page,
          perPage: +headers['x-per-page'],
          total: +headers['x-total'],
        };

        store.dispatch('pagination/setHeaders', data);
      }
    });

  return result;
}

async function getCommentsByUser(payload) {
  const { limit, source, userId } = payload;
  const url = `/api/user_managements/${userId}/comments`;

  let params = {
    limit: limit || 30,
    order: 'created_at,desc',
  };

  if (source) {
    params = { ...params, source };
  }

  const result = await Comment.api()
    .get(url, {
      params,
    })
    .then(response => {
      const {
        response: { headers },
      } = response;

      let data = {
        baseUrl: url,
        context: Comment,
        page: 'profile-comments',
        perPage: limit || +headers['x-per-page'],
        total: +headers['x-total'],
      };

      if (source) {
        data = { ...data, source };
      }

      store.dispatch('pagination/setHeaders', data);
    });

  return result;
}

function markAsRead(payload) {
  const { commentIds } = payload;

  return Comment.api().put('/api/comments/mark_as_read', {
    comment_ids: commentIds,
  });
}

function unfollowComment(payload) {
  const { id } = payload;

  return Comment.api().put(`/api/comments/${id}/unfollow`);
}

export const commentService = {
  bulkFollowComments,
  bulkUnfollowComments,
  followComment,
  getCommentById,
  getComments,
  getCommentsByPage,
  getCommentsByUser,
  markAsRead,
  unfollowComment,
};
