import { Model } from '@vuex-orm/core';

export class CheckinStat extends Model {
  static get entity() {
    return 'CheckinStat';
  }

  static fields() {
    return {
      confidence: this.number(0),
      date: this.string(''),
      mood: this.number(0),
      id: this.attr(null),
      workload: this.number(0),
    };
  }
}

export default CheckinStat;
