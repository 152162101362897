import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
      // types: [
      //   {
      //     "kind": "UNION",
      //     "name": "Targetable",
      //     "possibleTypes": [
      //       {
      //         "name": "Account"
      //       },
      //       {
      //         "name": "Group"
      //       },
      //       {
      //         "name": "User"
      //       },
      //     ],
      //   },
      // ],
    },
  }
})

const cache = new InMemoryCache({
  fragmentMatcher,
});

// import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
// import axios from 'axios';

// let cache;

// const main = async () => {
//   await axios.post(`http://localhost:5000/graphql`, {
//     headers: { 'Content-Type': 'application/json' },
//     data: {
//       variables: {},
//       query: `
//         {
//           __schema {
//             types {
//               kind
//               name
//               possibleTypes {
//                 name
//               }
//             }
//           }
//         }
//       `,
//     },
//   }).then(result => {
//     const possibleTypes = {};
//     console.log(result.data);

//     result.data.__schema.types.forEach(supertype => {
//       if (supertype.possibleTypes) {
//         possibleTypes[supertype.name] =
//           supertype.possibleTypes.map(subtype => subtype.name);
//       }
//     });

//     const resultData = JSON.stringify(possibleTypes);

//     const fragmentMatcher = new IntrospectionFragmentMatcher({
//       introspectionQueryResultData: {
//         ...resultData,
//       },
//     });

//     cache = new InMemoryCache({
//       fragmentMatcher,
//     });
//   });
// }

// main();

export default cache;
