const ContactCheckin = () => import('~/views/contacts/checkins/Show');
const ContactEngagement = () => import('~/views/contacts/engagement/Show');
const ContactFeedback = () => import('~/views/contacts/feedback/Show');
const ContactMailableShow = () => import('~/views/contacts/mailable/Show');
const ContactPromoterScore = () => import('~/views/contacts/enps/Show');

export const contact = [
  {
    meta: {
      adminRestricted: true,
      requiresAuth: true,
    },
    name: 'ContactIndex',
    path: '/contacts',
    redirect: { name: 'TeamManagementIndividuals' },
  },
  {
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'ContactEdit',
    path: '/contacts/:id/edit',
    redirect: { name: 'UserEdit' },
  },
  {
    component: ContactCheckin,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'ContactCheckin',
    path: '/contacts/:id/checkins',
  },
  {
    component: ContactPromoterScore,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'ContactPromoterScore',
    path: '/contacts/:id/enps',
  },
  {
    component: ContactEngagement,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'ContactEngagement',
    path: '/contacts/:id/engagement',
  },
  {
    component: ContactMailableShow,
    meta: {
      adminRestricted: true,
      requiresAuth: false,
    },
    name: 'ContactMailableShow',
    path: '/contacts/:id/mailable',
  },
  {
    component: ContactFeedback,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'ContactFeedback',
    path: '/contacts/:id/feedback',
  },
];
