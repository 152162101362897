import { Event } from '~/models/eventHierarchy';

export class ReminderSent extends Event {
  static get entity() {
    return 'reminder_sent';
  }

  static baseEntity = 'event';

  static fields() {
    return {
      ...super.fields(),
    };
  }

  description = (user = null) => {
    const from = user ? user : 'This contact';
    return `${from} has received a reminder about a broadcast.`;
  }

  icon = () => {
    return 'mdi-email-receive';
  }

  title = () => {
    return 'Reminder';
  }
}

export default ReminderSent;
