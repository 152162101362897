import axios from 'axios';

function getOnboard() {
  return axios({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    url: '/api/onboard',
  })
    .then(response => response)
    .catch(error => error);
}

function updateOnboard(payload) {
  const { authenticityToken, data } = payload;

  return axios({
    data: { ...data },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'PUT',
    url: '/api/onboard',
  }).then(response => response);
}

export const funnelService = {
  getOnboard,
  updateOnboard,
};
