import { Model } from '@vuex-orm/core';

export default class PayPlan extends Model {
  static get entity() {
    return 'PayPlan';
  }

  static fields() {
    return {
      id: this.attr(null),
      legacy: this.boolean(false),
      name: this.string(''),
      package_type: this.string(''),
      plan_interval: this.string(''),
      plan_price: this.number(0),
      seats: this.number(0),
      stripe_id: this.string(''),
      subscription_id: this.attr(null),
      visible: this.boolean(false),
      weight: this.number(-1),
    };
  }
}
