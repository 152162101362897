import { roundToPrecision } from '~/helpers/roundNumber';

export class Smiley {
  constructor(target) {
    this.target = target;
  }

  getConfidence = () => {
    return this.target.type.includes('average')
      ? this.getAveragedSmiley(this.target.confidence)
      : this.getSmiley(this.target.confidence);
  };

  getMood = () => {
    return this.target.type.includes('average')
      ? this.getAveragedSmiley(this.target.mood)
      : this.getSmiley(this.target.mood);
  };

  getWorkload = () => {
    return this.target.type.includes('average')
      ? this.getAveragedSmiley(this.target.workload)
      : this.getSmiley(this.target.workload);
  };

  getAveragedSmiley = type => {
    const rounded = roundToPrecision(type, 0.2);
    switch (rounded) {
      case 0.2:
        return {
          hasIcon: true,
          icon: { color: '#e02020', type: 'mdi-emoticon-angry' },
        };
      case 0.4:
        return {
          hasIcon: true,
          icon: { color: '#ff731c', type: 'mdi-emoticon-sad' },
        };
      case 0.6:
        return {
          hasIcon: true,
          icon: { color: '#ffd400', type: 'mdi-emoticon-neutral' },
        };
      case 0.8:
        return {
          hasIcon: true,
          icon: { color: '#addd14', type: 'mdi-emoticon-happy' },
        };
      case 1:
        return {
          hasIcon: true,
          icon: { color: '#6bcd03', type: 'mdi-emoticon-excited' },
        };
      default:
        return {
          icon: { color: '#cbcbcb', type: 'mdi-emoticon-neutral' },
        };
    }
  };

  getSmiley = type => {
    switch (type) {
      case '1':
        return {
          hasIcon: true,
          icon: { color: '#e02020', type: 'mdi-emoticon-angry' },
        };
      case '2':
        return {
          hasIcon: true,
          icon: { color: '#ff731c', type: 'mdi-emoticon-sad' },
        };
      case '3':
        return {
          hasIcon: true,
          icon: { color: '#ffd400', type: 'mdi-emoticon-neutral' },
        };
      case '4':
        return {
          hasIcon: true,
          icon: { color: '#addd14', type: 'mdi-emoticon-happy' },
        };
      case '5':
        return {
          hasIcon: true,
          icon: { color: '#6bcd03', type: 'mdi-emoticon-excited' },
        };
      default:
        return {
          icon: { color: '#cbcbcb', type: 'mdi-emoticon-neutral' },
        };
    }
  };
}

export default Smiley;
