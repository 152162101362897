import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Model } from '@vuex-orm/core';
import { MAX_ENGAGEMENT_SCORE } from '~/helpers/constants';

import CheckinTarget from '~/models/checkinTarget';
import HealthScore from '~/models/healthScore';
import PlanFeature from '~/models/planFeature';
import PromoterScoreTarget from '~/models/promoterScoreTarget';
import SendingDomain from '~/models/sendingDomain';
import Smiley from '~/models/smiley';
import Subscription from '~/models/subscription';
import { User } from '~/models/userHierarchy';

dayjs.extend(utc);

export class Account extends Model {
  static get entity() {
    return 'Account';
  }

  static fields() {
    return {
      active: this.boolean(true),
      additional_seat_price: this.number(0),
      checkin_targets: this.morphMany(
        CheckinTarget,
        'targetable_id',
        'targetable_type',
      ),
      created_at: this.string(''),
      crud_templates: this.boolean(false),
      engagement: this.number(0),
      from_email: this.string('').nullable(),
      groupless_users_count: this.number(0),
      has_imported_contacts: this.boolean(false),
      has_requested_feedback: this.boolean(false),
      has_scheduled_broadcast: this.boolean(false),
      has_scheduled_checkin: this.boolean(false),
      has_scheduled_enps: this.boolean(false),
      has_scheduled_request_feedback: this.boolean(false),
      has_scheduled_survey: this.boolean(false),
      has_sent_broadcast: this.boolean(false),
      has_sent_checkin: this.boolean(false),
      has_sent_enps: this.boolean(false),
      has_sent_followup: this.boolean(false),
      has_sent_survey: this.boolean(false),
      has_trialed: this.boolean(false),
      health_score_pct: this.number(0),
      health_scores: this.morphMany(HealthScore, 'entity_id', 'entity_type'),
      id: this.attr(null),
      logo_url: this.string(''),
      name: this.string('').nullable(),
      onboarding_step: this.string(''),
      plans_features: this.hasMany(PlanFeature, 'account_id'),
      promoter_score_targets: this.morphMany(
        PromoterScoreTarget,
        'targetable_id',
        'targetable_type',
      ),
      seats: this.number(0),
      sending_domain: this.string('').nullable(),
      sending_domains: this.hasMany(SendingDomain, 'account_id'),
      show_onboarding: this.boolean(false),
      subscription: this.hasOne(Subscription, 'account_id'),
      thirty_day_health_score_change: this.attr(null).nullable(),
      time_zone: this.string('').nullable(),
      type: this.string(''),
      updated_at: this.string(''),
      users: this.hasMany(User, 'user_id'),
      users_count: this.number(0),
    };
  }

  displayEngagement = () => {
    switch (true) {
      case this.engagement >= 0 && this.engagement <= 4:
        return {
          score: ((this.engagement / MAX_ENGAGEMENT_SCORE) * 100).toFixed(0),
          status: {
            className: 'unengaged',
            label: 'Unengaged',
          },
        };
      case this.engagement >= 5 && this.engagement <= 9:
        return {
          score: ((this.engagement / MAX_ENGAGEMENT_SCORE) * 100).toFixed(0),
          status: {
            className: 'at-risk',
            label: 'At Risk',
          },
        };
      case this.engagement >= 10 && this.engagement <= 14:
        return {
          score: ((this.engagement / MAX_ENGAGEMENT_SCORE) * 100).toFixed(0),
          status: {
            className: 'engaged',
            label: 'Engaged',
          },
        };
      default:
        return {
          score: 0,
          status: {
            className: '',
            label: '—',
          },
        };
    }
  };

  getEnpsCounts = (type, date) => {
    const values = this.promoter_score_targets.filter(
      target => target[type] !== null,
    );

    if (!values.length) {
      return NaN;
    }

    switch (type) {
      case 'detractors':
      case 'passives':
      case 'promoters':
        return this.promoter_score_targets
          .filter(
            target =>
              dayjs(target.created_at).utc().format('YYYY-MM-DD') ===
              dayjs(date).utc().format('YYYY-MM-DD'),
          )
          .reduce((total, next) => total + next[type], 0);

      case 'score': {
        const initialValues = {
          completed_count: 0,
          detractors: 0,
          passives: 0,
          promoters: 0,
        };

        const counts = this.promoter_score_targets
          .filter(
            target =>
              dayjs(target.created_at).utc().format('YYYY-MM-DD') ===
              dayjs(date).utc().format('YYYY-MM-DD'),
          )
          .reduce((total, next) => {
            return {
              completed_count: total.completed_count + next.completed_count,
              detractors: total.detractors + next.detractors,
              promoters: total.promoters + next.promoters,
            };
          }, initialValues);

        return (
          ((counts.promoters - counts.detractors) / counts.completed_count) *
          100
        );
      }

      default:
        return NaN;
    }
  };

  getHealthScore = () => {
    if (this.health_score_pct === 0 && !this.health_scores.length) {
      return null;
    }

    return this.health_score_pct;
  };

  getOverallCount = type => {
    const values = this.checkin_targets.filter(target => target[type] !== null);

    return values.length
      ? this.checkin_targets.reduce((total, next) => total + next[type], 0) /
          values.length
      : NaN;
  };

  healthScore = () => {
    return {
      displayName: '',
      dot: {
        className: '',
      },
      score: 0,
      status: {
        label: 'N/A',
      },
    };
  };

  isFeatureUnlocked = (featureName, metaName = null) => {
    const access = this.plans_features.filter(
      feature => feature.feature_name === featureName,
    );

    if (!access.length) {
      return false;
    }

    const [feature] = access;

    if (metaName && feature.meta) {
      return !!feature.meta[metaName];
    }

    return feature.permitted;
  };

  isOverUserCount = () => {
    return this.users_count >= this.seats;
  };

  overallCheckin = () => {
    return new Smiley({
      confidence: this.overallConfidence(),
      mood: this.overallMood(),
      type: 'average',
      workload: this.overallWorkload(),
    });
  };

  overallConfidence = () => {
    const values = this.checkin_targets.filter(
      target => target.confidence !== null,
    );

    return values.length
      ? this.checkin_targets.reduce(
          (total, next) => total + next.confidence,
          0,
        ) / values.length
      : NaN;
  };

  overallMood = () => {
    const values = this.checkin_targets.filter(target => target.mood !== null);

    return values.length
      ? this.checkin_targets.reduce((total, next) => total + next.mood, 0) /
          values.length
      : NaN;
  };

  overallWorkload = () => {
    const values = this.checkin_targets.filter(
      target => target.workload !== null,
    );

    return values.length
      ? this.checkin_targets.reduce((total, next) => total + next.workload, 0) /
          values.length
      : NaN;
  };

  promoterScoreTotalTargets = () => {
    return this.promoter_score_targets.reduce(
      (total, next) => total + next.total_targets,
      0,
    );
  };
}

export default Account;
