import { User } from '~/models/userHierarchy';
import { FEATURES } from '~/helpers/constants';

export class GroupAdmin extends User {
  static get entity() {
    return 'GroupAdmin';
  }

  static baseEntity = 'User';

  static fields() {
    return {
      ...super.fields(),
    };
  }

  accountTabs = () => {
    return [
      {
        active: false,
        feature: FEATURES.INTEGRATIONS,
        name: 'Integrations',
        params: {},
        url: 'IntegrationIndex',
      },
    ];
  };

  commentsNavigationRoute = () => {
    return {
      name: 'InsightCommentIndex',
      params: {},
    };
  };

  hasAdminAccess = () => {
    return false;
  };

  hasAccess = () => {
    return true;
  };

  insightTabs = () => {
    return [
      {
        disabled: false,
        name: 'Health Score',
        params: {},
        url: 'InsightHealthScoreShow',
      },
      {
        disabled: false,
        name: 'Check-ins',
        params: {},
        url: 'InsightCheckinIndex',
      },
      {
        disabled: false,
        feature: FEATURES.ENPS,
        name: 'eNPS',
        params: {},
        url: 'InsightPromoterScoreIndex',
      },
      {
        disabled: false,
        name: 'Comments',
        params: {},
        url: 'InsightCommentIndex',
      },
    ];
  };

  postAccess = post => {
    if (this.id === post.creator_id) {
      switch (post.status) {
        case 'draft':
          return 'draft-post';
        case 'scheduled':
          return 'scheduled-post';
        case 'sending':
          return 'sending-post';
        default:
          return 'sent-post';
      }
    } else {
      return 'user-post';
    }
  };

  postCrumbItems = post => {
    return [
      {
        disabled: false,
        href: '/posts',
        text: 'Broadcasts',
      },
      {
        disabled: true,
        href: '',
        text: post.subject,
      },
    ];
  };

  tabs = id => {
    return [
      {
        name: 'Profile',
        params: id,
        url: 'UserEdit',
      },
      {
        name: 'Health Score',
        params: id,
        url: 'UserHealthScore',
      },
      {
        name: 'Check-ins',
        params: id,
        url: 'UserCheckin',
      },
      {
        feature: FEATURES.ENPS,
        name: 'eNPS',
        params: id,
        url: 'UserPromoterScore',
      },
      {
        name: 'Comments',
        params: id,
        url: 'UserFeedback',
      },
    ];
  };
}

export default GroupAdmin;
