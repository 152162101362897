<template>
  <div>
    <v-app-bar
      v-if="isLoggedIn && account"
      absolute
      app
      class="nav-logged-in"
      clipped-left
      color="primary"
      flat
      height="72">
      <v-toolbar-title class="headline mr-10 text-uppercase">
        <router-link
          class="app-bar-link toolbar-title-link"
          :to="{ name: 'Dashboard' }">
          <img
            :src="account.logo_url"
            :alt="account.name">
        </router-link>
      </v-toolbar-title>

      <v-spacer />

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        color="white"
        :ripple="false"
        @click="toggleDrawer" />

      <v-menu
        left
        offset-y>
        <template #activator="{ on }">
          <v-btn
            class="hidden-sm-and-down"
            icon
            :ripple="false"
            x-large
            v-on="on">
            <v-avatar
              v-if="hasDefaultAvatar"
              color="accent"
              size="42">
              <span class="white--text">{{ avatarInitials }}</span>
            </v-avatar>
            <div
              v-else
              class="user-avatar"
              :style="{ backgroundImage: `url(${currentUser.avatar_url})` }"
              :title="`${currentUser.first_name} ${currentUser.last_name}`" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ currentUser.username() }}</v-list-item-title>
              <v-list-item-subtitle>{{ currentUser.role.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item-group>
            <v-list-item
              :ripple="false"
              :to="{ name: 'UserEdit', params: { id: currentUser.id } }">
              <v-list-item-title>Profile &amp; Preferences</v-list-item-title>
            </v-list-item>
          </v-list-item-group>

          <v-list-item-group v-if="hasAccess && hasAdminAccess">
            <v-list-item
              :ripple="false"
              :to="{ name: accountBillingRoute }">
              <v-list-item-title>Account &amp; Billing</v-list-item-title>
            </v-list-item>
          </v-list-item-group>

          <v-list-item-group v-if="hasAccess">
            <v-list-item
              :ripple="false"
              :to="{ name: 'TeamManagementIndividuals' }">
              <v-list-item-title>Team Management</v-list-item-title>
            </v-list-item>
          </v-list-item-group>

          <v-list-item-group v-if="hasAccess">
            <v-list-item
              :ripple="false"
              :to="{ name: 'IntegrationIndex' }">
              <v-list-item-title>
                Integrations
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>

          <v-divider />

          <v-list-item-group>
            <v-list-item
              rel="nofollow"
              :ripple="false"
              tag="a"
              @click="logout">
              <v-list-item-title class="log-out">
                Log Out
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>

          <v-divider />

          <v-list-item-group>
            <v-list-item
              href="https://chrome.google.com/webstore/detail/recess/jcmafppmgmdgjflfjgpkbcmigkcchpeo"
              :ripple="false"
              target="_blank">
              <v-list-item-title class="install">
                Install Chrome Extension
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-app-bar
      v-else
      :absolute="true"
      app
      class="nav-logged-out"
      color="primary"
      flat
      height="72">
      <v-toolbar-title class="headline mr-10 text-uppercase">
        <router-link
          class="app-bar-link"
          to="/users/sign_in">
          <img
            :src="require('~/assets/recess-light-transparent-logo-high-res.png')"
            alt="Recess">
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        color="white"
        :ripple="false"
        @click="toggleDrawer" />
      <span class="action-text hidden-sm-and-down mr-4 white--text">Don't have an account?</span>
      <v-btn
        class="btn-register hidden-sm-and-down"
        color="white"
        href="https://www.recess.io/pricing"
        large
        outlined
        :ripple="false"
        rounded>
        <span>Register Now</span>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import currentUser from '~/mixins/currentUser';
import store from '~/store';
import { userService } from '~/services';

export default {
  mixins: [currentUser],
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    accountBillingRoute() {
      const [firstTab] = this.currentUser.accountTabs();

      if (firstTab) {
        return firstTab.url;
      }

      return 'TeamManagementIndividuals';
    },
    avatarInitials() {
      if (Object.keys(this.currentUser).length) {
        return this.currentUser.avatarInitials();
      }

      return null;
    },
    hasAccess() {
      return this.account && this.isLoggedIn && this.$route.path !== '/onboard' && this.currentUser.hasAccess();
    },
    hasDefaultAvatar() {
      if (this.currentUser && Object.keys(this.currentUser).length) {
        return Boolean(this.currentUser.avatar_url.includes('default-avatar'));
      }

      return false;
    },
  },
  methods: {
    ...mapMutations([
      'toggleDrawer',
    ]),
    async logout() {
      await userService.logout()
        .then(() => {
          store.dispatch('entities/deleteAll');
          document.querySelector('body').setAttribute('data-user', '');
          document.querySelector('body').setAttribute('data-account', '');
          window.location.href = '/users/sign_in';
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .theme--light.v-sheet {
    z-index: 3;
  }

  .v-list-item--disabled {
    flex-direction: column;
    align-items: flex-start;
  }

  .v-toolbar__content > .v-btn.v-btn--icon:last-child {
    margin-right: -4px;
  }

  .install {
    font-size: 0.8rem;
  }
</style>

<style lang="scss">
  @import '~/sass/variables';

  .v-toolbar__content {
    margin: 0 auto;
    padding: 0 12px !important;

    .navbar-links {
      align-items: center;
      display: flex;
      height: 100%;
      margin-right: 5rem;
    }

    .action-text {
      opacity: 0.6;
    }

    .v-btn {
      &.btn-navbar {
        position: relative;

        &::after {
          background-color: transparent;
          bottom: 0;
          content: '';
          display: block;
          height: 6px;
          opacity: 0;
          position: absolute;
          transform: translateY(100%);
          transition: 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
          width: calc(100% - 16px);
        }
      }

      span,
      .v-icon {
        font-size: 16px;
        font-weight: 600;
      }

      &.btn-register {
        opacity: 0.8;
      }

      &.v-btn--active {
        &::after {
          background-color: $accent;
          opacity: 1;
          transform: translateY(0);
        }

        &::before {
          opacity: 0 !important;
        }
      }
    }

    .v-btn:not(.v-btn--round).v-size--default {
      margin: 0 8px;
      padding: 0 8px;
    }
  }

  .user-avatar {
    background-position: center center;
    background-size: cover;
    border-radius: 100%;
    height: 42px;
    width: 42px;
  }

  .v-application .app-bar-link {
    color: $dark-grey;
    text-decoration: none;
  }

  .theme--light.v-list {
    padding: 0;
  }

  .nav-logged-out .v-toolbar__content,
  .nav-logged-in .v-toolbar__content {
    .app-bar-link,
    .toolbar-title-link {
      color: $white;
      font-size: 12px;
      line-height: 1;
    }

    .v-toolbar__title {
      display: flex;
    }

    img {
      vertical-align: middle;
      width: auto;
    }
  }

  .nav-logged-out .v-toolbar__content {
    max-width: 632px;

    .app-bar-link img {
      max-height: 30px;
    }
  }

  .nav-logged-in .v-toolbar__content {
    width: 94%;

    .toolbar-title-link img {
      max-height: 40px;
    }
  }

  .icon-lock {
    margin-left: 4px;
    opacity: 0.9;
  }

  .is-locked {
    opacity: 0.5;
  }

  .log-out {
    color: $primary;
  }
</style>
