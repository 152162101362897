export class Status {
  constructor(healthScore) {
    this.healthScore = healthScore;

    /* eslint-disable */
    this.colors = {
      low: '#ffd400',
      medium: '#addd14',
      high: '#59ae00',
    };

    this.labels = {
      low: 'Low',
      medium: 'Average',
      high: 'High',
    };
    /* eslint-enable */
  }

  get() {
    switch (true) {
      case this.healthScore === null:
        return {
          hexCode: '#e8ecef',
          label: String.fromCharCode(8212),
        };
      case this.healthScore >= 0 && this.healthScore <= 32:
        return {
          hexCode: this.colors.low,
          label: this.labels.low,
        };
      case this.healthScore >= 33 && this.healthScore <= 67:
        return {
          hexCode: this.colors.medium,
          label: this.labels.medium,
        };
      case this.healthScore >= 68 && this.healthScore <= 100:
        return {
          hexCode: this.colors.high,
          label: this.labels.high,
        };
      default:
        return {
          hexCode: '#e8ecef',
          label: String.fromCharCode(8212),
        };
    }
  }

  getColorsWithLabels() {
    const result = {};

    for (const [key, value] of Object.entries(this.colors)) {
      if (!result[key]) {
        result[key] = {
          color: value,
        };
      }
    }

    for (const [key, value] of Object.entries(this.labels)) {
      if (result[key]) {
        result[key] = {
          ...result[key],
          label: value,
        };
      }
    }

    return result;
  }
}

export default Status;
