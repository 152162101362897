import { FEATURES } from '~/helpers/constants';

const AccountOnboard = () => import('~/views/accounts/onboard/Onboard');
const AccountShow = () => import('~/views/accounts/show/Show');
const AddRole = () => import('~/views/accounts/userManagement/AddRole');
const BillingHistoryShow = () =>
  import('~/views/accounts/subscription/BillingHistory');
const ListRoles = () => import('~/views/accounts/userManagement/ListRoles');
const PaymentInformationShow = () =>
  import('~/views/accounts/subscription/PaymentInformation');
const SendingDomainIndex = () =>
  import('~/views/accounts/sendingDomains/Index');
const SendingDomainShow = () => import('~/views/accounts/sendingDomains/Show');
const SubscriptionShow = () => import('~/views/accounts/subscription/Show');

export const account = [
  {
    component: AccountOnboard,
    meta: {
      adminRestricted: true,
      isNavigationDrawerVisible: false,
      isTopNavigationVisible: false,
      requiresAuth: true,
    },
    name: 'AccountOnboard',
    path: '/onboard',
  },
  {
    component: AccountShow,
    meta: {
      adminRestricted: true,
      requiresAuth: true,
    },
    name: 'AccountShow',
    path: '/accounts',
  },
  {
    component: SendingDomainIndex,
    meta: {
      adminRestricted: true,
      featureName: FEATURES.SENDING_DOMAINS,
      permissionName: 'approve_email_domain',
      requiresAuth: true,
    },
    name: 'SendingDomainIndex',
    path: '/sending_domains',
  },
  {
    component: SendingDomainShow,
    meta: {
      adminRestricted: true,
      featureName: FEATURES.SENDING_DOMAINS,
      parentComponent: 'SendingDomainIndex',
      permissionName: 'approve_email_domain',
      requiresAuth: true,
    },
    name: 'SendingDomainShow',
    path: '/sending_domains/:id',
  },
  {
    component: SubscriptionShow,
    meta: {
      adminRestricted: true,
      permissionName: 'manage_billing_subscription',
      requiresAuth: true,
    },
    name: 'SubscriptionShow',
    path: '/subscription',
  },
  {
    component: PaymentInformationShow,
    meta: {
      adminRestricted: true,
      parentComponent: 'SubscriptionShow',
      permissionName: 'manage_billing_subscription',
      requiresAuth: true,
    },
    name: 'PaymentInformationShow',
    path: '/subscription/payment_information',
  },
  {
    component: BillingHistoryShow,
    meta: {
      adminRestricted: true,
      parentComponent: 'SubscriptionShow',
      permissionName: 'manage_billing_subscription',
      requiresAuth: true,
    },
    name: 'BillingHistoryShow',
    path: '/subscription/billing_history',
  },
  {
    meta: {
      adminRestricted: true,
      requiresAuth: true,
    },
    name: 'UserManagementIndex',
    path: '/users_management',
    redirect: { name: 'TeamManagementIndividuals' },
  },
  {
    component: AddRole,
    meta: {
      adminRestricted: true,
      permissionName: 'add_custom_roles',
      requiresAuth: true,
    },
    name: 'AddRole',
    path: '/roles/new',
  },
  {
    component: AddRole,
    meta: {
      adminRestricted: true,
      permissionName: 'add_custom_roles',
      requiresAuth: true,
    },
    name: 'EditRole',
    path: '/roles/:id/edit',
  },
  {
    meta: {
      adminRestricted: true,
      requiresAuth: true,
    },
    name: 'ListGroups',
    path: '/user_groups',
    redirect: { name: 'TeamManagementGroups' },
  },
  {
    component: ListRoles,
    meta: {
      adminRestricted: true,
      permissionName: 'add_custom_roles',
      requiresAuth: true,
    },
    name: 'ListRoles',
    path: '/user_roles',
  },
];
