import { GroupAdmin } from '~/models/userHierarchy';
import { FEATURES } from '~/helpers/constants';

export class Admin extends GroupAdmin {
  static get entity() {
    return 'Admin';
  }

  static baseEntity = 'GroupAdmin';

  static fields() {
    return {
      ...super.fields(),
    };
  }

  accountTabs = () => {
    return [
      {
        active: false,
        feature: FEATURES.SENDING_DOMAINS,
        name: 'Sending Domains',
        params: {},
        url: 'SendingDomainIndex',
      },
      {
        active: false,
        feature: FEATURES.INTEGRATIONS,
        name: 'Integrations',
        params: {},
        url: 'IntegrationIndex',
      },
    ];
  };

  hasAdminAccess = () => {
    return true;
  };

  insightTabs = () => {
    return [
      {
        disabled: false,
        name: 'Health Score',
        params: {},
        url: 'InsightHealthScoreShow',
      },
      {
        disabled: false,
        name: 'Check-ins',
        params: {},
        url: 'InsightCheckinIndex',
      },
      {
        disabled: false,
        feature: FEATURES.ENPS,
        name: 'eNPS',
        params: {},
        url: 'InsightPromoterScoreIndex',
      },
      {
        disabled: false,
        feature: FEATURES.SURVEYS,
        name: 'Surveys',
        params: {},
        url: 'InsightSurveyIndex',
      },
      {
        disabled: false,
        name: 'Comments',
        params: {},
        url: 'InsightCommentIndex',
      },
    ];
  };

  postAccess = post => {
    switch (post.status) {
      case 'draft':
        return 'draft-post';
      case 'scheduled':
        return 'scheduled-post';
      case 'sending':
        return 'sending-post';
      default:
        return 'sent-post';
    }
  };

  postCrumbItems = post => {
    return [
      {
        disabled: false,
        href: '/posts',
        text: 'Broadcasts',
      },
      {
        disabled: false,
        href: '/posts/stats',
        text: 'Statistics',
      },
      {
        disabled: true,
        href: '',
        text: post.subject,
      },
    ];
  };
}

export default Admin;
