import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';

// Require Froala Editor js & css files.
import 'froala-editor/js/froala_editor.pkgd.min';
import 'froala-editor/js/plugins.pkgd.min';
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

import ActionCableVue from 'actioncable-vue';
import VueFroala from 'vue-froala-wysiwyg';
import App from '../app';
import apolloProvider from '~/apollo';
import router from '~/routes/router';
import store from '../store';
import vuetify from '~/plugins/vuetify';

Vue.use(ActionCableVue, {
  connectImmediately: true,
  connectionUrl: "/cable",
  debug: process.env.NODE_ENV !== 'production',
  debugLevel: process.env.NODE_ENV !== 'production' ? 'all' : 'error',
});

Vue.use(VueFroala);

document.addEventListener(
  'DOMContentLoaded',
  () =>
    new Vue({
      el: document.getElementById('vue-app'),
      apolloProvider,
      router,
      store,
      vuetify,
      render: h => h(App),
    }),
);
