import { Model } from '@vuex-orm/core';

export default class Permission extends Model {
  static get entity() {
    return 'Permission';
  }

  static fields() {
    return {
      id: this.attr(null),
      permission_id: this.attr(null),
      permission_name: this.string(''),
      permitted: this.attr(null),
      role_id: this.attr(null),
    };
  }
}
