import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Post } from '~/models/postHierarchy';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export class PostError extends Post {
  static get entity() {
    return 'PostError';
  }

  static baseEntity() {
    return 'Post';
  }

  static fields() {
    return {
      ...super.fields(),
    };
  }

  actions = () => {
    return {
      button: {
        hasRoute: true,
        isOutlined: false,
        params: {
          id: this.id,
        },
        route: 'ScheduleNew',
        text: 'Schedule',
      },
    };
  };

  details = () => {
    return {
      hasIcon: false,
      html: `<span class="font-weight-bold red--text">${this.error_message}</span>`,
      icon: '',
    };
  };

  subhead = () => {
    return {
      action: {
        hasLink: false,
        link: {},
        text: `Edited ${
          this.updated_at ? dayjs(this.updated_at).utc().fromNow() : ''
        }`,
      },
      details: {
        hasLink: true,
        link: {
          params: {
            id: this.id,
          },
          route: 'ScheduleNew',
          text: 'Unscheduled',
        },
        text: '',
      },
    };
  };
}

export default PostError;
