import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Model } from '@vuex-orm/core';
import Account from '~/models/account';
import Checkin from '~/models/checkin';
import CheckinTarget from '~/models/checkinTarget';
import CheckinUserAnswer from '~/models/checkinUserAnswer';
import Comment from '~/models/comment';
import { CurrentUser, GroupAdmin, Admin, Owner } from '~/models/userHierarchy';
import { Event } from '~/models/eventHierarchy';
import { Group } from '~/models/groupHierarchy';
import GroupUser from '~/models/groupUser';
import HealthScore from '~/models/healthScore';
import HealthScoreStatus from '~/models/healthScore/status/healthScoreStatus';
import MailableUser from '~/models/mailableUser';
import { MAX_ENGAGEMENT_SCORE, STATUS } from '~/helpers/constants';
import Permission from '~/models/permission';
import PromoterScoreTarget from '~/models/promoterScoreTarget';
import Reply from '~/models/reply';
import Role from '~/models/role';
import RolePermission from '~/models/rolePermission';
import Schedule from '~/models/schedule';
import Smiley from '~/models/smiley';
import SurveyTarget from '~/models/surveyTarget';
import SurveyUserAnswer from '~/models/surveyUserAnswer';
import Target from '~/models/target';
import Trend from '~/models/healthScore/trend/trend';
import ViewableUser from '~/models/viewableUser';

dayjs.extend(utc);

export class User extends Model {
  static get entity() {
    return 'User';
  }

  static typeKey = 'role_id';

  static types() {
    return {
      1: Owner,
      2: Admin,
      3: GroupAdmin,
      4: User,
      CurrentUser,
    };
  }

  static state() {
    return {
      page: {
        name: '',
        params: {},
      },
    };
  }

  static fields() {
    return {
      account: this.hasOne(Account, 'account_id'),
      avatar_url: this.attr(''),
      checkin_targets: this.morphMany(
        CheckinTarget,
        'targetable_id',
        'targetable_type',
      ),
      checkin_user_answers: this.hasMany(CheckinUserAnswer, 'user_id'),
      checkins: this.hasMany(Checkin, 'user_id'),
      city: this.string('').nullable(),
      comment_frequency: this.string(''),
      comment_status: this.boolean(false),
      comments: this.hasMany(Comment, 'user_id'),
      confirmation_sent_at: this.attr('').nullable(),
      country: this.string('').nullable(),
      created_at: this.attr(''),
      deactivated_at: this.attr('').nullable(),
      duplicate: this.boolean(false),
      email: this.string(''),
      engagement_color: this.string(''),
      engagement_score: this.attr(),
      environment: this.string(''),
      events: this.hasMany(Event, 'user_id'),
      first_name: this.string('').nullable(),
      groups: this.belongsToMany(Group, GroupUser, 'user_id', 'group_id'),
      health_score_frequency: this.string(''),
      health_score_pct: this.number(0),
      health_score_status: this.boolean(false),
      health_scores: this.morphMany(HealthScore, 'entity_id', 'entity_type'),
      id: this.attr(null),
      integration_id: this.attr(null).nullable(),
      last_name: this.string('').nullable(),
      mailable_groups: this.belongsToMany(
        Group,
        MailableUser,
        'user_id',
        'group_id',
      ),
      oauth_request_code: this.string('').nullable(),
      oauth_request_id: this.string('').nullable(),
      oauth_request_token: this.string('').nullable(),
      permissions: this.belongsToMany(
        Permission,
        RolePermission,
        'role_id',
        'permission_id',
      ),
      phone: this.string('').nullable(),
      postal_code: this.string('').nullable(),
      posts_count: this.attr(0),
      pretty_name: this.string('').nullable(),
      promoter_score_targets: this.morphMany(
        PromoterScoreTarget,
        'targetable_id',
        'targetable_type',
      ),
      replies: this.hasMany(Reply, 'user_id'),
      role: this.hasOne(Role, 'id', 'role_id'),
      role_id: this.attr(null),
      schedules: this.hasManyThrough(
        Schedule,
        Target,
        'target_id',
        'schedule_id',
      ),
      source: this.attr(''),
      state: this.string('').nullable(),
      status: this.string(''),
      street_address1: this.string('').nullable(),
      street_address2: this.string('').nullable(),
      survey_targets: this.morphMany(
        SurveyTarget,
        'targetable_id',
        'targetable_type',
      ),
      survey_user_answers: this.hasMany(SurveyUserAnswer, 'user_id'),
      targets: this.morphMany(Target, 'target_id', 'target_type'),
      thirty_day_health_score_change: this.attr(null).nullable(),
      type: this.attr('User'),
      unsub: this.boolean(false),
      user_type: this.attr(''),
      verification_sent: this.attr('').nullable(),
      viewable_groups: this.belongsToMany(
        Group,
        ViewableUser,
        'user_id',
        'group_id',
      ),
    };
  }

  accountTabs = () => {
    return [];
  };

  avatarInitials = () => {
    if (this.first_name && this.last_name) {
      return `${this.first_name.charAt(0)}${this.last_name.charAt(0)}`;
    }

    if (this.first_name && !this.last_name) {
      return `${this.first_name.charAt(0)}`;
    }

    if (!this.first_name && this.last_name) {
      return `${this.last_name.charAt(0)}`;
    }

    return `${this.email.charAt(0)}`;
  };

  canSendToGroup = group => {
    const sendableGroups = this.mailable_groups.filter(
      mailableGroup => mailableGroup.id === group.id,
    );

    return sendableGroups.length > 0;
  };

  canViewGroup = group => {
    const viewableGroups = this.viewable_groups.filter(
      viewableGroup => viewableGroup.id === group.id,
    );

    return viewableGroups.length > 0;
  };

  commentsNavigationRoute = () => {
    return {
      name: 'UserFeedback',
      params: {
        id: this.id,
      },
    };
  };

  concatenatedComments = date => {
    let allComments = '';

    this.promoter_score_targets
      .filter(target => {
        return (
          dayjs(target.created_at).utc().format('YYYY-MM-DD') ===
          dayjs(date).utc().format('YYYY-MM-DD')
        );
      })
      .forEach(target => {
        allComments += target.comments
          .map(comment => `${comment.value}<br />`)
          .join('<br />');
      });

    return allComments;
  };

  isDeactivated = () => {
    return this.status === STATUS.USER.DEACTIVATED;
  };

  deactivatedBanner = () => {
    let message = 'This individual was deactivated';

    if (this.deactivated_at != null) {
      const date = dayjs(this.deactivated_at).utc().format('MMM D, YYYY');
      message += ` on ${date}`;
    }

    return message;
  };

  displayEngagement = () => {
    switch (this.engagement_color) {
      case 'red':
        return {
          dotClassName: 'dot-red',
          score: ((this.engagement_score / MAX_ENGAGEMENT_SCORE) * 100).toFixed(
            0,
          ),
          status: {
            label: 'Unengaged',
          },
        };
      case 'yellow':
        return {
          dotClassName: 'dot-yellow',
          score: ((this.engagement_score / MAX_ENGAGEMENT_SCORE) * 100).toFixed(
            0,
          ),
          status: {
            label: 'At Risk',
          },
        };
      case 'green':
        return {
          dotClassName: 'dot-green',
          score: ((this.engagement_score / MAX_ENGAGEMENT_SCORE) * 100).toFixed(
            0,
          ),
          status: {
            label: 'Engaged',
          },
        };
      default:
        return {
          dotClassName: '',
          score: 0,
          status: {
            label: '—',
          },
        };
    }
  };

  formattedDate = () => {
    return dayjs(this.created_at).utc().format('MMM DD, YYYY ･ h:mm A');
  };

  getHealthScore = () => {
    if (this.health_score_pct === 0 && !this.health_scores.length) {
      return null;
    }

    return this.health_score_pct;
  };

  getPostLastClick = post => {
    const filteredEvents = this.events.filter(
      event =>
        event.event_type === 'click' && event.event_target_id === post.id,
    );

    return filteredEvents[filteredEvents.length - 1];
  };

  getPostLastOpen = post => {
    const filteredEvents = this.events.filter(
      event =>
        event.event_type === 'initial_open' &&
        event.event_target_id === post.id,
    );

    return filteredEvents[filteredEvents.length - 1];
  };

  getPostMarkedRead = post => {
    const filteredEvents = this.events.filter(
      event =>
        event.event_type === 'mark_read' && event.event_target_id === post.id,
    );

    return filteredEvents[filteredEvents.length - 1];
  };

  getPostRating = post => {
    const filteredEvents = this.events.filter(
      event =>
        (event.event_type === 'smile_rating' ||
          event.event_type === 'star_rating' ||
          event.event_type === 'thumb_rating') &&
        event.event_target_id === post.id,
    );

    return filteredEvents[filteredEvents.length - 1];
  };

  getPromoterScoreColor = number => {
    switch (true) {
      case number >= 0 && number <= 6:
        return '#cb3c07';
      case number >= 7 && number <= 8:
        return '#d39c05';
      case number >= 9 && number <= 10:
        return '#59ae00';
      default:
        return '#1c2026';
    }
  };

  hasAccess = () => {
    return this.role.name.toLowerCase() !== 'viewer';
  };

  hasAdminAccess = () => {
    return (
      this.role.name.toLowerCase() === 'owner' ||
      this.role.name.toLowerCase() === 'admin'
    );
  };

  hasOwnerAccess = () => {
    return false;
  };

  healthScoreStatus = () => {
    const healthScore = this.getHealthScore();

    return new HealthScoreStatus(healthScore).get();
  };

  healthScoreTrend = () => {
    return new Trend(this.thirty_day_health_score_change).get();
  };

  healthScoreTrendPercentage = () => {
    return new Trend(this.thirty_day_health_score_change).getPercentage();
  };

  insightTabs = () => {
    return [];
  };

  isActive = () => {
    return this.status === STATUS.USER.ACTIVE;
  };

  isDeactivated = () => {
    return this.status === STATUS.USER.DEACTIVATED;
  };

  isPending = () => {
    return this.status === STATUS.USER.PENDING_INVITE;
  };

  isUnsubscribed = () => {
    return this.status === STATUS.USER.UNSUBSCRIBED;
  };

  viewer = () => {
    return this.role.name.toLowerCase() === 'viewer';
  };

  hasPermission = permissionName => {
    const permission = this.permissions.filter(
      perm => perm.permission_name === permissionName,
    );

    return permission.length ? permission[0].permitted : 0;
  };

  overallMood = () => {
    const values = this.checkin_targets.filter(target => target.mood !== null);

    return values.length
      ? this.checkin_targets.reduce((total, next) => total + next.mood, 0) /
          values.length
      : NaN;
  };

  overallConfidence = () => {
    const values = this.checkin_targets.filter(
      target => target.confidence !== null,
    );

    return values.length
      ? this.checkin_targets.reduce(
          (total, next) => total + next.confidence,
          0,
        ) / values.length
      : NaN;
  };

  overallWorkload = () => {
    const values = this.checkin_targets.filter(
      target => target.workload !== null,
    );

    return values.length
      ? this.checkin_targets.reduce((total, next) => total + next.workload, 0) /
          values.length
      : NaN;
  };

  overallCheckin = () => {
    return new Smiley({
      confidence: this.overallConfidence(),
      mood: this.overallMood(),
      type: 'average',
      workload: this.overallWorkload(),
    });
  };

  overallScore = () => {
    const values = this.promoter_score_targets.filter(
      target => target.score !== null,
    );
    const total = values.length
      ? (
          this.promoter_score_targets.reduce(
            (accumulator, next) => accumulator + next.score,
            0,
          ) / values.length
        ).toFixed(0)
      : 0;
    const hexCode = this.getPromoterScoreColor(total);

    return {
      count: +total,
      hexCode,
    };
  };

  postAccess = post => {
    if (this.role_id === 4) {
      return 'user-post';
    }

    if (this.role_id === 1 || this.role_id === 2) {
      switch (post.status) {
        case 'draft':
          return 'draft-post';
        case 'scheduled':
          return 'scheduled-post';
        case 'sending':
          return 'sending-post';
        default:
          return 'sent-post';
      }
    } else if (this.id === post.creator_id) {
      switch (post.status) {
        case 'draft':
          return 'draft-post';
        case 'scheduled':
          return 'scheduled-post';
        case 'sending':
          return 'sending-post';
        default:
          return 'sent-post';
      }
    }

    return 'user-post';
  };

  postCrumbItems = post => {
    if (this.role_id === 4) {
      return [];
    }

    if (this.role_id === 1 || this.role_id === 2) {
      return [
        {
          disabled: false,
          href: '/posts',
          text: 'Broadcasts',
        },
        {
          disabled: false,
          href: '/posts/stats',
          text: 'Statistics',
        },
        {
          disabled: true,
          href: '',
          text: post.subject,
        },
      ];
    }

    return [
      {
        disabled: false,
        href: '/posts',
        text: 'Broadcasts',
      },
      {
        disabled: true,
        href: '',
        text: post.subject,
      },
    ];
  };

  tabs = id => {
    return [
      {
        name: 'Profile',
        params: id,
        url: 'UserEdit',
      },
      {
        name: 'Health Score',
        params: id,
        url: 'UserHealthScore',
      },
      {
        name: 'Comments',
        params: id,
        url: 'UserFeedback',
      },
    ];
  };

  username = () => {
    let username = String.fromCharCode(8212);
    const isDeactivated = this.isDeactivated();

    switch (true) {
      case this.first_name && !this.last_name:
        username = this.first_name;
        break;
      case !this.first_name && this.last_name:
        username = this.last_name;
        break;
      default:
        username = `${this.first_name} ${this.last_name}`;
    }

    if (isDeactivated) {
      username += ' (Deactivated)';
    }

    return username;
  };

  verificationDate = () => {
    return dayjs(this.verification_sent).utc().format('MM/DD/YYYY');
  };
}

export default User;
