import { Model } from '@vuex-orm/core';

export default class GroupManager extends Model {
  static get entity() {
    return 'GroupManager';
  }

  static primaryKey = ['group_id', 'manager_id'];

  static fields() {
    return {
      group_id: this.attr(null),
      manager_id: this.attr(null),
    };
  }
};
