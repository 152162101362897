import { Model } from '@vuex-orm/core';

export class StripeInfo extends Model {
  static get entity() {
    return 'stripe';
  }

  static fields() {
    return {
      invoices: this.attr(null),
      payment_intent: this.attr(null),
      prorate: this.attr(null),
      stripe_customer_info: this.attr(null),
    };
  }
}

export default StripeInfo;
