import { User } from '~/models/userHierarchy';

export class CurrentUser extends User {
  static get entity() {
    return 'CurrentUser';
  }

  static baseEntity = 'User';

  static fields() {
    return {
      ...super.fields(),
    };
  }
}

export default CurrentUser;