import { Model } from '@vuex-orm/core';
import { Checkin, Clicked, Delivered, InitialOpened, MarkRead, Opened, PromoterScore, Rated, ReminderSent } from './eventHierarchy';

export class Event extends Model {
  static get entity() {
    return 'event';
  }

  static types() {
    return {
      Checkin: Checkin,
      Click: Clicked,
      Delivery: Delivered,
      InitialOpen: InitialOpened,
      MarkRead: MarkRead,
      Open: Opened,
      PromoterScore: PromoterScore,
      Rating: Rated,
      ReminderSent: ReminderSent,
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      event_type: this.string(''),
      user_id: this.attr(null),
      event_target_id: this.attr(null),
      event_target_type: this.string(''),
      type: this.string('Event'),
      created_at: this.string(''),
      updated_at: this.string(''),
      event_data: this.attr(null).nullable(),
    };
  }

  description = fromInfo => {
    const from = fromInfo ? fromInfo : this.event_data.raw_rcpt_to;
    return `${from} has ${this.type.toLowerCase()} an email.`;
  }

  icon = () => {
    return 'mdi-star';
  }

  title = () => {
    return 'Event';
  }
};

export default Event;
