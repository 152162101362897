import Status from '~/models/healthScore/status/status';

export class HealthScoreStatus extends Status {
  constructor(healthScore) {
    super(healthScore);

    /* eslint-disable */
    this.labels = {
      low: 'Needs Work',
      medium: 'Good',
      high: 'Great',
    };
    /* eslint-enable */
  }
}

export default HealthScoreStatus;
