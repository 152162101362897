import { Group } from '~/models/groupHierarchy';
import store from '~/store';

function bulkUpdateGroups(payload) {
  const { ids, name } = payload;

  return Group.api().post('/api/groups/bulk_update_groups', {
    group_ids: ids,
    name,
  });
}

function createGroup(payload) {
  const { accountId, adminIds, insight, name, parentId, userIds } = payload;

  return Group.api().post('/api/groups', {
    account_id: accountId,
    admin_ids: adminIds,
    insight,
    name,
    parent_id: parentId,
    user_ids: userIds,
  });
}

function createManagers(payload) {
  const { id, userIds } = payload;

  return Group.api().post('/api/groups/create_managers', {
    id,
    user_ids: userIds,
  });
}

function deleteGroups(payload) {
  const { groupIds } = payload;

  return Group.api().post('/api/groups/bulk_delete_groups', {
    group_ids: groupIds,
  });
}

function getAllGroups() {
  return Group.api().get('/api/groups');
}

function getGroupWithHealthScore(id) {
  return Group.api().get(`/api/groups/${id}/health_score`);
}

function getGroupById(id) {
  return Group.api().get(`/api/groups/${id}`);
}

function getGroupsByIds(payload) {
  return Group.api().get('/api/groups', {
    params: {
      group_ids: payload,
    },
  });
}

async function getGroupsWithCheckins(payload) {
  const { limit } = payload;
  const result = await Group.api()
    .get('/api/groups/checkins', {
      params: {
        limit: limit || 30,
        order: 'name,asc',
      },
    })
    .then(response => {
      const {
        response: { headers },
      } = response;
      const data = {
        baseUrl: '/api/groups/checkins',
        context: Group,
        page: 'insight-checkin-index',
        perPage: +headers['x-per-page'],
        total: +headers['x-total'],
      };

      store.dispatch('pagination/setHeaders', data);
    });

  return result;
}

function getGroupsWithHealthScores() {
  return Group.api().get('/api/groups/health_scores');
}

async function getGroupsWithPromoterScores(payload) {
  const { limit } = payload;
  const result = await Group.api()
    .get('/api/groups/promoter_scores', {
      params: {
        limit: limit || 30,
        order: 'name,asc',
      },
    })
    .then(response => {
      const {
        response: { headers },
      } = response;
      const data = {
        baseUrl: '/api/groups/promoter_scores',
        context: Group,
        page: 'insight-promoter-score-index',
        perPage: +headers['x-per-page'],
        total: +headers['x-total'],
      };

      store.dispatch('pagination/setHeaders', data);
    });

  return result;
}

function getMailableGroups() {
  return Group.api().get('/api/groups?mailable=true');
}

function getPermissions(payload) {
  const { contactId } = payload;

  return Group.api().get(`/api/groups/${contactId}/groups_permissions`);
}

function getViewableGroups() {
  return Group.api().get('/api/groups?viewable=true');
}

function removeManagers(payload) {
  const { id, userIds } = payload;

  return Group.api().post('/api/groups/remove_managers', {
    id,
    user_ids: userIds,
  });
}

function updateGroup(payload) {
  const { id, insight, name } = payload;

  return Group.api().put(`/api/groups/${id}`, {
    insight: insight || false,
    name,
  });
}

function updateGroupInfo(payload, id) {
  const { adminIds, insight, name, userIds } = payload;

  return Group.api().post(`/api/groups/${id}/update_group`, {
    admin_ids: adminIds,
    insight,
    name,
    user_ids: userIds,
  });
}

export const groupService = {
  bulkUpdateGroups,
  createGroup,
  createManagers,
  deleteGroups,
  getAllGroups,
  getGroupById,
  getGroupWithHealthScore,
  getGroupsByIds,
  getGroupsWithCheckins,
  getGroupsWithHealthScores,
  getGroupsWithPromoterScores,
  getMailableGroups,
  getPermissions,
  getViewableGroups,
  removeManagers,
  updateGroup,
  updateGroupInfo,
};
