import { FEATURES } from '~/helpers/constants';

const IntegrationIndex = () => import('~/views/integrations/index/Index');
const PocketShow = () => import('~/views/integrations/pocket/show/Show');
const RssShow = () => import('~/views/integrations/rss/show/Show');
const SlackShow = () => import('~/views/integrations/slack/Show');
const SlackSso = () => import('~/views/integrations/slack/OAuth');
const GsuiteShow = () => import('~/views/integrations/gsuite/Show');
const OfficeShow = () => import('~/views/integrations/office/Show');

export const integration = [
  {
    component: IntegrationIndex,
    meta: {
      adminRestricted: true,
      featureName: FEATURES.INTEGRATIONS,
      permissionName: 'create_send_broadcast',
      requiresAuth: true,
    },
    name: 'IntegrationIndex',
    path: '/integrations',
  },
  {
    component: PocketShow,
    meta: {
      adminRestricted: true,
      permissionName: 'create_send_broadcast',
      requiresAuth: true,
    },
    name: 'PocketShow',
    path: '/integrations/pocket',
  },
  {
    component: RssShow,
    meta: {
      adminRestricted: true,
      permissionName: 'create_send_broadcast',
      requiresAuth: true,
    },
    name: 'RssShow',
    path: '/integrations/rss',
  },
  {
    component: SlackShow,
    meta: {
      adminRestricted: true,
      permissionName: 'edit_account_setting',
      requiresAuth: true,
    },
    name: 'SlackShow',
    path: '/integrations/slack',
  },
  {
    component: SlackSso,
    meta: {
      adminRestricted: true,
      permissionName: 'edit_account_setting',
      requiresAuth: true,
    },
    name: 'SlackSso',
    path: '/integrations/slack/sso',
  },
  {
    component: GsuiteShow,
    meta: {
      adminRestricted: true,
      featureName: FEATURES.INTEGRATIONS,
      permissionName: 'edit_account_setting',
      requiresAuth: true,
    },
    name: 'GsuiteShow',
    path: '/integrations/gsuite',
  },
  {
    component: OfficeShow,
    meta: {
      adminRestricted: true,
      permission_name: 'edit_account_setting',
      requiresAuth: true,
    },
    name: 'OfficeShow',
    path: '/integrations/office',
  },
];
