const TemplateEdit = () => import('~/views/templates/edit/Edit');
const TemplateIndex = () => import('~/views/templates/index/Index');

export const template = [
  {
    component: TemplateEdit,
    meta: {
      adminRestricted: true,
      requiresAuth: true,
    },
    name: 'TemplateEdit',
    path: '/templates/:id/edit',
  },
  {
    component: TemplateIndex,
    meta: {
      adminRestricted: true,
      requiresAuth: true,
    },
    name: 'TemplateIndex',
    path: '/templates',
  },
];
