import Card from '~/models/card';
import Invoice from '~/models/invoice';
import StripeInfo from '~/models/stripe';

function changeSubscription(payload) {
  return StripeInfo.api().post('/api/stripe/subscription/change', {
    payload,
  });
}

function getCustomerInfo() {
  return StripeInfo.api().get('/api/stripe/customer_info');
}

function getDefaultSource(cardId, sourceType) {
  return Card.api().get(`api/stripe/${cardId}/${sourceType}`);
}

function getInvoices() {
  return StripeInfo.api().get('/api/stripe/billing_history');
}

function getUpcomingInvoice() {
  return Invoice.api().get('/api/stripe/upcoming_invoice');
}

function proratePreview(payload) {
  return StripeInfo.api().post('/api/stripe/prorate/preview', {
    payload,
  });
}

function updatePaymentInfo(payload) {
  return StripeInfo.api().post('/api/stripe/payments', {
    payload,
  });
}

export const stripeService = {
  changeSubscription,
  getCustomerInfo,
  getDefaultSource,
  getInvoices,
  getUpcomingInvoice,
  proratePreview,
  updatePaymentInfo,
};
