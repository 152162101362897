const CheckinShow = () => import('~/views/checkins/Show');

export const checkin = [
  {
    meta: {
      adminRestricted: true,
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    path: '/checkins',
    redirect: { name: 'InsightCheckinIndex' },
  },
  {
    component: CheckinShow,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      requiresAuth: true,
    },
    name: 'CheckinShow',
    path: '/checkins/:id',
  },
];
