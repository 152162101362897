import { setContext } from 'apollo-link-context';

const authLink = setContext((_, { headers }) => {
  const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .attributes
    .content
    .value;

  return {
    headers: {
      ...headers,
      'X-CSRF-Token': csrfToken,
    },
  };
});

export default authLink;
