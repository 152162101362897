<template>
  <v-app>
    <template v-if="isTopNavigationVisible">
      <mobile-drawer :is-logged-in="isLoggedIn" />
      <top-nav :is-logged-in="isLoggedIn" />
    </template>

    <navigation-drawer
      v-if="isNavigationDrawerVisible"
      :is-logged-in="isLoggedIn" />

    <v-content>
      <transition
        mode="out-in"
        name="fade">
        <router-view :key="$route.fullPath" />
      </transition>
    </v-content>

    <v-snackbar
      v-if="alert.message"
      bottom
      :color="alert.type"
      left
      :timeout="5000"
      :value="alert.message"
      @input="alertClear">
      <div class="snack-left">
        <v-icon
          color="white"
          left
          size="20">
          {{ alert.icon }}
        </v-icon>
        <div v-html="alert.message" />
      </div>
      <v-btn
        :ripple="false"
        text
        @click="alertClear">
        &times;
      </v-btn>
    </v-snackbar>

    <account-upgrade
      v-model="modals.accountUpgrade"
      :upgrade-details="accountUpgradeDetails" />
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AccountUpgrade from '~/components/modals/AccountUpgrade';
import { contactService } from '~/services';
import accountUpgradeHashMap from '~/mixins/accountUpgradeHashMap';
import currentUser from '~/mixins/currentUser';
import { EventBus } from '~/helpers/eventBus';
import MobileDrawer from '~/components/MobileDrawer';
import NavigationDrawer from '~/components/NavigationDrawer';
import TopNav from '~/components/TopNav';
import { FacebookEventListener} from "~/events/facebookEventListener";

export default {
  components: {
    AccountUpgrade,
    MobileDrawer,
    NavigationDrawer,
    TopNav,
  },
  mixins: [
    accountUpgradeHashMap,
    currentUser,
  ],
  data: () => ({
    accountUpgradeDetails: {},
    hasSubscriptionChannel: false,
    isLoggedIn: false,
    modals: {
      accountUpgrade: false,
    },
  }),
  computed: {
    ...mapState({
      alert: state => state.alert,
    }),
    isNavigationDrawerVisible() {
      return !(
        this.$route.meta.hasOwnProperty('isNavigationDrawerVisible') &&
        !this.$route.meta.isNavigationDrawerVisible
      );
    },
    isTopNavigationVisible() {
      return !(
        this.$route.meta.hasOwnProperty('isTopNavigationVisible') &&
        !this.$route.meta.isTopNavigationVisible
      );
    },
  },
  watch: {
    $route() {
      if (
        (
          !this.$route.meta.hasOwnProperty('isPublic') ||
          !this.$route.meta.isPublic
        ) && !this.currentUser
      ) {
        this.setLoggedIn();
      }
    },
    currentUser() {
      if (this.$route.meta.adminRestricted) {
        if (this.currentUser && !this.hasAccess) {
          this.$router.go(-1);
        }
      }

      if (this.currentUser) {
        this.hasSubscriptionChannel = true;
      }
    },
    hasSubscriptionChannel() {
      if (this.hasSubscriptionChannel) {
        this.$cable.subscribe({ channel: 'SubscriptionChannel' });
      }
    },
  },
  beforeDestroy() {
    EventBus.$off('account-upgrade-required');
    EventBus.$off('reset-account-upgrade-modal');
  },
  mounted() {
    EventBus.$on('account-upgrade-required', featureName => {
      this.accountUpgradeDetails = this.featureDetails[featureName];
      this.modals.accountUpgrade = true;
    });

    EventBus.$on('reset-account-upgrade-modal', () => {
      this.accountUpgradeDetails = {};
    });
  },
  channels: {
    SubscriptionChannel: {
      received(data) {
        new FacebookEventListener().publish(data);
      },
    },
  },
  methods: {
    ...mapActions({
      alertClear: 'alert/clear',
      alertError: 'alert/error',
    }),
    async setLoggedIn() {
      await contactService.getCurrentUser()
        .then(res => {
          const user = res.response.data;

          if (user.id) {
            this.isLoggedIn = true;
          }
        })
        .catch(() => {
          this.isLoggedIn = false;
          this.alertError('There was an error logging you in. Please try again.');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~/sass/variables';

  .theme--light.v-application {
    color: $dark-grey;
  }

  .v-application {
    font-family: Avenir, 'Avenir Next', 'Lato', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .container {
    height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .snack-left {
    align-items: center;
    display: flex;
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 0.05s ease;
  }

  .fade-leave-active {
    opacity: 0;
    transition: opacity 0.05s ease;
  }

  .__cov-progress {
    transition: all 0.3s ease-in-out;
  }
</style>
