import { Model } from '@vuex-orm/core';

export default class Role extends Model {
  static get entity() {
    return 'Role';
  }

  static fields() {
    return {
      account_id: this.attr(null),
      id: this.attr(null),
      name: this.string(''),
    };
  }
}
