import Event from '~/models/event';

function getAllEvents() {
  return Event.api().get('/api/events');
}

function getEventsForPost(id) {
  return Event.api().get(`/api/events?filter[event_target_id]=${id}&filter[event_target_type]=Post`);
}

function getEventsForUser(id) {
  return Event.api().get(`/api/events?filter[user_id]=${id}`);
}

export const eventService = {
  getAllEvents,
  getEventsForPost,
  getEventsForUser,
};
