import { Model } from '@vuex-orm/core';
import { User } from '~/models/userHierarchy';
import CheckinQuestion from '~/models/checkinQuestion';

export class CheckinUserAnswer extends Model {
  static get entity() {
    return 'CheckinUserAnswer';
  }

  static fields() {
    return {
      id: this.attr(null),
      checkin_id: this.attr(null),
      checkin_question_id: this.attr(null),
      user_id: this.attr(null),
      value: this.attr(''),

      checkin_question: this.belongsTo(CheckinQuestion, 'checkin_question_id'),
      user: this.belongsTo(User, 'user_id'),
    };
  }
}

export default CheckinUserAnswer;
