import { Subscription } from '~/models/subscription';
import { subscriptionService } from '../services';

export class FacebookEventListener {
  publish = ({ subscription }) => {
    if (subscription) {
      if (
        !subscription.fb_notified &&
        subscription.current_status === 'active' &&
        subscription.previous_status === 'trialing'
      ) {
        Subscription.update({
          data: {
            fb_notified: true,
          },
          where: subscription.id,
        }).then(() => {
          subscriptionService.updateFBNotifiedStatus({
            fb_notified: true,
            id: subscription.id,
          });
        });

        if (typeof fbq !== 'undefined') {
          if (subscription.amount && subscription.currency) {
            window.fbq('track', 'Purchase', {
              currency: subscription.currency.toUpperCase(),
              value: parseFloat(subscription.amount).toFixed(2),
            });
          }
        }
      }
    }
  };

  ccInfoAdded = () => {
    if (typeof fbq !== 'undefined') {
      window.fbq('track', 'AddPaymentInfo');
    }
  };

}

export default FacebookEventListener;
