import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Post } from '~/models/postHierarchy';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export class Sent extends Post {
  static get entity() {
    return 'Sent';
  }

  static baseEntity() {
    return 'Post';
  }

  static fields() {
    return {
      ...super.fields(),
    };
  }

  actions = () => {
    return {
      button: {
        hasRoute: true,
        isOutlined: true,
        params: {
          id: this.id,
        },
        route: 'PostStats',
        text: 'Statistics',
      },
    };
  };

  details = () => {
    return {
      hasIcon: false,
      html: `
      <span>
        <p class="mb-0">${this.openPercentage()}%</p>
        <p class="caption">OPENED</p>
      </span>
      <span>
        <p class="mb-0">${this.clickPercentage()}%</p>
        <p class="caption">CLICKED</p>
      </span>
      `,
      icon: '',
    };
  };

  subhead = () => {
    const count = this.number_sent;

    return {
      action: {
        hasLink: true,
        link: {
          params: {
            id: this.id,
          },
          route: 'PostStats',
          text: `Sent ${
            this.sent_at ? dayjs(this.sent_at).utc().fromNow() : ''
          }`,
        },
        text: `to ${count} contact${count !== 1 ? 's' : ''}`,
      },
      details: {
        hasLink: true,
        link: {
          params: {
            id: this.id,
          },
          route: 'PostShow',
          text: 'View Online',
        },
        text: '',
      },
    };
  };
}

export default Sent;
