import { Model } from '@vuex-orm/core';

export default class Card extends Model {
  static get entity() {
    return 'Card';
  }

  static fields() {
    return {
      brand: this.string(''),
      customer: this.string(''),
      exp_month: this.attr(null),
      exp_year: this.attr(null),
      funding: this.string(''),
      id: this.attr(null),
      last4: this.number(0),
    };
  }
}
