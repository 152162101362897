import { Model } from '@vuex-orm/core';
import SurveyUserAnswer from '~/models/surveyUserAnswer';

export class SurveyQuestionOption extends Model {
  static get entity() {
    return 'SurveyQuestionOption';
  }

  static fields() {
    return {
      id: this.attr(null),
      option: this.attr(''),
      question_type: this.attr(''),
      survey_question_id: this.attr(''),

      survey_user_answers: this.hasMany(SurveyUserAnswer, 'option_id'),
    };
  }
}

export default SurveyQuestionOption;
