import { Post } from '~/models/postHierarchy';
import { Survey } from '~/models/surveyHierarchy';
import SurveyQuestionOption from '~/models/surveyQuestionOption';
import SurveyTemplate from '~/models/surveyTemplate';
import SurveyUserAnswer from '~/models/surveyUserAnswer';
import SurveyTarget from '~/models/surveyTarget';

function addAnswer(payload) {
  const { id, option } = payload;

  return Survey.api().post(`/api/survey_questions/${id}/create_option`, {
    option,
  });
}

function addQuestion(payload) {
  const { id, order, questionType } = payload;

  return Survey.api().post(`/api/insights/surveys/${id}/create_question`, {
    order,
    question_type: questionType,
  });
}

function addToBroadcast(id) {
  return Post.api().post(`/api/surveys/${id}/add_to_broadcast`);
}

function cancelSurvey(payload) {
  const { id } = payload;

  return Survey.api().delete(`/api/schedules/surveys/${id}`);
}

function complete(payload) {
  const { questions, surveyId } = payload;

  return Survey.api().post('/api/insights/surveys/complete', {
    id: surveyId,
    questions,
  });
}

function createSurvey(payload) {
  const { name } = payload;

  return Survey.api().post('/api/insights/surveys', {
    name,
  });
}

function deleteSurvey(payload) {
  const { id } = payload;

  return Survey.api().delete(`/api/insights/surveys/${id}`);
}

function duplicateQuestion(payload) {
  const { id } = payload;

  return Survey.api().post(`/api/survey_questions/${id}/duplicate`);
}

function duplicateSurvey(payload) {
  const { id } = payload;

  return Survey.api().post(`/api/insights/surveys/${id}/duplicate`);
}

function duplicateSurveyTemplate(payload) {
  const { id } = payload;

  return Survey.api().post(`/api/insights/surveys/${id}/duplicate_template`);
}

function getAllSurveys() {
  return Survey.api().get('/api/insights/surveys');
}

function getAllTemplates() {
  return SurveyTemplate.api().get('/api/insights/surveys/templates');
}

function getById(payload) {
  const { id } = payload;

  return Survey.api().get(`/api/insights/surveys/${id}`);
}

function getDraftedSurveys() {
  return Survey.api().get('/api/insights/surveys?filter[status]=draft');
}

function getOptionUserAnswers(payload) {
  const { id } = payload;

  return SurveyQuestionOption.api().get(`/api/survey_question_options/${id}`);
}

function getSentSurveys() {
  return Survey.api().get('/api/insights/surveys?filter[status]=sent');
}

function getSurveyTargetsById(payload) {
  const { id } = payload;

  return SurveyTarget.api().get(`/api/insights/surveys/${id}/survey_targets`);
}

function getTemplateById(id) {
  return SurveyTemplate.api().get(`/api/insights/surveys/templates/${id}`);
}

function getUserAnswersById(payload) {
  const { id } = payload;

  return SurveyUserAnswer.api().get(`/api/insights/surveys/${id}`);
}

function removeAnswer(id) {
  return Survey.api().delete(`/api/survey_question_options/${id}/destroy`);
}

function removeQuestion(id) {
  return Survey.api().delete(`/api/survey_questions/${id}/destroy`);
}

function updateAnswer(payload) {
  const { id, option } = payload;

  return Survey.api().put(`/api/survey_question_options/${id}/update`, {
    survey_question_option: {
      option,
    },
  });
}

function updateLinearScale(payload) {
  const { id, left, right } = payload;

  return Survey.api().post(`/api/survey_questions/${id}/update_linear_scale`, {
    left,
    right,
  });
}

function updateOrder(payload) {
  const { id, surveyQuestions } = payload;

  return Survey.api().put(`/api/insights/surveys/${id}/update_order`, {
    survey_questions: surveyQuestions,
  });
}

function updateQuestion(payload) {
  const { id, ...args } = payload;

  return Survey.api().put(`/api/survey_questions/${id}/update`, {
    survey_question: args,
  });
}

function updateSurvey(payload) {
  return Survey.api().put(`/api/insights/surveys/${payload.id}`, {
    survey: payload,
  });
}

export const surveyService = {
  addAnswer,
  addQuestion,
  addToBroadcast,
  cancelSurvey,
  complete,
  createSurvey,
  deleteSurvey,
  duplicateQuestion,
  duplicateSurvey,
  duplicateSurveyTemplate,
  getAllSurveys,
  getAllTemplates,
  getById,
  getDraftedSurveys,
  getOptionUserAnswers,
  getSentSurveys,
  getSurveyTargetsById,
  getTemplateById,
  getUserAnswersById,
  removeAnswer,
  removeQuestion,
  updateAnswer,
  updateLinearScale,
  updateOrder,
  updateQuestion,
  updateSurvey,
};
