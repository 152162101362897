import Checkin from '~/models/checkin';

function addToBroadcast(payload) {
  const { draft } = payload;

  return Checkin.api().post(`/api/checkins/add_to_broadcast`, {
    draft,
  });
}

function answerQuestion(payload) {
  const { checkinId, questionId, value } = payload;

  return Checkin.api().post('/api/checkins/answer_question', {
    checkin_id: checkinId,
    question_id: questionId,
    value,
  });
}

function complete(payload) {
  const { checkinId, questions } = payload;

  return Checkin.api().post('/api/checkins/complete', {
    id: checkinId,
    questions,
  });
}

function getById(payload) {
  const { id } = payload;

  return Checkin.api().get(`/api/checkins/${id}`);
}

function getGroupSentCheckins(groupId) {
  return Checkin.api().get(`/api/groups/${groupId}/checkins`);
}

function getSentCheckins() {
  return Checkin.api().get('/api/checkins');
}

export const checkinService = {
  addToBroadcast,
  answerQuestion,
  complete,
  getById,
  getGroupSentCheckins,
  getSentCheckins,
};
