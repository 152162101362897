import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Post } from '~/models/postHierarchy';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export class Scheduled extends Post {
  static get entity() {
    return 'Scheduled';
  }

  static baseEntity() {
    return 'Post';
  }

  static fields() {
    return {
      ...super.fields(),
    };
  }

  actions = () => {
    return {
      button: {
        hasRoute: false,
        isOutlined: true,
        params: {},
        route: '',
        text: 'Cancel',
      },
    };
  };

  details = () => {
    return {
      hasIcon: true,
      html: `
      <time
        class="mb-0"
        datetime="${this.schedule.scheduledTime()}">
        ${this.schedule.scheduledTime()}
      </time>
      `,
      icon: 'mdi-calendar-check-outline',
    };
  };

  subhead = () => {
    const count = this.total_targets;

    return {
      action: {
        hasLink: false,
        link: {},
        text: `Edited ${
          this.updated_at ? dayjs(this.updated_at).utc().fromNow() : ''
        }`,
      },
      details: {
        hasLink: false,
        link: {},
        text: `Will be sent to ${count} contact${count !== 1 ? 's' : ''}`,
      },
    };
  };
}

export default Scheduled;
