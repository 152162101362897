import axios from 'axios';
import { Post } from '~/models/postHierarchy';

function cancelSchedule(payload) {
  const { id } = payload;

  return Post.api().delete(`/api/schedules/${id}`);
}

function getChannels() {
  return axios({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    url: `/api/integrations/slacks/channels`,
  })
    .then(response => response)
    .catch(error => error);
}

function getSchedule(payload) {
  const { id } = payload;

  return axios({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    url: `/api/posts/${id}/schedule`,
  })
    .then(response => response)
    .catch(error => error);
}

function getScheduleForPost(payload) {
  const { id } = payload;

  return axios({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    url: `/api/schedules/post/${id}/`,
  })
    .then(response => response)
    .catch(error => error);
}

function sendTestEmail(payload) {
  const { authenticityToken, data, id } = payload;

  return axios({
    data: {
      ...data,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'POST',
    url: `/api/posts/${id}/schedule_test`,
  })
    .then(response => response)
    .catch(error => error);
}

function submitNewSchedule(payload) {
  const { channel, schedule, send_email, send_slack, status } = payload;

  return Post.api().post(`/api/posts/${schedule.post_id}/schedule`, {
    channel,
    id: schedule.post_id,
    schedule,
    send_email,
    send_slack,
    status,
  });
}

export const scheduleService = {
  cancelSchedule,
  getChannels,
  getSchedule,
  getScheduleForPost,
  sendTestEmail,
  submitNewSchedule,
};
