import { Model } from '@vuex-orm/core';
import SurveyQuestion from '~/models/surveyQuestion';

export class SurveyTemplate extends Model {
  static get entity() {
    return 'SurveyTemplate';
  }

  static fields() {
    return {
      id: this.attr(null),
      name: this.string('').nullable(),
      creator_id: this.attr(null),
      created_at: this.string(''),
      description: this.string('').nullable(),
      questions_count: this.attr(null),
      updated_at: this.string(''),
      suggestion: this.string('').nullable(),
      survey_type: this.string(''),
      thumbnail_url: this.string(''),

      survey_questions: this.hasMany(SurveyQuestion, 'survey_id'),
    };
  }
}

export default SurveyTemplate;
