import { Model } from '@vuex-orm/core';

export class PromoterScoreStat extends Model {
  static get entity() {
    return 'PromoterScoreStat';
  }

  static fields() {
    return {
      date: this.string(''),
      detractor: this.number(0),
      id: this.attr(null),
      passive: this.number(0),
      promoter: this.number(0),
      score: this.number(0),
    };
  }
}

export default PromoterScoreStat;
