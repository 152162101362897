import { itemService, postService } from '~/services';

const post = {
  actions: {
    createItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        itemService
          .createItem(payload)
          .then(response => {
            commit('postSuccess', response);
            resolve(response);
          })
          .catch(error => {
            commit('postError', error);
            reject(error);
          });
      });
    },
    getPost({ commit }, payload) {
      postService
        .getPost(payload)
        .then(response => {
          commit('postSuccess', response);
          commit('loadingComplete');
        })
        .catch(error => {
          commit('postError', error);
        });
    },
    removeItem({ commit }, payload) {
      itemService
        .removeItem(payload)
        .then(response => {
          commit('postSuccess', response);
        })
        .catch(error => {
          commit('postError: ', error);
        });
    },
    updateItem({ commit }, payload) {
      itemService
        .updateItem(payload)
        .then(response => {
          commit('updateItem', response);
        })
        .catch(error => {
          commit('itemError', error);
        });
    },
    updatePost({ commit }, payload) {
      return new Promise((resolve, reject) => {
        postService
          .updatePost(payload)
          .then(response => {
            commit('postSuccess', response);
            commit('loadingComplete');
            resolve(response);
          })
          .catch(error => {
            commit('postError', error);
            reject(error);
          });
      });
    },
  },

  getters: {},

  mutations: {
    addItem(state, payload) {
      if (typeof payload.order !== 'undefined') {
        state.post.items.splice(payload.order, 0, payload);
      } else {
        state.post.items.push(payload);
      }
    },
    deleteItem(state, payload) {
      state.post.items.forEach((item, index) => {
        if (item === payload && !('id' in item))
          state.post.items.splice(index, 1);
      });
    },
    itemError(state, payload) {
      let foundTask = state.post.items.find(item => item.id === payload.id);
      foundTask = payload;
      return foundTask;
    },
    loadingComplete(state) {
      state.loading = false;
    },
    postError(state, payload) {
      state.error = payload;
    },
    postSuccess(state, payload) {
      state.post = payload;
    },
    updateItem(state, payload) {
      let foundTask = state.post.items.find(item => item.id === payload.id);
      foundTask = payload;
      return foundTask;
    },
    updateItemOrder(state, payload) {
      state.post.items = payload;
    },
  },

  namespaced: true,

  state: {
    error: {},
    loading: true,
    post: {},
  },
};

export default post;
