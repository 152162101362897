const NotFound = () => import('~/views/errors/NotFound');
const ThanksShow = () => import('~/views/thanks/Show');

export const base = [
  {
    component: ThanksShow,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      requiresAuth: true,
    },
    name: 'ThanksShow',
    path: '/thanks',
  },
  {
    component: NotFound,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      requiresAuth: false,
    },
    name: 'NotFound',
    path: '*',
  },
];
