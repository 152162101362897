import axios from 'axios';

function createItem(payload) {
  const { authenticityToken, data, type } = payload;

  return axios({
    data: { ...data },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'POST',
    url: `/api/${type}s`,
  })
    .then(response => response.data)
    .catch(() => {
      throw new Error('There was an error creating a new item.');
    });
}

function removeItem(payload) {
  const { authenticityToken, data, id, type } = payload;

  return axios({
    data: { ...data },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'DELETE',
    url: `/api/${type}s/${id}`,
  })
    .then(response => response.data)
    .catch(() => {
      throw new Error('There was an error removing an item.');
    });
}

function updateItem(payload) {
  const { authenticityToken, id, data, type } = payload;

  return axios({
    data: { ...data },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'PUT',
    url: `/api/${type}s/${id}`,
  })
    .then(response => response.data)
    .catch(() => {
      throw new Error('There was an error updating an item.');
    });
}

function updateSourceImage(payload) {
  const { authenticityToken, data, id } = payload;

  return axios({
    data: { ...data },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'PUT',
    url: `/api/sources/${id}/update_image`,
  })
    .then(response => response.data)
    .catch(() => {
      throw new Error('There was an error uploading the image.');
    });
}

function uploadImage(payload, authenticityToken) {
  return axios({
    data: { ...payload },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'POST',
    url: '/rails/active_storage/direct_uploads',
  })
    .then(response => response.data)
    .catch(() => {
      throw new Error('There was an error uploading an image.');
    });
}

export const itemService = {
  createItem,
  removeItem,
  updateItem,
  updateSourceImage,
  uploadImage,
};
