import axios from 'axios';
import Checkin from '~/models/checkin';
import { User } from '~/models/userHierarchy';
import PromoterScore from '~/models/promoterScore';

function deactivateUsers(payload) {
  const { userIds } = payload;

  return User.api().post('/api/user_managements/deactivate', {
    user_ids: userIds,
  });
}

function getAccountUsers() {
  return User.api().get('/api/user_managements/account_users');
}

function getAllUsers() {
  return User.api().get('/api/user_managements');
}

function getById(id) {
  return User.api().get(`/api/user_managements/${id}`);
}

function getUserWithHealthScore(id) {
  return User.api().get(`/api/user_managements/${id}/health_score`);
}

function inviteTeam(payload) {
  const { emails } = payload;

  return User.api().post('/api/user_managements/invite_team', {
    emails,
  });
}

function inviteUser(payload) {
  const {
    email,
    firstName,
    groupIds,
    lastName,
    newGroupNames,
    roleId,
  } = payload;

  return User.api().post('/api/user_managements', {
    email,
    first_name: firstName,
    group_ids: groupIds,
    last_name: lastName,
    new_group_names: newGroupNames,
    role_id: roleId,
  });
}

function batchInviteUser(payload) {
  return User.api().post('/api/user_managements/bulk_add_to_users', {
    payload,
  });
}

function addToGroups(payload) {
  const { group_ids, new_group_names, user_ids } = payload;

  return User.api().post('/api/user_managements/bulk_add_to_groups', {
    group_ids,
    new_group_names,
    user_ids,
  });
}

function removeFromGroups(payload) {
  const { groupIds, userIds } = payload;

  return User.api().post('/api/user_managements/bulk_delete_from_groups', {
    group_ids: groupIds,
    user_ids: userIds,
  });
}

function deleteUsers(payload) {
  const { userIds } = payload;

  return User.api().post('/api/user_managements/bulk_delete_users', {
    user_ids: userIds,
  });
}

function resubscribe(userIds) {
  return User.api().put('/api/user_managements/resub', {
    user_ids: userIds,
  });
}

function saveProfile(payload) {
  const { authenticityToken, data, id } = payload;

  return axios({
    data: {
      ...data,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'PUT',
    url: `/api/user_managements/${id}`,
  })
    .then(response => response.data)
    .catch(() => {
      throw new Error('There was an error saving your profile.');
    });
}

function sendVerification(userIds) {
  return User.api().put(`/api/user_managements/send_verification_email`, {
    user_ids: userIds,
  });
}

function update(payload) {
  const {
    admin_group_ids,
    comment_frequency,
    comment_status,
    email,
    first_name,
    group_ids,
    health_score_frequency,
    health_score_status,
    id,
    last_name,
    new_admin_group_names,
    new_group_names,
    type,
  } = payload;

  return User.api().put(`/api/user_managements/${id}`, {
    admin_group_ids,
    group_ids,
    id,
    new_admin_group_names,
    new_group_names,
    user: {
      comment_frequency,
      comment_status,
      email,
      first_name,
      health_score_frequency,
      health_score_status,
      last_name,
      type,
    },
  });
}

function updateAvatar(payload) {
  const { avatar, id } = payload;

  return User.api().put(`/api/user_managements/${id}/update_avatar`, {
    avatar,
  });
}

function updateBroadcastPermissions(payload) {
  const { groupId, id } = payload;

  return User.api().put(
    `/api/user_managements/${id}/update_broadcast_permissions`,
    {
      group_id: groupId,
    },
  );
}

function updatePassword(payload) {
  const { authenticityToken, data, id } = payload;

  return axios({
    data: { ...data },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'PUT',
    url: `/api/user_managements/${id}/update_password`,
  }).then(response => response);
}

function updateType(payload) {
  const { id, role_id } = payload;

  return User.api().put(`/api/user_managements/${id}/update_type`, {
    role_id,
  });
}

function updateViewPermissions(payload) {
  const { groupId, id } = payload;

  return User.api().put(`/api/user_managements/${id}/update_view_permissions`, {
    group_id: groupId,
  });
}

function getCheckinsByUser(id) {
  return Checkin.api().get(`/api/user_managements/${id}/checkins`);
}

function getPromoterScoresByUser(id) {
  return PromoterScore.api().get(`/api/user_managements/${id}/enps`);
}

export const userManagementService = {
  addToGroups,
  batchInviteUser,
  deactivateUsers,
  deleteUsers,
  getAccountUsers,
  getAllUsers,
  getById,
  getCheckinsByUser,
  getPromoterScoresByUser,
  getUserWithHealthScore,
  inviteTeam,
  inviteUser,
  removeFromGroups,
  resubscribe,
  saveProfile,
  sendVerification,
  update,
  updateAvatar,
  updateBroadcastPermissions,
  updatePassword,
  updateType,
  updateViewPermissions,
};
