import { Model } from '@vuex-orm/core';
import Comment from '~/models/comment';
import PromoterScore from '~/models/promoterScore';

export class PromoterScoreTarget extends Model {
  static get entity() {
    return 'PromoterScoreTarget';
  }

  static fields() {
    return {
      completed_count: this.number(0),
      created_at: this.attr(''),
      detractors: this.attr(null).nullable(),
      id: this.attr(null),
      passives: this.attr(null).nullable(),
      promoter_score_id: this.attr(null),
      promoters: this.attr(null).nullable(),
      score: this.attr(null).nullable(),
      targetable_id: this.attr(null),
      targetable_type: this.attr(null),
      total_targets: this.number(0),
      updated_at: this.attr(''),

      comments: this.morphMany(Comment, 'commentable_id', 'commentable_type'),
      promoter_score: this.belongsTo(PromoterScore, 'promoter_score_id'),
    };
  }
}

export default PromoterScoreTarget;
