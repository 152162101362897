import axios from 'axios';
import { contactService, groupService } from '~/services';

function getData() {
  return axios.all([
    contactService.getAllContacts(),
    groupService.getMailableGroups(),
    groupService.getViewableGroups(),
  ]);
}

export const contactsIndexService = {
  getData,
};
