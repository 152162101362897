const DashboardShow = () => import('~/views/dashboard/show/Show');

export const dashboard = [
  {
    component: DashboardShow,
    meta: {
      adminRestricted: false,
      requiresAuth: true,
    },
    name: 'Dashboard',
    path: '/',
  },
];
