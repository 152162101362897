import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Post } from '~/models/postHierarchy';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export class Sending extends Post {
  static get entity() {
    return 'Sending';
  }

  static baseEntity() {
    return 'Post';
  }

  static fields() {
    return {
      ...super.fields(),
    };
  }

  actions = () => {
    return {
      button: {
        hasRoute: false,
        isOutlined: true,
        params: {},
        route: '',
        text: 'Cancel',
      },
    };
  };

  details = () => {
    const numberSent = this.number_sent ? this.number_sent : 0;
    const totalContacts = this.total_targets;

    return {
      hasIcon: false,
      html: `
      <p class="mb-0">
      Sending ${numberSent} out of ${totalContacts} contact${
        totalContacts !== 1 ? 's' : ''
      }
      </p>`,
      icon: '',
    };
  };

  subhead = () => {
    const count = this.total_targets;

    return {
      action: {
        hasLink: false,
        link: {},
        text: `Edited ${
          this.updated_at ? dayjs(this.updated_at).utc().fromNow() : ''
        }`,
      },
      details: {
        hasLink: false,
        link: {},
        text: `Will be sent to ${count} contact${count !== 1 ? 's' : ''}`,
      },
    };
  };
}

export default Sending;
