import { Model } from '@vuex-orm/core';
import { User } from '~/models/userHierarchy';
import PromoterScoreQuestion from '~/models/promoterScoreQuestion';

export class PromoterScoreUserAnswer extends Model {
  static get entity() {
    return 'PromoterScoreUserAnswer';
  }

  static fields() {
    return {
      created_at: this.attr(''),
      id: this.attr(null),
      promoter_score_id: this.attr(null),
      promoter_score_question_id: this.attr(null),
      updated_at: this.attr(''),
      user_id: this.attr(null),
      value: this.attr(''),

      promoter_score_question: this.belongsTo(PromoterScoreQuestion, 'promoter_score_question_id'),
      user: this.belongsTo(User, 'user_id'),
    };
  }
}

export default PromoterScoreUserAnswer;
