import { Model } from '@vuex-orm/core';
import PromoterScoreUserAnswer from '~/models/promoterScoreUserAnswer'

export class PromoterScoreQuestion extends Model {
  static get entity() {
    return 'PromoterScoreQuestion';
  }

  static fields() {
    return {
      account_id: this.attr(null),
      category: this.attr(''),
      created_at: this.attr(''),
      id: this.attr(null),
      promoter_score_id: this.attr(''),
      question: this.attr(''),
      updated_at: this.attr(''),
      value_type: this.attr(''),

      promoter_score_user_answers: this.hasMany(PromoterScoreUserAnswer, 'promoter_score_question_id')
    };
  }

  getQuestionType = () => {
    return {
      FreeResponse: 'freeResponse',
      Range: 'range',
    }[this.value_type];
  }
}

export default PromoterScoreQuestion;
