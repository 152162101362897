export const DEFAULT_EMAIL = 'notifications@recess.io';
export const MAX_HEALTH_SCORE = 144;
export const MAX_ENGAGEMENT_SCORE = 14;

export const ACCESS_PLANS = {
  FREE: 'Free',
  PROFESSIONAL: 'Pro',
  STANDARD: 'Standard',
};

export const CHANNELS = {
  COMMENT: 'CommentChannel',
  SURVEY: 'SurveyChannel',
};

export const FEATURES = {
  ADDITIONAL_SEATS: 'additional_seats',
  AUTOMATIONS: 'automations',
  BRANDING: 'branding',
  CHECKINS: 'checkins',
  CHILD_GROUPS: 'child_groups',
  COMMENTS: 'comments',
  ENPS: 'enps',
  GROUPS: 'groups',
  INSIGHTS: 'insights',
  INTEGRATIONS: 'integrations',
  META: {
    GSUITE: 'gsuite',
    OFFICE: 'office',
  },
  SENDING_DOMAINS: 'sending_domains',
  SURVEYS: 'surveys',
  SURVEY_TEMPLATES: 'survey_templates',
};

export const ONBOARDING_COMPLETE = 'Complete';

export const PERMISSIONS = {
  ADD_CUSTOM_ROLES: 'add_custom_roles',
  ADD_GROUP: 'add_group',
  ADD_REMOVE_USER: 'add_remove_user',
  ADD_USER_ROLE: 'add_user_role',
  ADD_USER_VIA_CSV: 'add_user_via_csv',
  CREATE_EDIT_SURVEYS: 'create_edit_surveys',
  CREATE_SEND_BROADCAST: 'create_send_broadcast',
  CREATE_SEND_ENPS: 'create_send_enps',
  DOWNLOAD_REPORTS: 'download_reports',
  EDIT_ACCOUNT_SETTING: 'edit_account_setting',
  EDIT_USER_ROLE: 'edit_user_role',
  INVITE_USER: 'invite_user',
  MANAGE_BILLING_SUBSCRIPTION: 'manage_billing_subscription',
  MANAGE_USER_WITHIN_GROUP: 'manage_user_within_group',
  READ_COMMENTS: 'read_comments',
  REMOVE_GROUP: 'remove_group',
  REMOVE_USER: 'remove_user',
  RESEND_CANCEL_PENDING_INVITE: 'resend_cancel_pending_invite',
  SEND_CHECKIN_POLLS: 'send_checkin_polls',
  VIEW_INSIGHTS_REPORTING: 'view_insights_reporting',
};

export const STATUS = {
  USER: {
    ACTIVE: 'active',
    DEACTIVATED: 'deactivated',
    PENDING_INVITE: 'pending_invite',
    UNSUBSCRIBED: 'unsubscribed',
  },
};

export const BULK_IMPORT = 'BulkImport';
export const CREATE_ACCOUNT = 'CreateAccount';
export const GOOGLE_IMPORT = 'GoogleImport';
export const IMPORT_SUCCESS = 'ImportSuccess';
export const INDIVIDUAL_IMPORT = 'IndividualImport';
export const INVITE_TEAM = 'InviteTeam';
export const MANUAL_IMPORT = 'ManualImport';
export const SCHEDULE_CHECKIN = 'ScheduleCheckin';
export const SEND_ANNOUNCEMENT = 'SendAnnouncement';
export const SLACK_IMPORT = 'SlackImport';
export const OFFICE_IMPORT = 'OfficeImport';
export const UPLOAD_CONTACTS = 'UploadContacts';
