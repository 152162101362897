import { Admin } from '~/models/userHierarchy';
import { FEATURES } from '~/helpers/constants';

export class Owner extends Admin {
  static get entity() {
    return 'Owner';
  }

  static baseEntity = 'Admin';

  static fields() {
    return {
      ...super.fields(),
    };
  }

  accountTabs = () => {
    return [
      {
        active: false,
        name: 'Account Settings',
        params: {},
        url: 'AccountShow',
      },
      {
        active: false,
        name: 'Subscription',
        params: {},
        url: 'SubscriptionShow',
      },
      {
        active: false,
        feature: FEATURES.SENDING_DOMAINS,
        name: 'Sending Domains',
        params: {},
        url: 'SendingDomainIndex',
      },
      {
        active: false,
        feature: FEATURES.INTEGRATIONS,
        name: 'Integrations',
        params: {},
        url: 'IntegrationIndex',
      },
    ];
  };

  hasOwnerAccess = () => {
    return true;
  };
}

export default Owner;
