import SendingDomain from '~/models/sendingDomain';

function createSendingDomain(payload) {
  const { sendingDomain } = payload;

  return SendingDomain.api().post('/api/sending_domains', {
    sendingDomain,
  });
}

function deleteSendingDomain(payload) {
  const { id } = payload;

  return SendingDomain.api().delete(`/api/sending_domains/${id}`);
}

function getAllDomains() {
  return SendingDomain.api().get('/api/sending_domains');
}

function getSendingDomain(payload) {
  const { id } = payload;

  return SendingDomain.api().get(`/api/sending_domains/${id}`);
}

function updateFromField(data) {
  const { from, id } = data;

  return SendingDomain.api().put(`/api/sending_domains/${id}`, {
    from,
  });
}

export const sendingDomainService = {
  createSendingDomain,
  deleteSendingDomain,
  getAllDomains,
  getSendingDomain,
  updateFromField,
};
