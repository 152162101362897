import Integration from '../models/integration';

function createIntegration(payload) {
  const { data, source } = payload;

  return Integration.api().post(`/api/integrations/${source}`, {
    ...data,
  });
}

function getAccountIntegrations() {
  return Integration.api().get('/api/integrations/accounts');
}

function getAllIntegrations() {
  return Integration.api().get('/api/integrations');
}

function getIntegration(payload) {
  const { source } = payload;

  return Integration.api().get(`/api/integrations/${source}`);
}

function getPocketRedirect() {
  return Integration.api().get(
    '/api/integrations/pocket/get_authentication_redirect',
  );
}

function importGsuiteUsers(payload) {
  const { data } = payload;

  return Integration.api().post('/api/integrations/gsuite', {
    ...data,
  });
}

function importUsers(payload) {
  const { data } = payload;

  return Integration.api().post('/api/integrations/gsuite', {
    ...data,
  });
}

function refreshIntegration(payload) {
  const { id, source } = payload;

  return Integration.api().post(`/api/integrations/${source}/${id}/refresh`);
}

function removeIntegration(payload) {
  const { id, source } = payload;

  return Integration.api().delete(`/api/integrations/${source}/${id}`);
}

export const integrationService = {
  createIntegration,
  getAccountIntegrations,
  getAllIntegrations,
  getIntegration,
  getPocketRedirect,
  importGsuiteUsers,
  importUsers,
  refreshIntegration,
  removeIntegration,
};
