import { Model } from '@vuex-orm/core';

export default class Template extends Model {
  static get entity() {
    return 'Template';
  }

  static fields() {
    return {
      account_id: this.attr(null),
      created_at: this.string(''),
      creator_id: this.attr(null),
      description: this.string('').nullable(),
      id: this.attr(null),
      required: this.boolean(null).nullable(),
      status: this.string('').nullable(),
      subject: this.string(''),
      suggestion: this.string('').nullable(),
      thumbnail_url: this.string('').nullable(),
      title: this.string('').nullable(),
      type: this.string('Template'),
      updated_at: this.string(''),
    };
  }
}
