import Account from '~/models/account';
import { User } from '~/models/userHierarchy';
import { FEATURES } from '~/helpers/constants';

export default {
  computed: {
    account() {
      const account = document
        .querySelector('body')
        .getAttribute('data-account');
      const { requiresAuth } = this.$route.meta;

      if (account && requiresAuth) {
        return Account.query()
          .whereId(parseInt(JSON.parse(account).id, 10))
          .withAll()
          .first();
      }

      return null;
    },
    canAddAdditionalSeats() {
      return this.isFeatureUnlocked(FEATURES.ADDITIONAL_SEATS);
    },
    currentUser() {
      const user = document.querySelector('body').getAttribute('data-user');
      const { requiresAuth } = this.$route.meta;

      if (user && requiresAuth) {
        return User.query().whereId(JSON.parse(user).id).withAll().first();
      }

      return null;
    },
    hasAccess() {
      return this.currentUser && this.currentUser.hasAccess();
    },
    hasAdminAccess() {
      return this.currentUser && this.currentUser.hasAdminAccess();
    },
    hasOwnerAccess() {
      return this.currentUser && this.currentUser.hasOwnerAccess();
    },
    isGroupsUnlocked() {
      return this.isFeatureUnlocked(FEATURES.GROUPS);
    },
    isGsuiteIntegrationUnlocked() {
      return this.isFeatureUnlocked(
        FEATURES.INTEGRATIONS,
        FEATURES.META.GSUITE,
      );
    },
    isOfficeIntegrationUnlocked() {
      return this.isFeatureUnlocked(
        FEATURES.INTEGRATIONS,
        FEATURES.META.OFFICE,
      );
    },
    isOverUserLimit() {
      return this.account && this.account.isOverUserCount();
    },
    viewer() {
      return this.currentUser && this.currentUser.viewer();
    },
  },
  methods: {
    hasPermission(permissionName) {
      return this.currentUser && this.currentUser.hasPermission(permissionName);
    },
    isFeatureUnlocked(featureName, metaName = null) {
      return (
        this.account && this.account.isFeatureUnlocked(featureName, metaName)
      );
    },
  },
};
