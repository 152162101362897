import axios from 'axios';
import Checkin from '~/models/checkin';
import CheckinUserAnswer from '~/models/checkinUserAnswer';
import PromoterScore from '~/models/promoterScore';
import PromoterScoreUserAnswer from '~/models/promoterScoreUserAnswer';
import { CurrentUser, User } from '~/models/userHierarchy';
import store from '~/store';
import SurveyUserAnswer from '~/models/surveyUserAnswer';

function addToGroups(payload) {
  const { group_ids, new_group_names, user_ids } = payload;
  return User.api().post('/api/contacts/bulk_add_to_groups', {
    group_ids,
    new_group_names,
    user_ids,
  });
}

function batchInviteUser(payload, authenticityToken = null) {
  const contacts = { requests: [] };

  for (const contact of payload) {
    contacts.requests.push({
      body: contact,
      method: 'POST',
      url: '/api/contacts',
    });
  }

  return axios({
    data: {
      ...contacts,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'POST',
    url: '/api/v1/batch_sequential',
  })
    .then(response => {
      const newUsers = [];
      const data = response.data.responses.response;

      data.forEach(res => {
        if (res.status >= 200 && res.status < 300) newUsers.push(res.response);
      });

      if (newUsers.length) {
        User.insert({
          data: [...newUsers],
        });
      } else {
        return {
          error: 'An error occurred importing your contacts.',
          status: 500,
        };
      }

      return response;
    })
    .catch(() => {
      throw new Error('There was an error adding the new contacts.');
    });
}

function deleteContacts(payload) {
  const { userIds } = payload;

  return User.api().post('/api/contacts/bulk_delete_contacts', {
    user_ids: userIds,
  });
}

function getAllContacts() {
  return User.api().get('/api/contacts');
}

function getById(id) {
  return User.api().get(`/api/contacts/${id}`);
}

function getCheckinsByDateWithAnswers(payload) {
  const { date, userId } = payload;

  return Checkin.api().get(
    `/api/contacts/${userId}/checkins_by_date_with_answers`,
    {
      params: {
        date,
      },
    },
  );
}

function getCheckinsByUser(id) {
  return Checkin.api().get(`/api/contacts/${id}/checkins`);
}

function getCheckinUserAnswersById(payload) {
  const { checkinId, userId } = payload;
  return CheckinUserAnswer.api().get(
    `/api/contacts/${userId}/checkin/${checkinId}/answers`,
  );
}

function getContactsById(payload) {
  return User.api().get(`/api/contacts/fetch_contacts`, {
    params: {
      user_ids: payload,
    },
  });
}

function getCurrentUser() {
  return CurrentUser.api().get('/api/contacts/current');
}

async function getGroupContactsWithCheckins(payload) {
  const { groupId, limit } = payload;
  const result = await User.api()
    .get(`/api/groups/${groupId}/contacts`, {
      params: {
        limit: limit || 30,
        order: 'first_name,asc',
      },
    })
    .then(response => {
      const {
        response: { headers },
      } = response;
      const data = {
        baseUrl: `/api/groups/${groupId}/contacts`,
        context: User,
        page: 'insight-checkin-group-show',
        perPage: +headers['x-per-page'],
        total: +headers['x-total'],
      };

      store.dispatch('pagination/setHeaders', data);
    });

  return result;
}

async function getGroupContactsWithPromoterScores(payload) {
  const { groupId, limit } = payload;
  const result = await User.api()
    .get(`/api/groups/${groupId}/promoter_score_contacts`, {
      params: {
        limit: limit || 30,
        order: 'first_name,asc',
      },
    })
    .then(response => {
      const {
        response: { headers },
      } = response;
      const data = {
        baseUrl: `/api/groups/${groupId}/promoter_score_contacts`,
        context: User,
        page: 'insight-promoter-score-group-show',
        perPage: +headers['x-per-page'],
        total: +headers['x-total'],
      };

      store.dispatch('pagination/setHeaders', data);
    });

  return result;
}

function getPromoterScoresByDateWithAnswers(payload) {
  const { date, userId } = payload;
  return PromoterScore.api().get(
    `/api/contacts/${userId}/enps_by_date_with_answers`,
    {
      params: {
        date,
      },
    },
  );
}

function getPromoterScoresByUser(id) {
  return PromoterScore.api().get(`/api/contacts/${id}/enps`);
}

function getPromoterScoreUserAnswersById(payload) {
  const { promoterScoreId, userId } = payload;
  return PromoterScoreUserAnswer.api().get(
    `/api/contacts/${userId}/enps/${promoterScoreId}/answers`,
  );
}

function getSurveyUserAnswersById(payload) {
  const { surveyId, userId } = payload;
  return SurveyUserAnswer.api().get(
    `/api/contacts/${userId}/surveys/${surveyId}/answers`,
  );
}

function getUsersByPost(id) {
  return User.api().get(`/api/contacts?filter[targets.schedule_id]=${id}`);
}

function inviteUser(payload) {
  const { email, firstName, group_ids, lastName, new_group_names } = payload;
  return User.api().post('/api/contacts', {
    email,
    first_name: firstName,
    group_ids,
    last_name: lastName,
    new_group_names,
  });
}

function removeFromGroups(payload) {
  const { groupIds, userIds } = payload;
  return User.api().post('/api/contacts/bulk_delete_from_groups', {
    group_ids: groupIds,
    user_ids: userIds,
  });
}

function resubscribe(userIds) {
  return User.api().put('/api/contacts/resub', {
    user_ids: userIds,
  });
}

function saveProfile(payload) {
  const { authenticityToken, data, id } = payload;

  return axios({
    data: {
      ...data,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    method: 'PUT',
    url: `/api/contacts/${id}`,
  })
    .then(response => response.data)
    .catch(() => {
      throw new Error('There was an error saving your profile.');
    });
}

function sendVerification(userIds) {
  return User.api().put(`/api/contacts/send_verification_email`, {
    user_ids: userIds,
  });
}

function update(payload) {
  const {
    email,
    first_name,
    group_ids,
    id,
    last_name,
    new_group_names,
    type,
  } = payload;
  return User.api().put(`/api/contacts/${id}`, {
    contact: {
      email,
      first_name,
      last_name,
      type,
    },
    group_ids,
    id,
    new_group_names,
  });
}

function updateType(payload) {
  const { id, type } = payload;
  return User.api().put(`/api/contacts/${id}/update_type`, {
    type,
  });
}

function updateBroadcastPermissions(payload) {
  const { groupId, id } = payload;
  return User.api().put(`/api/contacts/${id}/update_broadcast_permissions`, {
    group_id: groupId,
  });
}

function updateViewPermissions(payload) {
  const { groupId, id } = payload;
  return User.api().put(`/api/contacts/${id}/update_view_permissions`, {
    group_id: groupId,
  });
}

function updateAvatar(payload) {
  const { avatar, id } = payload;
  return User.api().put(`/api/contacts/${id}/update_avatar`, {
    avatar,
  });
}

export const contactService = {
  addToGroups,
  batchInviteUser,
  deleteContacts,
  getAllContacts,
  getById,
  getCheckinUserAnswersById,
  getCheckinsByDateWithAnswers,
  getCheckinsByUser,
  getContactsById,
  getCurrentUser,
  getGroupContactsWithCheckins,
  getGroupContactsWithPromoterScores,
  getPromoterScoreUserAnswersById,
  getPromoterScoresByDateWithAnswers,
  getPromoterScoresByUser,
  getSurveyUserAnswersById,
  getUsersByPost,
  inviteUser,
  removeFromGroups,
  resubscribe,
  saveProfile,
  sendVerification,
  update,
  updateAvatar,
  updateBroadcastPermissions,
  updateType,
  updateViewPermissions,
};
