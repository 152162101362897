import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import axios from 'axios';
import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
// import VuexORMGraphQL from '@vuex-orm/plugin-graphql';
// import VuexPersistence from 'vuex-persist';

// Vuex ORM models
import Account from '~/models/account';
import Automation from '~/models/automation';
import Card from '~/models/card';
import Checkin from '~/models/checkin';
import CheckinQuestion from '~/models/checkinQuestion';
import CheckinQuestionJoin from '~/models/checkinQuestionJoin';
import CheckinStat from '~/models/checkinStat';
import CheckinTarget from '~/models/checkinTarget';
import CheckinUserAnswer from '~/models/checkinUserAnswer';
import Comment from '~/models/comment';
import { Event, Opened } from '~/models/eventHierarchy';
import { Group } from '~/models/groupHierarchy';
import GroupAdminUser from '~/models/groupAdminUser';
import GroupManager from '~/models/groupManager';
import GroupUser from '~/models/groupUser';
import HealthScore from '~/models/healthScore';
import Integration from '~/models/integration';
import Invoice from '~/models/invoice';
import MailableUser from '~/models/mailableUser';
import PayPlan from '~/models/payPlan';
import PlanFeature from '~/models/planFeature';
import { Post } from '~/models/postHierarchy';
import PromoterScore from '~/models/promoterScore';
import PromoterScoreQuestion from '~/models/promoterScoreQuestion';
import PromoterScoreQuestionJoin from '~/models/promoterScoreQuestionJoin';
import PromoterScoreStat from '~/models/promoterScoreStat';
import PromoterScoreTarget from '~/models/promoterScoreTarget';
import PromoterScoreUserAnswer from '~/models/promoterScoreUserAnswer';
import Reply from '~/models/reply';
import RolePermission from '~/models/rolePermission';
import SendingDomain from '~/models/sendingDomain';
import Schedule from '~/models/schedule';
import Stat from '~/models/stat';
import Subscription from '~/models/subscription';
import SubscriptionItem from '~/models/subscriptionItem';
import { Survey } from '~/models/surveyHierarchy';
import SurveyQuestion from '~/models/surveyQuestion';
import SurveyQuestionOption from '~/models/surveyQuestionOption';
import SurveyTarget from '~/models/surveyTarget';
import SurveyTemplate from '~/models/surveyTemplate';
import SurveyUserAnswer from '~/models/surveyUserAnswer';
import Target from '~/models/target';
import Template from '~/models/template';
import {
  User,
  CurrentUser,
  GroupAdmin,
  Admin,
  Owner,
} from '~/models/userHierarchy';
import ViewableUser from '~/models/viewableUser';
import Stripe from '~/models/stripe';
import Role from '~/models/role';
import Permission from '~/models/permission';

// Other Vuex modules
import alert from './alert.module';
import pagination from './pagination.module';
import post from './post.module';
import user from './user.module';

const authenticityToken = document
  .getElementsByName('csrf-token')[0]
  .getAttribute('content');

Vue.use(Vuex);
Vue.prototype.$http = axios;

VuexORM.use(VuexORMAxios, {
  axios,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': authenticityToken,
  },
});

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
// });

const database = new VuexORM.Database();

database.register(User);
database.register(Card);
database.register(CurrentUser);
database.register(GroupAdmin);
database.register(Admin);
database.register(Account);
database.register(Automation);
database.register(Checkin);
database.register(CheckinQuestion);
database.register(CheckinQuestionJoin);
database.register(CheckinStat);
database.register(CheckinTarget);
database.register(CheckinUserAnswer);
database.register(Comment);
database.register(Event);
database.register(Group);
database.register(Integration);
database.register(Invoice);
database.register(MailableUser);
database.register(GroupAdminUser);
database.register(GroupManager);
database.register(GroupUser);
database.register(HealthScore);
database.register(Opened);
database.register(Owner);
database.register(PayPlan);
database.register(PlanFeature);
database.register(Post);
database.register(PromoterScore);
database.register(PromoterScoreQuestion);
database.register(PromoterScoreQuestionJoin);
database.register(PromoterScoreStat);
database.register(PromoterScoreTarget);
database.register(PromoterScoreUserAnswer);
database.register(Reply);
database.register(RolePermission);
database.register(SendingDomain);
database.register(Schedule);
database.register(Stat);
database.register(Subscription);
database.register(SubscriptionItem);
database.register(Survey);
database.register(SurveyQuestion);
database.register(SurveyQuestionOption);
database.register(SurveyTarget);
database.register(SurveyTemplate);
database.register(SurveyUserAnswer);
database.register(Target);
database.register(Template);
database.register(ViewableUser);
database.register(Stripe);
database.register(Role);
database.register(Permission);

// VuexORM.use(VuexORMGraphQL, { database });

const store = new Vuex.Store({
  modules: {
    alert,
    pagination,
    post,
    user,
  },
  mutations: {
    setDrawer: (state, payload) => {
      state.drawer = payload;
    },
    toggleDrawer: state => {
      state.drawer = !state.drawer;
    },
  },
  plugins: [VuexORM.install(database)],
  state: {
    drawer: false,
  },
});

export default store;
