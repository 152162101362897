import { FEATURES } from '~/helpers/constants';

const PromoterScoreShow = () => import('~/views/enps/Show');

export const enps = [
  {
    meta: {
      adminRestricted: true,
      featureName: FEATURES.ENPS,
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    path: '/enps',
    redirect: { name: 'InsightPromoterScoreIndex' },
  },
  {
    component: PromoterScoreShow,
    meta: {
      adminRestricted: false,
      featureName: FEATURES.ENPS,
      isNavigationDrawerVisible: false,
      requiresAuth: true,
    },
    name: 'PromoterScoreShow',
    path: '/enps/:id',
  },
];
