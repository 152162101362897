import { Model } from '@vuex-orm/core';

export class RolePermission extends Model {
  static get entity() {
    return 'RolePermission';
  }

  static primaryKey = ['permission_id', 'role_id'];

  static fields() {
    return {
      id: this.attr(null),
      permission_id: this.attr(null),
      permitted: this.attr(null),
      role_id: this.attr(null),
    };
  }
}

export default RolePermission;
