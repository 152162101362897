import { userService } from '~/services/index';

const user = {
  namespaced: true,

  state: {
    account: {},
    currentUser: {},
    loading: null,
    error: null,
  },

  actions: {
    forgotPassword({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        userService.forgotPassword(payload)
          .then(response => resolve(response))
          .catch(error => {
            dispatch('alert/error', error, { root: true });
            reject(error);
          });
      });
    },
    loginUser({ dispatch, commit }, payload) {
      commit('loginRequest');
      return new Promise((resolve, reject) => {
        userService.login(payload)
          .then(response => {
            commit('loginSuccess', response.data);
            resolve(response);
          })
          .catch(error => {
            commit('loginFailure', error);
            dispatch('alert/error', error, { root: true });
            reject(error);
          });
      });
    },
    logoutUser({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        userService.logout(payload)
          .then(response => {
            commit('logoutSuccess');
            resolve(response);
          })
          .catch(error => {
            commit('logoutFailure', error);
            dispatch('alert/error', error, { root: true });
            reject(error);
          });
      });
    },
    resendConfirmation({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        userService.resendConfirmation(payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            dispatch('alert/error', error, { root: true });
            reject(error);
          });
      });
    },
  },

  mutations: {
    loginRequest(state) {
      state.loading = true;
      state.erorr = null;
    },
    loginSuccess(state, payload) {
      state.loading = false;
      state.currentUser = payload;
      state.error = null;
    },
    loginFailure(state, error) {
      state.loading = false;
      state.currentUser = {};
      state.error = error;
    },
    logoutSuccess(state) {
      state.loading = false;
      state.currentUser = {};
      state.erorr = null;
    },
    logoutFailure(state, error) {
      state.loading = false;
      state.error = error;
    },
    setUser(state, payload) {
      state.currentUser = payload;
    },
    setAccount(state, payload) {
      state.account = payload;
    },
  },
};

export default user;
