import { Model } from '@vuex-orm/core';

export default class Target extends Model {
  static get entity() {
    return 'Target';
  }

  static fields() {
    return {
      id: this.attr(null),
      target_id: this.attr(null),
      target_type: this.string(''),
      group_type: this.string('').nullable(),
      post_id: this.attr(null).nullable(),
      created_at: this.string(''),
      updated_at: this.string(''),
      schedule_id: this.attr(null),
    };
  }
};
