var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showNavigation)?_c('v-navigation-drawer',{staticClass:"side-navigation",style:(_vm.styles),attrs:{"app":"","clipped":"","height":"100%","mobile-break-point":"960","temporary":false,"width":"168"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"active"}},[_c('v-list-item',{staticClass:"navigation-item",attrs:{"exact":"","link":"","ripple":false,"to":{ name: 'Dashboard' }}},[_c('v-list-item-content',{staticClass:"navigation-link"},[_c('v-list-item-title',[_vm._v("\n            Dashboard\n          ")])],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-list-item',{staticClass:"navigation-item",attrs:{"link":"","ripple":false,"to":{ name: 'PostIndex' }}},[_c('v-list-item-content',{staticClass:"navigation-link"},[_c('v-list-item-title',[_vm._v("\n            Broadcasts\n          ")])],1)],1),_vm._v(" "),(_vm.hasAccess && _vm.showTemplatesItem)?[_c('v-divider'),_vm._v(" "),_c('v-list-item',{staticClass:"navigation-item",attrs:{"link":"","ripple":false,"to":{ name: 'TemplateIndex' }}},[_c('v-list-item-content',{staticClass:"navigation-link"},[_c('v-list-item-title',[_vm._v("\n              Templates\n            ")])],1)],1)]:_vm._e(),_vm._v(" "),(_vm.hasAccess)?[_c('v-divider'),_vm._v(" "),_c('v-list-item',{staticClass:"navigation-item",attrs:{"link":"","ripple":false,"to":{ name: 'InsightCheckinIndex' }}},[_c('v-list-item-content',{staticClass:"navigation-link"},[_c('v-list-item-title',[_vm._v("\n              Check-ins\n            ")])],1)],1)]:_vm._e(),_vm._v(" "),(_vm.hasAccess)?[_c('v-divider'),_vm._v(" "),_c('v-list-item',{class:['navigation-item', {
            'locked': !_vm.checkFeatureAccess.enps,
            'v-list-item--active active': _vm.checkFeatureAccess.enps &&
              _vm.isMenuItemActive('InsightPromoterScoreIndex'),
          }],attrs:{"link":"","ripple":false},on:{"click":function($event){return _vm.checkLockedState('InsightPromoterScoreIndex', _vm.features.ENPS)}}},[_c('v-list-item-content',{staticClass:"navigation-link"},[_c('v-list-item-title',{class:{ 'is-locked': !_vm.checkFeatureAccess.enps }},[_vm._v("\n              eNPS"),_c('sup',{class:['trademark', {
                  'has-lock-icon': !_vm.checkFeatureAccess.enps,
                }]},[_vm._v("®")]),_vm._v(" "),(!_vm.checkFeatureAccess.enps)?_c('v-icon',{staticClass:"icon-lock",attrs:{"size":"12"}},[_vm._v("\n                mdi-lock\n              ")]):_vm._e()],1)],1)],1)]:_vm._e(),_vm._v(" "),(_vm.hasAccess && _vm.hasAdminAccess)?[_c('v-divider'),_vm._v(" "),_c('v-list-item',{class:['navigation-item', {
            'locked': !_vm.checkFeatureAccess.surveys,
            'v-list-item--active active': _vm.checkFeatureAccess.surveys &&
              _vm.isMenuItemActive('SurveyIndex'),
          }],attrs:{"link":"","ripple":false},on:{"click":function($event){return _vm.checkLockedState('SurveyIndex', _vm.features.SURVEYS)}}},[_c('v-list-item-content',{staticClass:"navigation-link"},[_c('v-list-item-title',{class:{ 'is-locked': !_vm.checkFeatureAccess.surveys }},[_vm._v("\n              Surveys\n              "),(!_vm.checkFeatureAccess.surveys)?_c('v-icon',{staticClass:"icon-lock",attrs:{"size":"12"}},[_vm._v("\n                mdi-lock\n              ")]):_vm._e()],1)],1)],1)]:_vm._e(),_vm._v(" "),(_vm.hasAccess)?[_c('v-divider'),_vm._v(" "),_c('v-list-item',{staticClass:"navigation-item",attrs:{"link":"","ripple":false,"to":{ name: 'AutomationIndex' }}},[_c('v-list-item-content',{staticClass:"navigation-link"},[_c('v-list-item-title',[_vm._v("\n              Automations\n            ")])],1)],1)]:_vm._e(),_vm._v(" "),(_vm.hasAccess)?[_c('v-divider'),_vm._v(" "),_c('v-list-item',{staticClass:"navigation-item",attrs:{"link":"","ripple":false,"to":{ name: 'InsightHealthScoreShow' }}},[_c('v-list-item-content',{staticClass:"navigation-link"},[_c('v-list-item-title',[_vm._v("\n              Insights\n            ")])],1)],1)]:_vm._e(),_vm._v(" "),_c('v-divider')],2),_vm._v(" "),_c('v-list-item-group',[_c('v-list-item',{staticClass:"navigation-item",attrs:{"href":"https://help.recess.io/en/?ref=app","link":"","ripple":false,"target":"_blank"}},[_c('v-list-item-content',{staticClass:"navigation-link"},[_c('v-list-item-title',{staticClass:"external-link"},[_vm._v("\n            Help Center\n            "),_c('v-icon',{staticClass:"icon-external",attrs:{"color":"rgba(28, 32, 38, 0.4)","size":"15"}},[_vm._v("\n              mdi-open-in-new\n            ")])],1)],1)],1),_vm._v(" "),_c('v-divider')],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }