import { Model } from '@vuex-orm/core';
import { Survey } from '~/models/surveyHierarchy';

export class SurveyTarget extends Model {
  static get entity() {
    return 'SurveyTarget';
  }

  static fields() {
    return {
      completed_count: this.number(0),
      id: this.attr(null),
      survey_id: this.attr(null),
      targetable_id: this.attr(null),
      targetable_type: this.attr(null),
      total_targets: this.number(0),

      survey: this.belongsTo(Survey, 'survey_id'),
    };
  }

  averageParticipationPercent = () => {
    if (this.completed_count === 0 && this.total_targets === 0) {
      return String.fromCharCode(8212);
    }

    const division = this.completed_count / this.total_targets;
    if (isNaN(division) || division === Infinity) return '0%';

    return `${Math.round(division * 100)}%`;
  }

  status = () => {
    if (this.targetable_type === 'User') {
      return this.completed_count === 1 ? 'Complete' : 'Incomplete'
    }
  }
}

export default SurveyTarget;
