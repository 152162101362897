import { Event } from '~/models/eventHierarchy';

export class Checkin extends Event {
  static get entity() {
    return 'checkin';
  }

  static baseEntity = 'event';

  static fields() {
    return {
      ...super.fields(),
    };
  }

  description = (user = null) => {
    const from = user ? user : 'This contact';
    return `${from} has completed a Check-in.`;
  }

  icon = () => {
    return 'mdi-emoticon-netural';
  }

  title = () => {
    return 'Check-in';
  }
}

export default Checkin;
