import PromoterScore from '~/models/promoterScore';

function addToBroadcast(payload) {
  const { draft } = payload;

  return PromoterScore.api().post(`/api/enps/add_to_broadcast`, {
    draft,
  });
}

function answerQuestion(payload) {
  const { promoterScoreId, questionId, value } = payload;

  return PromoterScore.api().post('/api/enps/answer_question', {
    promoter_score_id: promoterScoreId,
    question_id: questionId,
    value,
  });
}

function complete(payload) {
  const { promoterScoreId, questions } = payload;

  return PromoterScore.api().post('/api/enps/complete', {
    id: promoterScoreId,
    questions,
  });
}

function getById(payload) {
  const { id } = payload;

  return PromoterScore.api().get(`/api/enps/${id}`);
}

function getGroupSentPromoterScores(groupId) {
  return PromoterScore.api().get(`/api/groups/${groupId}/enps`);
}

function getSentPromoterScores() {
  return PromoterScore.api().get('/api/enps');
}

export const promoterScoreService = {
  addToBroadcast,
  answerQuestion,
  complete,
  getById,
  getGroupSentPromoterScores,
  getSentPromoterScores,
};
