const ForgotPassword = () => import('~/views/sessions/ForgotPassword');
const Login = () => import('~/views/sessions/Login');
const Register = () => import('~/views/sessions/Register');
const ResendConfirmation = () => import('~/views/sessions/ResendConfirmation');
const ResetPassword = () => import('~/views/sessions/ResetPassword');
const ConfirmationShow = () => import('~/views/sessions/ConfirmationShow');

export const user = [
  {
    component: Login,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      isPublic: true,
      requiresAuth: false,
    },
    name: 'Login',
    path: '/users/sign_in',
  },
  {
    component: Register,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      isPublic: true,
      requiresAuth: false,
    },
    name: 'Register',
    path: '/users/sign_up',
  },
  {
    component: ResetPassword,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      isPublic: true,
      requiresAuth: false,
    },
    name: 'ResetPassword',
    path: '/users/password/edit',
  },
  {
    component: ForgotPassword,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      isPublic: true,
      requiresAuth: false,
    },
    name: 'ForgotPassword',
    path: '/users/password/new',
  },
  {
    component: ResendConfirmation,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      isPublic: true,
      requiresAuth: false,
    },
    name: 'ResendConfirmation',
    path: '/users/confirmation/new',
  },
  {
    component: ConfirmationShow,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      isPublic: true,
      requiresAuth: false,
    },
    name: 'ConfirmationShow',
    path: '/users/confirmation',
  },
];
