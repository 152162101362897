import { Model } from '@vuex-orm/core';

export class MailableUser extends Model {
  static get entity() {
    return 'MailableUser';
  }

  static primaryKey = ['group_id', 'user_id'];

  static fields() {
    return {
      group_id: this.attr(null),
      id: this.attr(null),
      user_id: this.attr(null),
    };
  }
}

export default MailableUser;
