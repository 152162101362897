import { Event } from '~/models/eventHierarchy';

export class PromoterScore extends Event {
  static get entity() {
    return 'promoter_score';
  }

  static baseEntity = 'event';

  static fields() {
    return {
      ...super.fields(),
    };
  }

  description = (user = null) => {
    const from = user ? user : 'This contact';
    return `${from} has completed an eNPS<sup class="trademark">&reg;</sup>.`;
  }

  icon = () => {
    return 'mdi-numeric-0-box';
  }

  title = () => {
    return 'eNPS';
  }
}

export default PromoterScore;
