import { Model } from '@vuex-orm/core';
import Comment from '~/models/comment';
import { User } from '~/models/userHierarchy';

export class Reply extends Model {
  static get entity() {
    return 'Reply';
  }

  static fields() {
    return {
      comment: this.belongsTo(Comment, 'comment_id'),
      comment_id: this.attr(null),
      created_at: this.string(''),
      id: this.attr(null),
      reply: this.string('').nullable(),
      user: this.belongsTo(User, 'user_id'),
      user_id: this.attr(null),
    };
  }
}

export default Reply;
