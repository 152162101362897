import { Model } from '@vuex-orm/core';
import CheckinUserAnswer from '~/models/checkinUserAnswer'

export class CheckinQuestion extends Model {
  static get entity() {
    return 'CheckinQuestion';
  }

  static fields() {
    return {
      id: this.attr(null),
      category: this.attr(''),
      checkin_id: this.attr(''),
      question: this.attr(''),
      value_type: this.attr(''),
      checkin_user_answers: this.hasMany(CheckinUserAnswer, 'checkin_question_id')
    };
  }

  getQuestionType = () => {
    return {
      Boolean: 'boolean',
      FreeResponse: 'freeResponse',
      Smiley: 'smiley',
    }[this.value_type];
  }
}

export default CheckinQuestion;
