import { Model } from '@vuex-orm/core';

export class GroupUser extends Model {
  static get entity() {
    return 'GroupUser';
  }

  static primaryKey = ['group_id', 'user_id'];

  static fields() {
    return {
      group_id: this.attr(null),
      id: this.attr(null),
      is_group_admin: this.boolean(false),
      user_id: this.attr(null),
    };
  }
}

export default GroupUser;
