import Automation from '~/models/automation';

function createAutomation(payload) {
  const { automationType, fromName } = payload;

  return Automation.api().post('/api/automations', {
    automation_type: automationType,
    from_name: fromName,
  });
}

function createOnboardCheckinAutomation(payload) {
  const { day } = payload;

  return Automation.api().post('/api/automations/create_onboard_checkin', {
    day,
  });
}

function createSurveyAutomation(payload) {
  const { fromName, surveyId } = payload;

  return Automation.api().post('/api/automations/surveys', {
    from_name: fromName,
    survey_id: surveyId,
  });
}

function deleteAutomations(payload) {
  const { automationIds } = payload;

  return Automation.api().post('/api/automations/bulk_delete_automations', {
    automation_ids: automationIds,
  });
}

function getAllAutomations() {
  return Automation.api().get('/api/automations');
}

function getById(payload) {
  const { id } = payload;

  return Automation.api().get(`/api/automations/${id}`);
}

function saveAutomation(payload) {
  const {
    automation: {
      automationTargetsAttributes,
      frequency,
      frequencyType,
      fromEmail,
      fromName,
      name,
      startDate,
      subject,
    },
    channel,
    id,
    sendSlack,
    start,
  } = payload;

  return Automation.api().put(`/api/automations/${id}`, {
    automation: {
      automation_targets_attributes: automationTargetsAttributes,
      frequency,
      frequency_type: frequencyType,
      from_email: fromEmail,
      from_name: fromName,
      name,
      start_date: startDate,
      subject,
    },
    channel,
    send_slack: sendSlack,
    start,
  });
}

function updateStatus(payload) {
  const { automationIds, status } = payload;

  return Automation.api().put('/api/automations/bulk_update_status', {
    automation_ids: automationIds,
    status,
  });
}

export const automationService = {
  createAutomation,
  createOnboardCheckinAutomation,
  createSurveyAutomation,
  deleteAutomations,
  getAllAutomations,
  getById,
  saveAutomation,
  updateStatus,
};
