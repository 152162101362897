import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Model } from '@vuex-orm/core';
import CheckinQuestion from '~/models/checkinQuestion';
import CheckinQuestionJoin from '~/models/checkinQuestionJoin';
import CheckinTarget from '~/models/checkinTarget';
import CheckinUserAnswer from '~/models/checkinUserAnswer';
import Smiley from '~/models/smiley';

dayjs.extend(utc);
export class Checkin extends Model {
  static get entity() {
    return 'Checkin';
  }

  static fields() {
    return {
      account_id: this.attr(null),
      checkin_questions: this.belongsToMany(
        CheckinQuestion,
        CheckinQuestionJoin,
        'checkin_id',
        'checkin_question_id',
      ),
      checkin_targets: this.hasMany(CheckinTarget, 'checkin_id'),
      checkin_user_answers: this.hasMany(CheckinUserAnswer, 'checkin_id'),
      draft: this.boolean(true),
      id: this.attr(null),
      sent_at: this.attr(null).nullable(),
    };
  }

  static state() {
    return {
      currentDate: '',
    };
  }

  getUserAnswer = type => {
    const question = this.checkin_questions.find(
      checkinQuestion => checkinQuestion.category === type,
    );
    const answer = this.checkin_user_answers.find(
      checkinAnswer => checkinAnswer.checkin_question_id === question.id,
    );
    return answer.value;
  };

  confidence = () => {
    return this.getUserAnswer('confidence');
  };

  mood = () => {
    return this.getUserAnswer('mood');
  };

  workload = () => {
    return this.getUserAnswer('workload');
  };

  overallCheckin = () => {
    return new Smiley({
      confidence: this.confidence(),
      mood: this.mood(),
      type: '',
      workload: this.workload(),
    });
  };
}

export default Checkin;
