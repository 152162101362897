export class Trend {
  constructor(trend) {
    this.trend = trend;

    this.hexCodes = {
      down: '#eb5757',
      neutral: '#1c2026',
      up: '#59ae00',
    };

    this.icons = {
      down: 'mdi-arrow-down',
      neutral: 'mdi-minus',
      up: 'mdi-arrow-up',
    };
  }

  get() {
    switch (true) {
      case this.trend === null:
        return {
          className: '',
          hasIcon: false,
          hexCode: this.hexCodes.neutral,
          icon: null,
        };
      case this.trend === 0:
        return {
          className: 'trend-neutral',
          hasIcon: true,
          hexCode: this.hexCodes.neutral,
          icon: this.icons.neutral,
        };
      case this.trend < 0:
        return {
          className: 'trend-down',
          hasIcon: true,
          hexCode: this.hexCodes.down,
          icon: this.icons.down,
        };
      case this.trend > 0:
        return {
          className: 'trend-up',
          hasIcon: true,
          hexCode: this.hexCodes.up,
          icon: this.icons.up,
        };
      default:
        return {
          className: '',
          hasIcon: true,
          hexCode: this.hexCodes.neutral,
          icon: this.icons.neutral,
        };
    }
  }

  getPercentage() {
    if (this.trend === null) {
      return String.fromCharCode(8212);
    }

    const trend = this.trend.toString().replace(/[^.\d]/g, '');

    return `${trend}%`;
  }
}

export default Trend;
