import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Model } from '@vuex-orm/core';
import { DEFAULT_EMAIL } from '~/helpers/constants';
import Survey from '~/models/survey';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

export class Automation extends Model {
  static get entity() {
    return 'Automation';
  }

  static fields() {
    return {
      account_id: this.attr(null),
      automation_type: this.string(''),
      created_at: this.string(''),
      frequency: this.attr(null),
      frequency_type: this.string(''),
      from_email: this.string(''),
      from_name: this.string(''),
      id: this.attr(null),
      last_sent: this.attr(null).nullable(),
      name: this.string(''),
      next_send: this.attr(null).nullable(),
      show: this.boolean(true),
      start_date: this.attr(null).nullable(),
      status: this.string(''),
      subject: this.string(''),
      survey: this.hasOne(Survey, 'automation_id'),
      total_targets: this.number(0),
      updated_at: this.string(''),
    };
  }

  humanizeFrequency = () => {
    if (!this.start_date) {
      return String.fromCharCode(8212);
    }

    const date = dayjs(this.start_date).utc();

    switch (this.frequency_type) {
      case 'week':
        return `Weekly on ${date.format('dddd')}`;
      case 'month':
        if (this.frequency === 3) {
          return `Quarterly on the ${date.format('Do')}`;
        }

        return `Monthly on the ${date.format('Do')}`;
      case 'year':
        return `Yearly on ${date.format('MMMM Do')}`;
      default:
        return String.fromCharCode(8212);
    }
  };

  isDefaultEmail = () => {
    return this.from_email === DEFAULT_EMAIL;
  };

  isModified = () => {
    return (
      dayjs(this.created_at).utc().valueOf() !==
        dayjs(this.updated_at).utc().valueOf() || this.status === 'active'
    );
  };

  nextSend = () => {
    if (this.status === 'inactive') {
      return String.fromCharCode(8212);
    }

    const date = dayjs(this.next_send).utc();

    return this.frequency_type === 'year'
      ? date.format('MMM. D, YYYY')
      : date.format('dddd, MMM. Do');
  };

  resource = () => {
    switch (this.automation_type.toLowerCase()) {
      case 'checkin':
        return {
          component: 'InsightCheckinIndex',
          name: 'Check-in',
          params: {},
        };
      case 'enps':
        return {
          component: 'InsightPromoterScoreIndex',
          name: 'eNPS',
          params: {},
        };
      case 'survey':
        return {
          component: 'InsightSurveyAutomationShow',
          name: 'Survey',
          params: {
            id: this.survey ? this.survey.id : '',
          },
        };
      default:
        return {
          component: 'AutomationIndex',
          name: String.fromCharCode(8212),
          params: {},
        };
    }
  };
}

export default Automation;
