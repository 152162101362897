import { Model } from '@vuex-orm/core';

export class CheckinQuestionJoin extends Model {
  static get entity() {
    return 'CheckinQuestionJoin';
  }

  static primaryKey = ['checkin_id', 'checkin_question_id'];

  static fields() {
    return {
      id: this.attr(null),
      checkin_id: this.attr(null),
      checkin_question_id: this.attr(null),
    };
  }
}

export default CheckinQuestionJoin;
