import { ACCESS_PLANS, FEATURES } from '~/helpers/constants';

export default {
  computed: {
    featureDetails() {
      return {
        [FEATURES.ADDITIONAL_SEATS]: {
          accessLevel: ACCESS_PLANS.STANDARD,
          displayName: 'Additional Seats',
        },
        [FEATURES.AUTOMATIONS]: {
          accessLevel: ACCESS_PLANS.STANDARD,
          displayName: 'Automations',
        },
        [FEATURES.BRANDING]: {
          accessLevel: ACCESS_PLANS.PROFESSIONAL,
          displayName: 'Recess Branding',
        },
        [FEATURES.CHECKINS]: {
          accessLevel: ACCESS_PLANS.STANDARD,
          displayName: 'Check-ins',
        },
        [FEATURES.CHILD_GROUPS]: {
          accessLevel: ACCESS_PLANS.PROFESSIONAL,
          displayName: 'Child Groups',
        },
        [FEATURES.COMMENTS]: {
          accessLevel: ACCESS_PLANS.FREE,
          displayName: 'Comments',
        },
        [FEATURES.ENPS]: {
          accessLevel: ACCESS_PLANS.PROFESSIONAL,
          displayName: 'eNPS',
        },
        [FEATURES.GROUPS]: {
          accessLevel: ACCESS_PLANS.STANDARD,
          displayName: 'Groups',
        },
        [FEATURES.INSIGHTS]: {
          accessLevel: ACCESS_PLANS.FREE,
          displayName: 'Insights',
        },
        [FEATURES.INTEGRATIONS]: {
          accessLevel: ACCESS_PLANS.STANDARD,
          displayName: 'Integrations',
        },
        [FEATURES.SENDING_DOMAINS]: {
          accessLevel: ACCESS_PLANS.PROFESSIONAL,
          displayName: 'Custom Sending Domains',
        },
        [FEATURES.SURVEYS]: {
          accessLevel: ACCESS_PLANS.STANDARD,
          displayName: 'Surveys & Polls',
        },
        [FEATURES.SURVEY_TEMPLATES]: {
          accessLevel: ACCESS_PLANS.PROFESSIONAL,
          displayName: 'Survey Template Library',
        },
      };
    },
  },
};
