import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Model } from '@vuex-orm/core';
import Target from './target';

dayjs.extend(utc);

export default class Schedule extends Model {
  static get entity() {
    return 'Schedule';
  }

  static fields() {
    return {
      created_at: this.string(''),
      id: this.attr(null),
      post_id: this.attr(null),
      scheduled_at: this.string(''),
      send_count: this.number(0),
      targets: this.hasMany(Target, 'schedule_id'),
      time_zone: this.string(''),
      updated_at: this.string(''),
      user_id: this.attr(null),
    };
  }

  scheduledTime = () => {
    return dayjs(this.scheduled_at).utc().format('MMM DD, YYYY ･ h:mm A');
  };
}
