import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Model } from '@vuex-orm/core';
import Automation from '~/models/automation';
import { Blueprint, Draft, Scheduled, Sent } from '~/models/surveyHierarchy';
import SurveyQuestion from '~/models/surveyQuestion';
import SurveyTarget from '~/models/surveyTarget';
import SurveyUserAnswer from '~/models/surveyUserAnswer';
import { User } from '~/models/userHierarchy';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const percentage = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  style: 'percent',
});

export class Survey extends Model {
  static get entity() {
    return 'Survey';
  }

  static typeKey = 'status';

  static types() {
    return {
      blueprint: Blueprint,
      draft: Draft,
      scheduled: Scheduled,
      sent: Sent,
    };
  }

  static fields() {
    return {
      account_id: this.attr(null),
      automation: this.belongsTo(Automation, 'automation_id'),
      automation_id: this.attr(null),
      completed_count: this.attr(null),
      created_at: this.string(''),
      creator: this.belongsTo(User, 'creator_id'),
      creator_id: this.attr(null),
      editor: this.belongsTo(User, 'editor_id'),
      editor_id: this.attr(null),
      has_post: this.boolean(false),
      id: this.attr(null),
      is_automation: this.boolean(false),
      name: this.string(''),
      post_id: this.attr(null).nullable(),
      questions_count: this.attr(null),
      scheduled_at: this.string('').nullable(),
      sent_at: this.string('').nullable(),
      status: this.string(''),
      survey: this.belongsTo(Survey, 'survey_id'),
      survey_id: this.attr(null),
      survey_questions: this.hasMany(SurveyQuestion, 'survey_id'),
      survey_targets: this.hasMany(SurveyTarget, 'survey_id'),
      survey_type: this.string(''),
      survey_user_answers: this.hasMany(SurveyUserAnswer, 'survey_id'),
      surveys: this.hasMany(Survey, 'survey_id'),
      total_targets: this.attr(null),
      updated_at: this.string(''),
    };
  }

  actions = () => {
    return {
      button: {
        actionType: '',
        hasRoute: false,
        isOutlined: true,
        params: {},
        route: '',
        text: '',
      },
    };
  };

  averageParticipationPercent = () => {
    if (this.completed_count === 0 && this.total_targets === 0) {
      return String.fromCharCode(8212);
    }

    const division = this.completed_count / this.total_targets;

    return this.formatPercentage(division);
  };

  childAverageParticipation = () => {
    if (this.childCompletedCount() === 0 && this.childTotalTargets() === 0) {
      return String.fromCharCode(8212);
    }

    const division = this.childCompletedCount() / this.childTotalTargets();

    return this.formatPercentage(division);
  };

  childCompletedCount = () => {
    return this.surveys.reduce((total, current) => {
      return total + current.completed_count;
    }, 0);
  };

  childSentCount = () => {
    return this.surveys.length;
  };

  childTotalTargets = () => {
    return this.surveys.reduce((total, current) => {
      return total + current.total_targets;
    }, 0);
  };

  editorName = () => {
    if (!this.editor || Object.keys(this.editor).length === 0) {
      return '';
    }

    return this.editor.username();
  };

  formatPercentage = number => {
    if (Number.isNaN(number) || number === Infinity) {
      return percentage.format(0);
    }

    return percentage.format(number);
  };

  formatScheduledAt = () => {
    // This does NOT need .utc(). The backend returns a UTC timestamp
    return dayjs(this.scheduled_at).format('MMM DD, YYYY ･ h:mm A');
  };

  formatSentAt = () => {
    return dayjs(this.sent_at).utc().format('MMMM DD, YYYY');
  };

  headline = () => {
    return {
      hasRoute: false,
      name: this.name,
      route: {
        name: '',
        params: {},
      },
    };
  };

  isAutomationBlueprint = () => {
    return this.automation_id && this.is_automation;
  };

  toCSV = () => {
    const csv = [];

    this.survey_questions.forEach(question => {
      csv.push(...question.toCSV());
      csv.push(['', '']);
    });

    return csv;
  };

  details = () => {
    return {
      hasIcon: false,
      html: '',
      icon: '',
    };
  };

  subhead = () => {
    return {
      action: {
        hasLink: false,
        link: {},
        text: `Edited ${
          this.updated_at ? dayjs(this.updated_at).utc().fromNow() : ''
        }`,
      },
      details: {
        hasLink: true,
        link: {
          params: {
            id: this.id,
          },
          route: 'SurveyShow',
          text: 'View Online',
        },
        text: '',
      },
    };
  };
}

export default Survey;
