<template>
  <svg
    :height="height"
    viewBox="0 0 340 340"
    :width="width"
    x="0"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0">
    <path
      d="M316.4 140.9c-2-2-5.2-2-7.2 0L287.1 163c-1.7-32.5-15.5-63.6-38.6-86.8-25.1-25.1-58.4-38.9-93.8-38.9S85.9 51.1 60.9 76.2c-51.7 51.7-51.7 135.9 0 187.6 25.1 25.1 58.4 38.9 93.8 38.9s68.7-13.8 93.8-38.9c2-2 2-5.2 0-7.2s-5.2-2-7.2 0c-23.1 23.1-53.9 35.9-86.6 35.9s-63.5-12.7-86.6-35.9c-47.7-47.7-47.7-125.4 0-173.2 23.1-23.1 53.9-35.9 86.6-35.9s63.5 12.7 86.6 35.9c21.2 21.2 33.8 49.5 35.6 79.1l-21.7-21.7c-2-2-5.2-2-7.2 0s-2 5.2 0 7.2l30 30c.9 1.2 2.3 2 3.9 2.1h.4c.2 0 .5 0 .7-.1 1-.2 2-.6 2.8-1.4l30.6-30.6c2-1.9 2-5.1 0-7.1z"
      fill="#bdd8f4" />
    <path
      d="M154.7 37.4c35.4 0 68.7 13.8 93.8 38.9 23.2 23.2 36.9 54.3 38.6 86.8l22.1-22.1c1-1 2.3-1.5 3.6-1.5s2.6.5 3.6 1.5c2 2 2 5.2 0 7.2l-30.6 30.6c-.8.8-1.8 1.3-2.8 1.4-.2 0-.4.1-.7.1h-.1-.1-.1-.1c-1.6-.1-3-.9-3.9-2.1l-30-30c-2-2-2-5.2 0-7.2 1-1 2.3-1.5 3.6-1.5 1.3 0 2.6.5 3.6 1.5l21.7 21.7c-1.8-29.7-14.5-58-35.6-79.1-23.1-23.1-53.9-35.9-86.6-35.9S91.2 60.3 68.1 83.4c-47.7 47.7-47.7 125.4 0 173.2 23.1 23.1 53.9 35.9 86.6 35.9s63.5-12.7 86.6-35.9c1-1 2.3-1.5 3.6-1.5 1.3 0 2.6.5 3.6 1.5 2 2 2 5.2 0 7.2-25.1 25.1-58.4 38.9-93.8 38.9S86 288.9 60.9 263.8c-51.7-51.7-51.7-135.9 0-187.6 25-25.1 58.3-38.8 93.8-38.8m0-7.4c-37.4 0-72.6 14.6-99 41-54.6 54.6-54.6 143.4 0 198 26.4 26.4 61.6 41 99 41s72.6-14.6 99-41c2.4-2.4 3.6-5.5 3.6-8.8 0-3.3-1.3-6.5-3.6-8.8-2.4-2.4-5.5-3.6-8.8-3.6-3.3 0-6.5 1.3-8.8 3.6-21.7 21.7-50.6 33.7-81.4 33.7-30.7 0-59.6-12-81.4-33.7-44.9-44.9-44.9-117.9 0-162.8 21.7-21.7 50.6-33.7 81.4-33.7 30.7 0 59.6 12 81.4 33.7 14.7 14.7 25 32.9 30.1 52.8l-5.7-5.7c-2.4-2.4-5.5-3.6-8.8-3.6-3.3 0-6.5 1.3-8.8 3.6-4.9 4.9-4.9 12.8 0 17.6l29.7 29.7c2.2 2.8 5.5 4.4 9.1 4.6H283c.5 0 .9-.1 1.3-.1 2.6-.4 5-1.6 6.9-3.5l30.6-30.6c2.4-2.4 3.6-5.5 3.6-8.8 0-3.3-1.3-6.5-3.6-8.8-2.4-2.4-5.5-3.6-8.8-3.6-3.3 0-6.5 1.3-8.8 3.6L292.7 147c-4.8-28.7-18.4-55.2-39.1-76-26.4-26.4-61.5-41-98.9-41z"
      :fill="primaryColor" />
    <path
      d="M194.2 151.7h-65.9v-8.8c0-14.5 11.8-26.4 26.4-26.4 7.9 0 15.3 3.5 20.4 9.6 1.5 1.9 4.3 2.1 6.2.6 1.9-1.5 2.1-4.3.6-6.2-6.7-8.1-16.6-12.8-27.1-12.8-19.4 0-35.2 15.8-35.2 35.2v8.8h-4.4c-7.3 0-13.2 5.9-13.2 13.2v44c0 7.3 5.9 13.2 13.2 13.2h79.1c7.3 0 13.2-5.9 13.2-13.2v-44c-.1-7.3-6-13.2-13.3-13.2z"
      fill="#dfecf7" />
    <path
      d="M154.7 107.7c10.5 0 20.4 4.7 27.1 12.8 1.5 1.9 1.3 4.6-.6 6.2-.8.7-1.8 1-2.8 1-1.3 0-2.5-.5-3.4-1.6-5-6.1-12.5-9.6-20.4-9.6-14.5 0-26.4 11.8-26.4 26.4v8.8h65.9c7.3 0 13.2 5.9 13.2 13.2v44c0 7.3-5.9 13.2-13.2 13.2H115c-7.3 0-13.2-5.9-13.2-13.2v-44c0-7.3 5.9-13.2 13.2-13.2h4.4v-8.8c.1-19.4 15.9-35.2 35.3-35.2m0-7.3c-23.4 0-42.5 19.1-42.5 42.5v1.7c-9.9 1.4-17.6 10-17.6 20.3v44c0 11.3 9.2 20.5 20.5 20.5h79.1c11.3 0 20.5-9.2 20.5-20.5v-44c0-11.3-9.2-20.5-20.5-20.5h-58.6V143c0-10.5 8.5-19 19-19 5.7 0 11 2.5 14.7 6.9 2.3 2.7 5.6 4.3 9.1 4.3 2.7 0 5.4-1 7.5-2.7 5-4.1 5.7-11.5 1.6-16.5-8.2-10-20.1-15.6-32.8-15.6z"
      :fill="primaryColor" />
    <g>
      <path
        d="M163.5 182.5c0-4.8-3.9-8.8-8.8-8.8s-8.8 3.9-8.8 8.8c0 3.3 1.8 6.2 4.5 7.7-.1.4-.1.7-.1 1.1v8.8c0 2.4 2 4.4 4.4 4.4 2.4 0 4.4-2 4.4-4.4v-8.8c0-.4-.1-.7-.1-1.1 2.6-1.5 4.5-4.4 4.5-7.7z"
        fill="#ffffff" />
      <path
        d="M154.7 173.7c4.8 0 8.8 3.9 8.8 8.8 0 3.3-1.8 6.2-4.5 7.7.1.4.1.7.1 1.1v8.8c0 2.4-2 4.4-4.4 4.4-2.4 0-4.4-2-4.4-4.4v-8.8c0-.4.1-.7.1-1.1a8.79 8.79 0 01-4.5-7.7c0-4.9 3.9-8.8 8.8-8.8m0-7.4c-8.9 0-16.1 7.2-16.1 16.1 0 4.2 1.6 8.1 4.4 11.1v6.5c0 6.5 5.3 11.7 11.7 11.7 6.5 0 11.7-5.3 11.7-11.7v-6.5c2.8-3 4.4-6.9 4.4-11.1 0-8.8-7.2-16.1-16.1-16.1z"
        :fill="primaryColor" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      default: '340',
      required: true,
      type: String,
    },
    primaryColor: {
      default: '#2051a0',
      required: false,
      type: String,
    },
    width: {
      default: '340',
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped></style>
