import { Model } from '@vuex-orm/core';

export class Stat extends Model {
  static get entity() {
    return 'Stat';
  }

  static fields() {
    return {
      clicks: this.number(0),
      date: this.string(''),
      id: this.attr(null),
      marked_as_read: this.number(0),
      opens: this.number(0),
      rated: this.number(0),
    };
  }
}

export default Stat;
