<template>
  <v-navigation-drawer
    v-if="showNavigation"
    app
    class="side-navigation"
    clipped
    height="100%"
    mobile-break-point="960"
    :style="styles"
    :temporary="false"
    width="168">
    <v-list>
      <v-list-item-group active-class="active">
        <v-list-item
          class="navigation-item"
          exact
          link
          :ripple="false"
          :to="{ name: 'Dashboard' }">
          <v-list-item-content class="navigation-link">
            <v-list-item-title>
              Dashboard
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item
          class="navigation-item"
          link
          :ripple="false"
          :to="{ name: 'PostIndex' }">
          <v-list-item-content class="navigation-link">
            <v-list-item-title>
              Broadcasts
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="hasAccess && showTemplatesItem">
          <v-divider />

          <v-list-item
            class="navigation-item"
            link
            :ripple="false"
            :to="{ name: 'TemplateIndex' }">
            <v-list-item-content class="navigation-link">
              <v-list-item-title>
                Templates
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="hasAccess">
          <v-divider />

          <v-list-item
            class="navigation-item"
            link
            :ripple="false"
            :to="{ name: 'InsightCheckinIndex' }">
            <v-list-item-content class="navigation-link">
              <v-list-item-title>
                Check-ins
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="hasAccess">
          <v-divider />

          <v-list-item
            :class="['navigation-item', {
              'locked': !checkFeatureAccess.enps,
              'v-list-item--active active': checkFeatureAccess.enps &&
                isMenuItemActive('InsightPromoterScoreIndex'),
            }]"
            link
            :ripple="false"
            @click="checkLockedState('InsightPromoterScoreIndex', features.ENPS)">
            <v-list-item-content class="navigation-link">
              <v-list-item-title :class="{ 'is-locked': !checkFeatureAccess.enps }">
                eNPS<sup
                  :class="['trademark', {
                    'has-lock-icon': !checkFeatureAccess.enps,
                  }]">&reg;</sup>
                <v-icon
                  v-if="!checkFeatureAccess.enps"
                  class="icon-lock"
                  size="12">
                  mdi-lock
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="hasAccess && hasAdminAccess">
          <v-divider />

          <v-list-item
            :class="['navigation-item', {
              'locked': !checkFeatureAccess.surveys,
              'v-list-item--active active': checkFeatureAccess.surveys &&
                isMenuItemActive('SurveyIndex'),
            }]"
            link
            :ripple="false"
            @click="checkLockedState('SurveyIndex', features.SURVEYS)">
            <v-list-item-content class="navigation-link">
              <v-list-item-title :class="{ 'is-locked': !checkFeatureAccess.surveys }">
                Surveys
                <v-icon
                  v-if="!checkFeatureAccess.surveys"
                  class="icon-lock"
                  size="12">
                  mdi-lock
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="hasAccess">
          <v-divider />

          <v-list-item
            class="navigation-item"
            link
            :ripple="false"
            :to="{ name: 'AutomationIndex' }">
            <v-list-item-content class="navigation-link">
              <v-list-item-title>
                Automations
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="hasAccess">
          <v-divider />

          <v-list-item
            class="navigation-item"
            link
            :ripple="false"
            :to="{ name: 'InsightHealthScoreShow' }">
            <v-list-item-content class="navigation-link">
              <v-list-item-title>
                Insights
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider />
      </v-list-item-group>

      <v-list-item-group>
        <v-list-item
          class="navigation-item"
          href="https://help.recess.io/en/?ref=app"
          link
          :ripple="false"
          target="_blank">
          <v-list-item-content class="navigation-link">
            <v-list-item-title class="external-link">
              Help Center
              <v-icon
                class="icon-external"
                color="rgba(28, 32, 38, 0.4)"
                size="15">
                mdi-open-in-new
              </v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { EventBus } from '~/helpers/eventBus';
import { FEATURES } from '~/helpers/constants';
import currentUser from '~/mixins/currentUser';

export default {
  mixins: [currentUser],
  props: {
    isLoggedIn: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    actionBarHeight: 0,
    features: FEATURES,
    isActionBarVisible: false,
    topOffset: 0,
  }),
  computed: {
    checkFeatureAccess() {
      return {
        enps: this.isFeatureUnlocked(this.features.ENPS),
        surveys: this.isFeatureUnlocked(this.features.SURVEYS),
      };
    },
    hasAccess() {
      return (
        this.account &&
        this.isLoggedIn &&
        this.$route.path !== '/onboard' &&
        this.currentUser.hasAccess()
      );
    },
    showNavigation() {
      return this.isLoggedIn && this.account;
    },
    showTemplatesItem() {
      return this.account.crud_templates;
    },
    styles() {
      return {
        maxHeight: `calc(100% - ${this.topOffset}px)`,
        paddingBottom: `${this.actionBarHeight}px`,
        top: `${this.topOffset}px`,
      };
    },
  },
  created() {
    document.addEventListener('scroll', this.debounce(this.onScroll, 10));
  },
  beforeDestroy() {
    EventBus.$off('set-action-bar-props');
  },
  destroyed() {
    document.removeEventListener('scroll', this.onScroll);
  },
  mounted() {
    const navbar = document.getElementsByClassName('v-app-bar')[0];
    this.topOffset = navbar.offsetHeight;

    EventBus.$on('set-action-bar-props', data => {
      const { height, visible } = data;

      this.isActionBarVisible = visible;
      this.actionBarHeight = height;
    });
  },
  methods: {
    checkLockedState(route, featureName) {
      if (!this.isFeatureUnlocked(featureName)) {
        EventBus.$emit('account-upgrade-required', featureName);

        return;
      }

      if (route === this.$route.name) {
        return;
      }

      this.$router.push({ name: route });
    },
    debounce(callback, wait) {
      let timeout;

      return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
      };
    },
    isMenuItemActive(item) {
      return item === this.$route.name;
    },
    onScroll() {
      const navbar = document.getElementsByClassName('v-app-bar')[0];

      if (navbar) {
        this.topOffset =
          window.scrollY <= navbar.offsetHeight
            ? navbar.offsetHeight - window.scrollY
            : 0;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '~/sass/variables';

  .side-navigation {
    box-shadow: 1px 2px 4px rgba($dark-grey, 0.04);

    .v-navigation-drawer__border {
      border: none;
    }
  }

  .navigation-link {
    color: rgba($dark-grey, 0.5);
    padding: 20px 0;
  }

  .v-list-item.navigation-item {
    border-left: 4px solid transparent;

    &::before {
      opacity: 0;
    }

    &:hover::before {
      opacity: 0;
    }
  }

  .v-list-item--active:not(.locked).active {
    border-left-color: $accent;
    font-weight: 500;

    &::before {
      opacity: 0;
    }

    &:hover::before {
      opacity: 0;
    }

    .navigation-link {
      color: $dark-grey;
    }
  }

  .is-locked {
    display: flex;
    opacity: 0.5;
  }

  .icon-lock {
    margin-left: 4px;
  }

  .external-link {
    display: flex;
  }

  .icon-external {
    margin-left: 6px;
    margin-top: -1px;
  }

  .trademark {
    font-size: 60%;
    line-height: 1;
    top: -0.7em;

    &.has-lock-icon {
      top: 0;
    }
  }
</style>
