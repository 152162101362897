import { PERMISSIONS } from '~/helpers/constants';

const TeamManagementGroupEdit = () =>
  import('~/views/teamManagement/groups/Edit');
const TeamManagementGroups = () => import('~/views/teamManagement/groups/Show');
const TeamManagementIndividuals = () =>
  import('~/views/teamManagement/individuals/Show');

export const teamManagement = [
  {
    component: TeamManagementGroupEdit,
    meta: {
      adminRestricted: true,
      parentComponent: 'TeamManagementGroups',
      permissionName: PERMISSIONS.MANAGE_USER_WITHIN_GROUP,
      requiresAuth: true,
    },
    name: 'EditGroup',
    path: '/groups/:id/edit',
  },
  {
    component: TeamManagementGroups,
    meta: {
      adminRestricted: true,
      permissionName: PERMISSIONS.MANAGE_USER_WITHIN_GROUP,
      requiresAuth: true,
    },
    name: 'TeamManagementGroups',
    path: '/team_management/groups',
  },
  {
    component: TeamManagementIndividuals,
    meta: {
      adminRestricted: true,
      requiresAuth: true,
    },
    name: 'TeamManagementIndividuals',
    path: '/team_management',
  },
];
