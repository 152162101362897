import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Survey } from '~/models/surveyHierarchy';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export class Draft extends Survey {
  static get entity() {
    return 'Draft';
  }

  static baseEntity() {
    return 'Survey';
  }

  static fields() {
    return {
      ...super.fields(),
    };
  }

  actions = () => {
    return {
      button: {
        actionType: 'schedule',
        hasRoute: false,
        isOutlined: false,
        params: {},
        route: '',
        text: 'Schedule',
      },
    };
  };

  details = () => {
    return {
      hasIcon: false,
      html: '',
      icon: '',
    };
  };

  headline = () => {
    return {
      hasRoute: true,
      name: this.name,
      route: {
        name: 'SurveyEdit',
        params: {
          id: this.id,
        },
      },
    };
  };

  subhead = () => {
    let text = 'Edited';
    const lastUpdatedAt = this.updated_at
      ? dayjs(this.updated_at).utc().fromNow()
      : '';

    if (this.editorName()) {
      text += ` by ${this.editorName()}`;
    }

    return {
      action: {
        hasLink: false,
        link: {},
        text: `${text} ${lastUpdatedAt}`,
      },
      details: {
        hasLink: false,
        link: {
          params: {},
          route: '',
          text: 'Unscheduled',
        },
        text: '',
      },
    };
  };
}

export default Draft;
