import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        accent: '#2aba83',
        'dark-grey': '#1c2026',
        error: '#ff7e6b',
        info: '#2196f3',
        'light-grey': '#e0e4e8',
        primary: '#2051a0',
        secondary: '#42aeea',
        'secondary-grey': '#a7adb5',
        success: '#2aba83',
        warning: '#3f51b5',
      },
    },
  },
});
