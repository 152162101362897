import { Model } from '@vuex-orm/core';
import Checkin from '~/models/checkin'

export class CheckinTarget extends Model {
  static get entity() {
    return 'checkin_target';
  }

  static fields() {
    return {
      checkin_id: this.attr(null),
      completed_targets: this.number(0),
      confidence: this.number(null).nullable(),
      id: this.attr(null),
      mood: this.number(null).nullable(),
      targetable_id: this.attr(null),
      targetable_type: this.attr(null),
      total_targets: this.number(0),
      workload: this.number(null).nullable(),

      checkin: this.belongsTo(Checkin, 'checkin_id'),
    };
  }
}

export default CheckinTarget;
