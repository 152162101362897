import { Model } from '@vuex-orm/core';
import { Survey } from '~/models/surveyHierarchy';
import SurveyQuestionOption from '~/models/surveyQuestionOption';
import SurveyUserAnswer from '~/models/surveyUserAnswer';

export class SurveyQuestion extends Model {
  static get entity() {
    return 'SurveyQuestion';
  }

  static fields() {
    return {
      id: this.attr(null),
      meta: this.attr(),
      order: this.attr(''),
      question: this.attr(''),
      question_type: this.attr(''),
      required: this.attr(''),
      survey: this.belongsTo(Survey, 'survey_id'),
      survey_id: this.attr(''),
      survey_question_options: this.hasMany(
        SurveyQuestionOption,
        'survey_question_id',
      ),
      survey_user_answers: this.hasMany(SurveyUserAnswer, 'survey_question_id'),
    };
  }

  toCSV = () => {
    const csv = [];
    csv.push([this.question]);

    if (this.question_type === 'OpenResponse') {
      this.survey_user_answers.forEach(answer =>
        csv.push([answer.user.username(), answer.comment]),
      );
    } else {
      this.survey_question_options.forEach(option => {
        csv.push([option.option, option.survey_user_answers.length]);
      });
    }

    return csv;
  };
}

export default SurveyQuestion;
