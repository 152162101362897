const AutomationIndex = () => import('~/views/automations/index/Index');
const AutomationEdit = () => import('~/views/automations/edit/Edit');

export const automation = [
  {
    component: AutomationIndex,
    meta: {
      adminRestricted: true,
      requiresAuth: true,
    },
    name: 'AutomationIndex',
    path: '/automations',
  },
  {
    component: AutomationEdit,
    meta: {
      adminRestricted: true,
      permissionName: 'create_send_broadcast',
      requiresAuth: true,
    },
    name: 'AutomationEdit',
    path: '/automations/:id/edit',
  },
];
