import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Model } from '@vuex-orm/core';
import { User } from '~/models/userHierarchy';
import CheckinTarget from '~/models/checkinTarget';
import GroupAdminUser from '~/models/groupAdminUser';
import GroupUser from '~/models/groupUser';
import HealthScore from '~/models/healthScore';
import HealthScoreStatus from '~/models/healthScore/status/healthScoreStatus';
import MailableUser from '~/models/mailableUser';
import { MAX_ENGAGEMENT_SCORE } from '~/helpers/constants';
import PromoterScoreTarget from '~/models/promoterScoreTarget';
import Smiley from '~/models/smiley';
import SurveyTarget from '~/models/surveyTarget';
import Trend from '~/models/healthScore/trend/trend';
import ViewableUser from '~/models/viewableUser';

dayjs.extend(utc);

export class Group extends Model {
  static get entity() {
    return 'Group';
  }

  static types() {
    return {
      Group,
    };
  }

  static fields() {
    return {
      account_id: this.attr(null),
      check_ins: this.boolean(false),
      checkin_targets: this.morphMany(
        CheckinTarget,
        'targetable_id',
        'targetable_type',
      ),
      child_groups: this.hasMany(Group, 'parent_id'),
      created_at: this.attr(''),
      engagement: this.attr(0),
      group_admins: this.belongsToMany(
        User,
        GroupAdminUser,
        'group_id',
        'user_id',
      ),
      has_group_admin: this.boolean(false),
      health_score_pct: this.number(0),
      health_scores: this.morphMany(HealthScore, 'entity_id', 'entity_type'),
      id: this.attr(null),
      insight: this.boolean(false),
      mailable: this.boolean(false),
      mailable_users: this.belongsToMany(
        User,
        MailableUser,
        'group_id',
        'user_id',
      ),
      members_count: this.attr(0),
      name: this.string(''),
      parent_group: this.belongsTo(Group, 'parent_id'),
      parent_id: this.attr(null).nullable(),
      promoter_score_targets: this.morphMany(
        PromoterScoreTarget,
        'targetable_id',
        'targetable_type',
      ),
      show: this.boolean(true),
      survey_targets: this.morphMany(
        SurveyTarget,
        'targetable_id',
        'targetable_type',
      ),
      thirty_day_health_score_change: this.attr(null).nullable(),
      updated_at: this.attr(''),
      users: this.belongsToMany(User, GroupUser, 'group_id', 'user_id'),
      viewable: this.boolean(false),
      viewable_users: this.belongsToMany(
        User,
        ViewableUser,
        'group_id',
        'user_id',
      ),
    };
  }

  averageParticipationPercent = () => {
    const [surveyTarget] = this.survey_targets;

    if (
      !surveyTarget ||
      (surveyTarget.completed_count === 0 && surveyTarget.total_targets === 0)
    ) {
      return String.fromCharCode(8212);
    }

    const division = surveyTarget.completed_count / surveyTarget.total_targets;

    if (Number.isNaN(division) || division === Infinity) {
      return '0%';
    }

    return `${Math.round(division * 100)}%`;
  };

  completedPromoterPercentage = () => {
    if (
      this.promoterScoreCompletedTargets() === 0 &&
      this.promoterScoreTotalTargets() === 0
    ) {
      return String.fromCharCode(8212);
    }

    const division =
      this.promoterScoreCompletedTargets() / this.promoterScoreTotalTargets();

    if (Number.isNaN(division) || division === Infinity) {
      return '0%';
    }

    return `${(division * 100).toFixed(0)}%`;
  };

  getEnpsCounts = (type, date) => {
    const values = this.promoter_score_targets.filter(
      target => target[type] !== null,
    );

    if (!values.length) {
      return NaN;
    }

    switch (type) {
      case 'detractors':
      case 'passives':
      case 'promoters':
        return this.promoter_score_targets
          .filter(
            target =>
              dayjs(target.created_at).utc().format('YYYY-MM-DD') ===
              dayjs(date).utc().format('YYYY-MM-DD'),
          )
          .reduce((total, next) => total + next[type], 0);

      case 'score': {
        const initialValues = {
          completed_count: 0,
          detractors: 0,
          passives: 0,
          promoters: 0,
        };

        const counts = this.promoter_score_targets
          .filter(
            target =>
              dayjs(target.created_at).utc().format('YYYY-MM-DD') ===
              dayjs(date).utc().format('YYYY-MM-DD'),
          )
          .reduce((total, next) => {
            return {
              completed_count: total.completed_count + next.completed_count,
              detractors: total.detractors + next.detractors,
              promoters: total.promoters + next.promoters,
            };
          }, initialValues);

        return (
          ((counts.promoters - counts.detractors) / counts.completed_count) *
          100
        );
      }

      default:
        return NaN;
    }
  };

  getHealthScore = () => {
    if (this.health_score_pct === 0 && !this.health_scores.length) {
      return null;
    }

    return this.health_score_pct;
  };

  healthScoreStatus = () => {
    const healthScore = this.getHealthScore();

    return new HealthScoreStatus(healthScore).get();
  };

  healthScoreTrend = () => {
    return new Trend(this.thirty_day_health_score_change).get();
  };

  healthScoreTrendPercentage = () => {
    return new Trend(this.thirty_day_health_score_change).getPercentage();
  };

  isChildGroup = () => {
    return !!this.parent_id;
  };

  promoterScoreCompletedTargets = () => {
    return this.promoter_score_targets.reduce(
      (total, next) => total + next.completed_count,
      0,
    );
  };

  promoterScoreTotalTargets = () => {
    return this.promoter_score_targets.reduce(
      (total, next) => total + next.total_targets,
      0,
    );
  };

  completedTargets = () => {
    return this.checkin_targets.reduce(
      (total, next) => total + next.completed_targets,
      0,
    );
  };

  totalTargets = () => {
    return this.checkin_targets.reduce(
      (total, next) => total + next.total_targets,
      0,
    );
  };

  displayEngagement = () => {
    switch (true) {
      case this.engagement >= 0 && this.engagement <= 4:
        return {
          dotClassName: 'dot-red',
          score: ((this.engagement / MAX_ENGAGEMENT_SCORE) * 100).toFixed(0),
          status: {
            className: 'unengaged',
            label: 'Unengaged',
          },
        };
      case this.engagement >= 5 && this.engagement <= 9:
        return {
          dotClassName: 'dot-yellow',
          score: ((this.engagement / MAX_ENGAGEMENT_SCORE) * 100).toFixed(0),
          status: {
            className: 'at-risk',
            label: 'At Risk',
          },
        };
      case this.engagement >= 10 && this.engagement <= 14:
        return {
          dotClassName: 'dot-green',
          score: ((this.engagement / MAX_ENGAGEMENT_SCORE) * 100).toFixed(0),
          status: {
            className: 'engaged',
            label: 'Engaged',
          },
        };
      default:
        return {
          dotClassName: '',
          score: 0,
          status: {
            className: '',
            label: 'N/A',
          },
        };
    }
  };

  overallMood = () => {
    const values = this.checkin_targets.filter(target => target.mood !== null);

    return values.length
      ? this.checkin_targets.reduce((total, next) => total + next.mood, 0) /
          values.length
      : NaN;
  };

  overallConfidence = () => {
    const values = this.checkin_targets.filter(
      target => target.confidence !== null,
    );

    return values.length
      ? this.checkin_targets.reduce(
          (total, next) => total + next.confidence,
          0,
        ) / values.length
      : NaN;
  };

  overallWorkload = () => {
    const values = this.checkin_targets.filter(
      target => target.workload !== null,
    );

    return values.length
      ? this.checkin_targets.reduce((total, next) => total + next.workload, 0) /
          values.length
      : NaN;
  };

  overallCheckin = () => {
    return new Smiley({
      confidence: this.overallConfidence(),
      mood: this.overallMood(),
      type: 'average',
      workload: this.overallWorkload(),
    });
  };

  overallScore = () => {
    if (
      this.promoterScoreCompletedTargets() === 0 &&
      this.promoterScoreTotalTargets() === 0
    ) {
      return String.fromCharCode(8212);
    }

    const values = this.promoter_score_targets.filter(
      target => target.score !== null,
    );

    return values.length
      ? (
          this.promoter_score_targets.reduce(
            (total, next) => total + next.score,
            0,
          ) / values.length
        ).toFixed(0)
      : 0;
  };

  pendingPromoterPercentage = () => {
    if (
      this.promoterScoreCompletedTargets() === 0 &&
      this.promoterScoreTotalTargets() === 0
    ) {
      return String.fromCharCode(8212);
    }

    const division =
      this.promoterScoreCompletedTargets() / this.promoterScoreTotalTargets();

    if (Number.isNaN(division) || division === Infinity) {
      return '0%';
    }

    return `${100 - (division * 100).toFixed(0)}%`;
  };

  membersCount = () => {
    return this.managers.length + this.users.length;
  };
}

export default Group;
