import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Survey } from '~/models/surveyHierarchy';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export class Blueprint extends Survey {
  static get entity() {
    return 'Blueprint';
  }

  static baseEntity() {
    return 'Survey';
  }

  static fields() {
    return {
      ...super.fields(),
    };
  }

  actions = () => {
    return {
      button: {
        actionType: 'schedule',
        hasRoute: true,
        isOutlined: false,
        params: {
          id: this.automation.id,
        },
        route: 'AutomationEdit',
        text: 'Schedule',
      },
    };
  };

  details = () => {
    return {
      hasIcon: false,
      html: `
      <span>
        <p class="mb-0">${this.automation.nextSend()}</p>
        <p class="caption">NEXT SEND</p>
      </span>
      `,
      icon: '',
    };
  };

  headline = () => {
    return {
      hasRoute: true,
      name: this.name,
      route: {
        name: 'SurveyEdit',
        params: {
          id: this.id,
        },
      },
    };
  };

  subhead = () => {
    let text = 'Edited';
    const lastUpdatedAt = this.updated_at
      ? dayjs(this.updated_at).utc().fromNow()
      : '';

    if (this.editorName()) {
      text += ` by ${this.editorName()}`;
    }

    return {
      action: {
        hasLink: false,
        link: {},
        text: `${text} ${lastUpdatedAt}`,
      },
      details: {
        hasLink: true,
        link: {
          params: {},
          route: 'AutomationIndex',
          text: this.automation.status === 'active' ? 'Active' : 'Inactive',
        },
        text: '',
      },
    };
  };
}

export default Blueprint;
