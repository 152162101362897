import Account from '~/models/account';

function getById(payload) {
  const { id } = payload;

  return Account.api().get(`/api/accounts/${id}`);
}

function hideOnboarding() {
  return Account.api().put(`/api/accounts/hide_onboarding`);
}

function storeOnboardingStep(payload) {
  const { onboardingStep } = payload;

  return Account.api().put(`/api/accounts/onboarding_step`, {
    onboarding_step: onboardingStep,
  });
}

function update(payload) {
  const { account, id } = payload;

  return Account.api().put(`/api/accounts/${id}`, {
    account,
  });
}

function updateAccountLogo(payload) {
  const { id, logo } = payload;

  return Account.api().put(`/api/accounts/${id}/update_logo`, {
    logo,
  });
}

export const accountService = {
  getById,
  hideOnboarding,
  storeOnboardingStep,
  update,
  updateAccountLogo,
};
