const ScheduleNew = () => import('~/views/schedules/New');

export const schedule = [
  {
    component: ScheduleNew,
    meta: {
      adminRestricted: true,
      permissionName: 'create_send_broadcast',
      requiresAuth: true,
    },
    name: 'ScheduleNew',
    path: '/posts/:id/schedule',
  },
];
