const CommentShow = () => import('~/views/comments/show/Show');

export const comment = [
  {
    // redirect to /insights/comments
    meta: {
      adminRestricted: true,
      requiresAuth: true,
    },
    path: '/comments',
    redirect: { name: 'InsightCommentIndex' },
  },
  {
    component: CommentShow,
    meta: {
      adminRestricted: false,
      isNavigationDrawerVisible: false,
      requiresAuth: true,
    },
    name: 'CommentShow',
    path: '/comments/:id',
  },
];
