import { Model } from '@vuex-orm/core';

export default class PlanFeature extends Model {
  static get entity() {
    return 'PlanFeature';
  }

  static fields() {
    return {
      account_id: this.attr(null),
      created_at: this.string(''),
      feature_name: this.string('').nullable(),
      id: this.attr(null),
      meta: this.attr(null),
      pay_plan_id: this.attr(null),
      permitted: this.attr(null),
      updated_at: this.string(''),
    };
  }
}
