import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '~/store/';
import Account from '~/models/account';
import { User } from '~/models/userHierarchy';
import { userService } from '~/services';

import { account } from './account.routes';
import { automation } from './automation.routes';
import { base } from './base.routes';
import { checkin } from './checkin.routes';
import { comment } from './comment.routes';
import { contact } from './contact.routes';
import { dashboard } from './dashboard.routes';
import { enps } from './enps.routes';
import { group } from './group.routes';
import { insight } from './insight.routes';
import { integration } from './integration.routes';
import { post } from './post.routes';
import { schedule } from './schedule.routes';
import { survey } from './survey.routes';
import { teamManagement } from './teamManagement.routes';
import { template } from './template.routes';
import { user } from './user.routes';
import { userManagement } from './userManagement.routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...dashboard,
    ...account,
    ...automation,
    ...checkin,
    ...comment,
    ...contact,
    ...enps,
    ...group,
    ...insight,
    ...integration,
    ...post,
    ...schedule,
    ...survey,
    ...teamManagement,
    ...template,
    ...user,
    ...userManagement,
    ...base,
  ],
});

router.beforeEach((to, from, next) => {
  const accountData = document
    .querySelector('body')
    .getAttribute('data-account');
  const userData = document.querySelector('body').getAttribute('data-user');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (userData && !JSON.parse(accountData).name && to.path !== '/onboard') {
      next({ name: 'AccountOnboard' });

      return;
    }

    if (accountData && to.meta.featureName) {
      const accountModel = Account.query()
        .whereId(JSON.parse(accountData).id)
        .withAll()
        .first();

      if (
        accountModel &&
        !accountModel.isFeatureUnlocked(to.meta.featureName)
      ) {
        store.dispatch(
          'alert/error',
          'Your account does not have access to this feature.',
        );

        return;
      }
    }

    if (userData && to.meta.permissionName) {
      const userModel = User.query()
        .whereId(JSON.parse(userData).id)
        .withAll()
        .first();

      if (userModel && !userModel.hasPermission(to.meta.permissionName)) {
        store.dispatch(
          'alert/error',
          'You are not allowed to access this page.',
        );

        return;
      }
    }

    if (userData) {
      next();

      return;
    }

    userService.logout();
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
