import { FEATURES } from '~/helpers/constants';

const InsightCheckinIndex = () => import('~/views/insights/checkins/Index');
const InsightCheckinGroupShow = () =>
  import('~/views/insights/checkins/group/Show');
const InsightCommentIndex = () => import('~/views/insights/comments/Index');
const InsightHealthScoreShow = () =>
  import('~/views/insights/healthScore/show/Show');
const InsightHealthScoreGroupShow = () =>
  import('~/views/insights/healthScore/group/Show');
const InsightEngagementShow = () =>
  import('~/views/insights/engagement/show/Show');
const InsightEngagementGroupShow = () =>
  import('~/views/insights/engagement/group/Show');
const InsightPromoterScoreIndex = () => import('~/views/insights/enps/Index');
const InsightPromoterScoreGroupShow = () =>
  import('~/views/insights/enps/group/Show');
const InsightSurveyAutomationShow = () =>
  import('~/views/insights/surveys/automation/Show');
const InsightSurveyGroupShow = () =>
  import('~/views/insights/surveys/group/Show');
const InsightSurveyIndex = () => import('~/views/insights/surveys/Index');
const InsightSurveyShow = () => import('~/views/insights/surveys/show/Show');

export const insight = [
  {
    meta: {
      adminRestricted: true,
      requiresAuth: true,
    },
    path: '/insights',
    redirect: { name: 'InsightHealthScoreShow' },
  },
  {
    component: InsightCheckinIndex,
    meta: {
      adminRestricted: true,
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightCheckinIndex',
    path: '/insights/checkins',
  },
  {
    component: InsightCheckinGroupShow,
    meta: {
      adminRestricted: true,
      parentComponent: 'InsightCheckinIndex',
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightCheckinGroupShow',
    path: '/insights/checkins/groups/:id',
  },
  {
    component: InsightCommentIndex,
    meta: {
      adminRestricted: true,
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightCommentIndex',
    path: '/insights/comments',
  },
  {
    component: InsightHealthScoreShow,
    meta: {
      adminRestricted: true,
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightHealthScoreShow',
    path: '/insights/health_score',
  },
  {
    component: InsightHealthScoreGroupShow,
    meta: {
      adminRestricted: true,
      parentComponent: 'InsightHealthScoreShow',
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightHealthScoreGroupShow',
    path: '/insights/health_score/groups/:id',
  },
  {
    component: InsightEngagementShow,
    meta: {
      adminRestricted: true,
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightEngagementShow',
    path: '/insights/engagement',
  },
  {
    component: InsightEngagementGroupShow,
    meta: {
      adminRestricted: true,
      parentComponent: 'InsightEngagementShow',
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightEngagementGroupShow',
    path: '/insights/engagement/groups/:id',
  },
  {
    component: InsightPromoterScoreIndex,
    meta: {
      adminRestricted: true,
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightPromoterScoreIndex',
    path: '/insights/enps',
  },
  {
    component: InsightPromoterScoreGroupShow,
    meta: {
      adminRestricted: true,
      parentComponent: 'InsightPromoterScoreIndex',
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightPromoterScoreGroupShow',
    path: '/insights/enps/groups/:id',
  },
  {
    component: InsightSurveyAutomationShow,
    meta: {
      adminRestricted: true,
      parentComponent: 'InsightSurveyIndex',
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightSurveyAutomationShow',
    path: '/insights/automations/surveys/:id',
  },
  {
    component: InsightSurveyIndex,
    meta: {
      adminRestricted: true,
      featureName: FEATURES.SURVEYS,
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightSurveyIndex',
    path: '/insights/surveys',
  },
  {
    component: InsightSurveyShow,
    meta: {
      adminRestricted: true,
      featureName: FEATURES.SURVEYS,
      parentComponent: 'InsightSurveyIndex',
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightSurveyShow',
    path: '/insights/surveys/:id',
  },
  {
    component: InsightSurveyGroupShow,
    meta: {
      adminRestricted: true,
      parentComponent: 'InsightSurveyIndex',
      permissionName: 'view_insights_reporting',
      requiresAuth: true,
    },
    name: 'InsightSurveyGroupShow',
    path: '/insights/surveys/:surveyId/groups/:groupId',
  },
];
